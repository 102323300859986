import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Footer from "./Footer";
import {
  Snackbar,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Box, Modal } from "@mui/material";

function Checkout() {
  const [cart, setCart] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [cartLoading, setCartLoading] = useState(true);
  const [addressLoading, setAddressLoading] = useState(true);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [products, setProducts] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to control the pop-up visibility
  const [productSpecs, setProductSpecs] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [courier, setCourier] = useState("jne");
  const [origin, setOrigin] = useState(null);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editingAddress, setEditingAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [snapUrl, setSnapUrl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openGateway, setOpenGateway] = useState(false);

  const onClose = () => {
    setIsOpen(false); // Close modal or similar
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic
  };

  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState({
    namapenerima: "",
    nomerteleponpenerima: "",
  });

  const handleAddressSelects = () => {
    setIsAddressSelected(true);
  };

  useEffect(() => {
    const totalCartWeight = cart.reduce((acc, item) => {
      const product = item.Produk;
      return acc + product.berat * item.jumlah; // Multiply weight by quantity
    }, 0);
    setTotalWeight(totalCartWeight); // Update total weight
  }, [cart]);

  useEffect(() => {
    const fetchOrigin = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/origin`
        );
        const data = await response.json();
        console.log("Origin API Response:", data); // Debugging response
        if (data && data[0] && data[0].kotaId) {
          setOrigin(data[0].kotaId); // Use kotaId from the response
        } else {
          console.error("No origin city ID found in the response.");
        }
      } catch (error) {
        console.error("Error fetching origin:", error);
      }
    };

    fetchOrigin();
  }, []); // This runs once when the component mounts

  // useEffect(() => {
  //   const fetchShippingCost = async () => {
  //     if (!origin || !selectedAddress) return; // Ensure origin and selectedAddress are available

  //     setLoading(true);
  //     try {
  //       fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`);

  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/cost`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             origin: origin, // Use dynamic origin
  //             destination: selectedAddress.kotaId, // Use cityId from selectedAddress
  //             weight: totalWeight, // Use total weight calculated from cart
  //             courier: courier, // Selected courier
  //           }),
  //         }
  //       );

  //       const data = await response.json();
  //       console.log("Shipping Cost API Response:", data); // Debugging response
  //       if (data.rajaongkir?.results?.length > 0) {
  //         const costs = data.rajaongkir.results[0].costs;
  //         setShippingOptions(costs);
  //       } else {
  //         console.error("No shipping options found.");
  //         setShippingOptions([]); // Set to empty if no results found
  //       }
  //     } catch (error) {
  //       console.error("Error fetching shipping cost:", error);
  //       setShippingOptions([]);
  //     }
  //     setLoading(false);
  //   };

  //   if (totalWeight > 0 && origin && selectedAddress?.kotaId) {
  //     fetchShippingCost(); // Fetch shipping cost when total weight, origin, and selected address are available
  //   }
  // }, [totalWeight, courier, origin, selectedAddress]); // Re-fetch when total weight, courier, origin, or selectedAddress changes


  useEffect(() => {
    // Retrieve selectedAddress from localStorage
    const storedAddress = localStorage.getItem("selectedAddress");
    const parsedAddress = storedAddress ? JSON.parse(storedAddress) : null;
  
    if (parsedAddress) {
      setSelectedAddress(parsedAddress);
    }
  
    // Check if required values are available
    if (!origin || !parsedAddress?.kotaId) {
      console.log("Shipping cost fetch skipped - missing origin or address.");
      return;
    }
  
    const fetchShippingCost = async () => {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`);
  
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/cost`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              origin: origin,
              destination: parsedAddress.kotaId, // Use parsedAddress
              weight: totalWeight,
              courier: courier,
            }),
          }
        );
  
        const data = await response.json();
        console.log("Shipping Cost API Response:", data);
  
        if (data.rajaongkir?.results?.length > 0) {
          setShippingOptions(data.rajaongkir.results[0].costs);
        } else {
          console.error("No shipping options found.");
          setShippingOptions([]);
        }
      } catch (error) {
        console.error("Error fetching shipping cost:", error);
        setShippingOptions([]);
      }
      setLoading(false);
    };
  
    fetchShippingCost();
  }, [totalWeight, courier, origin]); // Remove selectedAddress from dependencies
  
  
  console.log(selectedAddress);
  console.log("Selected Address Kota ID:", selectedAddress?.kotaId);

  const handleCourierChange = (e) => {
    setCourier(e.target.value);
  };
  console.log(setCourier);

  const handleOptionSelect = (option) => {
    setSelectedOption(option); // Store in state
    localStorage.setItem("selectedOption", JSON.stringify(option)); // Save to localStorage
  };
  

  const handleEditAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/alamat/${addressToEdit.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(addressToEdit),
        }
      );

      if (response.ok) {
        const updatedAddress = await response.json();
        setAddresses((prevAddresses) =>
          prevAddresses.map((addr) =>
            addr.id === updatedAddress.id ? updatedAddress : addr
          )
        );
        closeEditPopup();
      } else {
        const error = await response.json();
        alert(`Failed to update address: ${error.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alert("Failed to update address. Please try again later.");
    }

    console.log("Updated Address:", addressToEdit);
    setIsEditPopupVisible(false);
  };

  const closeEditPopup = () => {
    setIsEditPopupVisible(false);
  };

  const openEditPopup = (address) => {
    setAddressToEdit(address);
    setIsEditPopupVisible(true);
  };

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      setAddressLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");

        const addressResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/alamat`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!addressResponse.ok) {
          throw new Error(
            `Address fetch failed with status: ${addressResponse.status}`
          );
        }

        const addresses = await addressResponse.json();
        setAddresses(addresses || []);
      } catch (error) {
        console.error("Failed to fetch addresses", error);
        setAddresses([]);
      } finally {
        setAddressLoading(false);
      }
    };

    // Retrieve the full cart data from localStorage
    const storedSelectedCartItems =
      JSON.parse(localStorage.getItem("selectedCartItems")) || [];
    console.log("Stored selected cart items:", storedSelectedCartItems); // Debug log
    setCart(storedSelectedCartItems); // Set cart state with full items (not just ids)

    const fetchProductData = async () => {
      const productIds = storedSelectedCartItems.map((item) => item.produkId); // Extract produkId from cart items

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/produk/all`
        );
        if (!response.ok) {
          throw new Error(`Error fetching products: ${response.statusText}`);
        }

        const allProducts = await response.json();

        const selectedProducts = allProducts.filter((product) =>
          productIds.includes(product.id)
        );

        // Ensure all selected products have the `spesifikasi` field available
        setProducts(selectedProducts);
      } catch (error) {
        console.error("Failed to fetch products:", error);
        toast.error("Failed to fetch products. Please try again later.");
      }
    };

    fetchAddresses();
    fetchProductData();

    setCurrentStep(2);
    setCompletedSteps([true, false, false, false, false]);
  }, []);

  const formatRupiah = (angka) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(angka);
  };

  const calculateTotal = (selectedCart) => {
    return selectedCart.reduce((total, item) => {
      const product = item.Produk; // Assuming Produk is the product object

      if (product && product.harga && product.diskon !== undefined) {
        const price = parseFloat(product.harga) || 0;
        const discountPercentage = parseFloat(product.diskon) || 0;
        const quantity = parseInt(item.jumlah, 10) || 0;

        // Apply the discount if available
        const priceAfterDiscount =
          discountPercentage > 0
            ? price - (price * discountPercentage) / 100
            : price;

        // Add the price of this product (after discount) * quantity to the total
        return total + priceAfterDiscount * quantity;
      }

      return total;
    }, 0);
  };

  const totalPrice = calculateTotal(cart);

  const handleAddressSelect = (address) => {
    setSelectedAddress(address); // Simpan ke state
    localStorage.setItem("selectedAddressId", address.id); // Simpan id alamat ke localStorage
  };

  let colorsData = {};

  const fetchColors = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/color`
      );
      const data = await response.json();
      colorsData = data.colorList.reduce((acc, color) => {
        acc[color.color_hex.toLowerCase()] = color.color_name;
        return acc;
      }, {});
    } catch (error) {
      console.error("Failed to fetch colors:", error);
    }
  };

  const hexToName = (hex) => {
    return colorsData[hex.toLowerCase()] || hex;
  };

  const nextStep = async () => {
    if (!selectedAddress) {
      toast("You must select a shipping address to continue.", {
        type: "warning",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (currentStep < 5) {
      setCompletedSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[currentStep - 1] = true;
        return updatedSteps;
      });
    }

    // Ensure colorsData is populated before proceeding
    if (Object.keys(colorsData).length === 0) {
      await fetchColors();
    }

    const payload = {
      produkData: cart.map((item) => ({
        produkId: item.Produk.id,
        jumlah: item.jumlah,
        // color: hexToName(item.colorName), // Convert hex to color name
        color: item.color_hex, // Convert hex to color name
        harga_per_item: parseFloat(item.Produk.harga),
      })),
    };
    console.log(payload);

    try {
      // Step 1: Create the order
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/order/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Order creation failed:", errorData);
        throw new Error(errorData.message || "Failed to create order");
      }

      const data = await response.json();
      console.log("Order Response:", data);

      toast.success("Order created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Step 2: Delete items from cart after successful order creation
      const cartDeletePromises = cart.map((item) =>
        fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/${item.id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
      );

      await Promise.all(cartDeletePromises); // Wait for all delete requests to complete

      // Step 3: Clear selected items from localStorage
      localStorage.removeItem("selectedCartItems");
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("selectedAddressId");
      localStorage.removeItem("cart");

      // Step 4: Set snapUrl to localStorage and update the step
      if (data.snapUrl) {
        localStorage.setItem("snapUrl", data.snapUrl);
        setSnapUrl(data.snapUrl);
        toast.success("Payment gateway opened successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setOpenGateway(true); // Open modal right after showing toast
        setCurrentStep(currentStep + 1);
      } else {
        throw new Error("snapUrl is undefined in the response");
      }
      console.log(snapUrl);

      // Step 5: Redirect to homepage after successful order and clear cart state
      window.location.href = "/snap-url";
    } catch (error) {
      console.error("Error:", error);
      toast.error(`An error occurred: ${error.message || "Unknown error"}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const prevStep = () => {
    if (currentStep === 2) {
      navigate("/cart");
    } else if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepIndicator = (stepIndex, stepNumber) => {
    return completedSteps[stepIndex] ? (
      <FaCheckCircle size={45} color="#1D1615" />
    ) : (
      <div
        style={{
          width: "45px",
          height: "45px",
          borderRadius: "50%",
          border: "2px solid #1D1615",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        <span style={{ fontSize: "20px", color: "#1D1615" }}>{stepNumber}</span>
      </div>
    );
  };

  // Show pop-up with product specification when clicked
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [hoveredProductId, setHoveredProductId] = useState(null); // State untuk melacak produk yang di-hover

  // Perbaiki fungsi handleProductSpecificationClick
  const handleProductSpecificationClick = (product) => {
    setSelectedProductId(product.id); // Simpan ID produk yang diklik
    setShowPopup(true); // Tampilkan pop-up
  };

  // Perbaiki closePopup untuk reset selectedProductId
  const closePopup = () => {
    setShowPopup(false);
    setSelectedProductId(null); // Reset ID produk yang dipilih
  };

  const [formData, setFormData] = useState({
    namapenerima: "",
    alamat: "",
    kotaataukelurahan: "",
    provinsi: "",
    kodePos: "",
    main: "Rumah",
    nomeralamat: "",
    nomerteleponpenerima: "",
    status: "Utama",
    provinsiId: "",
    kotaId: "",
  });

  const handleAddAddress = async (event) => {
    event.preventDefault();

    // Validate data
    if (
      !formData.namapenerima ||
      !formData.alamat ||
      !formData.kotaataukelurahan ||
      !formData.provinsi ||
      !formData.kodePos
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/alamat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert("Address added successfully!");
        setAddresses((prev) => [...prev, data]); // Add the new address to the list
        setFormData({
          namapenerima: "",
          alamat: "",
          kotaataukelurahan: "",
          provinsi: "",
          kodePos: "",
          main: "Rumah",
          nomeralamat: "",
          nomerteleponpenerima: "",
          status: "Utama",
          provinsiId: "", // Added provinsiId field
          kotaId: "", // Added kotaId field
        }); // Reset form data
      } else {
        const error = await response.json();
        alert(`Failed to add address: ${error.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error adding address:", error);
      alert("Failed to add address. Please try again later.");
    }
  };

  // Fetch provinces
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/provinces`
        );
        if (response.ok) {
          const data = await response.json();
          setProvinces(data.rajaongkir.results);
        } else {
          console.error("Failed to fetch provinces");
        }
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };

    fetchProvinces();
  }, []);

  // Fetch cities when province changes
  const fetchCities = async (provinceId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/cities?province=${provinceId}`
      );
      if (response.ok) {
        const data = await response.json();
        setCities(data.rajaongkir.results);
      } else {
        console.error("Failed to fetch cities");
        setCities([]);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCities([]);
    }
  };

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "provinsi") {
      const selectedProvince = provinces.find(
        (province) => province.province === value
      );
      if (selectedProvince) {
        setFormData((prevData) => ({
          ...prevData,
          provinsiId: selectedProvince.province_id,
        }));
        fetchCities(selectedProvince.province_id);
      }
      setFormData((prevData) => ({
        ...prevData,
        kotaataukelurahan: "",
        kotaId: "",
      }));
    }

    if (name === "kotaataukelurahan") {
      const selectedCity = cities.find((city) => city.city_name === value);
      if (selectedCity) {
        setFormData((prevData) => ({
          ...prevData,
          kotaId: selectedCity.city_id,
        }));
      }
    }
  };

  fetchColors();

  console.log(snapUrl);

  const orderid = localStorage.getItem("orderId");
  console.log(orderid);

  useEffect(() => {
    console.log("snapUrl updated:", snapUrl);
  }, [snapUrl]);

  // Update VA URL when snapUrl changes
  useEffect(() => {
    const updateVaUrl = async () => {
      const orderId = localStorage.getItem("orderId");
      const token = localStorage.getItem("token");
      if (orderId && snapUrl) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/order/vaurl/${orderId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ vaurl: snapUrl }),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            console.error("Failed to update VA URL:", errorData);
            toast.error("Failed to update VA URL");
          } else {
            console.log("VA URL updated successfully.");
          }
        } catch (error) {
          console.error("Error updating VA URL:", error);
          toast.error("Error updating VA URL");
        }
      }
    };

    if (snapUrl) updateVaUrl();
  }, [snapUrl]);

  useEffect(() => {
    const storedSnapUrl = localStorage.getItem("snapUrl");
    console.log(snapUrl);
    if (storedSnapUrl) {
      setSnapUrl(storedSnapUrl);
      setOpenGateway(true);
      toast.success("Payment gateway opened successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      localStorage.removeItem("snapUrl");
    }
  }, []);

  const handleCloseGateway = () => {
    setOpenGateway(false);
    setSnapUrl(null);
  };

  const nextDelivery = () => {
    window.location.href = "/payment-page";
  };

  return (
    <>
      <div className="mt-28 mb-28 px-10 md:px-40 bg-gray-100 p-8">
        {/* <ToastContainer /> */}
        <div className="flex justify-between items-center mb-6 md:mb-8 lg:mb-10">
          {["Cart", "Shipping", "Delivery", "Payment", "Summary"].map(
            (step, index) => (
              <div
                key={index}
                className="flex items-center text-sm md:text-base lg:text-lg font-medium text-gray-800"
              >
                {/* Step Indicator with Checkmark for Cart and Shipping */}
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full text-white font-bold
          ${index < 2 ? "bg-green-500" : "bg-gray-300"}`}
                >
                  {index < 2 ? "✔" : index + 1}
                </div>

                {/* Step Name */}
                <div className="ml-3 flex items-center relative">
                  <p className="whitespace-nowrap">{step}</p>

                  {/* Connector Line */}
                  {index < 4 && (
                    <div className="absolute left-full ml-3 top-1/2 transform -translate-y-1/2 w-12 md:w-16 lg:w-20 border-b-4 border-gray-700"></div>
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <h2 className="text-3xl font-bold mb-6">Delivery Method</h2>

        {/* Shipping Methode */}
        <div className="bg-white p-5 mb-14 shadow-lg rounded-lg">
          <label htmlFor="courier" className="text-lg font-semibold">
            Select Shipping Method
          </label>
          <select
            id="courier"
            value={courier}
            onChange={handleCourierChange}
            className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <option value="jne">JNE</option>
            {/* <option value="pos">POS</option>
    <option value="tiki">TIKI</option> */}
            <option value="">Free Shipping</option>
            <option value="">Grab</option>
            <option value="">Gojek</option>
          </select>

          {/* Shipping Options Container */}
          <div className="mt-8 max-h-[300px] overflow-y-auto">
            {loading ? (
              <p className="text-gray-600">Loading shipping cost...</p>
            ) : shippingOptions.length > 0 ? (
              <div className="bg-white p-5 rounded-lg shadow">
                <h3 className="text-lg font-semibold">Shipping Options:</h3>
                {shippingOptions.map((option, index) => (
                  <div
                    key={index}
                    className={`p-4 my-2 rounded-lg border ${
                      selectedOption === option
                        ? "border-green-500"
                        : "border-gray-300"
                    }`}
                  >
                    <h4 className="text-md font-medium">
                      {option.service} - {option.description}
                    </h4>
                    <p>
                      <strong>Cost:</strong>{" "}
                      {formatRupiah(option.cost[0].value)}
                    </p>
                    <p>
                      <strong>Estimated Delivery Time (ETD):</strong>{" "}
                      {option.cost[0].etd} days
                    </p>
                    <button
                      className="mt-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                      onClick={() => handleOptionSelect(option)}
                    >
                      Select this option
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600">No shipping options available</p>
            )}

            {selectedOption && (
              <div className="bg-white p-5 mt-5 rounded-lg shadow">
                <h3 className="text-lg font-semibold">You selected:</h3>
                <div>
                  <h4 className="text-md font-medium">
                    {selectedOption.service} - {selectedOption.description}
                  </h4>
                  <p>
                    <strong>Cost:</strong>{" "}
                    {formatRupiah(selectedOption.cost[0].value)}
                  </p>
                  <p>
                    <strong>Estimated Delivery Time (ETD):</strong>{" "}
                    {selectedOption.cost[0].etd} days
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt-8 relative flex justify-end gap-5 md:gap-6 lg:gap-8">
          <button
            onClick={prevStep}
            disabled={currentStep === 1}
            className="w-[227px] h-14 text-lg border border-gray-800 text-gray-900 bg-white transition-all duration-300 ease-in-out 
               hover:bg-red-600 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {currentStep === 2 ? "Cancel" : "Previous"}
          </button>

          <button
            onClick={nextDelivery}
            disabled={currentStep === 4}
            className="w-[227px] h-14 text-lg text-white bg-gray-900 transition-all duration-300 ease-in-out 
               hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Checkout;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import apiClient from "../components/apiClient";

const AddressCard = () => {
  const [outletList, setOutletList] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_BASE_URL || "http://192.168.1.3:2000";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get("/outlet");
        setOutletList(response.data); // Assuming `response.data` is an array of outlets
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseURL]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto pt-2 pb-2 p-4 sm:pt-4 sm:pb-4 md:pt-6 md:pb-6 lg:pt-28 lg:pb-8">
        {outletList.map((outlet) => (
          <div
            key={outlet.id}
            className="flex bg-white shadow-md rounded-lg overflow-hidden mb-6 cursor-pointer hover:shadow-xl transition"
          >
            {/* Left Side: Image */}
            <div className="w-1/3">
              <img
                src={outlet.image}
                alt={outlet.nama_outlet}
                className="object-cover h-full w-full cursor-pointer"
                onClick={() => openModal(outlet.image)} // Open modal on click
              />
            </div>

            {/* Right Side: Address */}
            <div className="flex-1 p-6">
              <h2 className="text-3xl font-bold text-gray-800 mb-2">
                {outlet.nama_outlet}
              </h2>
              <p className="text-gray-600 mb-2 whitespace-pre-line">
                {outlet.alamat}
              </p>
              <p className="text-gray-600">
                <strong>Business hours:</strong> {outlet.jam_operasional}
                <br />
                <strong>Days:</strong> {outlet.hari_operasional}
              </p>
            </div>
          </div>
        ))}

        {/* Modal for Image */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                onClick={closeModal}
              >
                ✕
              </button>
              <img
                src={selectedImage}
                alt="Large View"
                className="max-w-full max-h-screen rounded-lg"
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default AddressCard;

import React, { useState } from "react";
import Footer from "../components/Footer";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section className="py-24">
        <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {/* Terms and Conditions Heading */}
          <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-5 text-center">
            Terms and Conditions
          </h2>
          {/* Terms and Conditions Content */}
          <p className="text-lg text-gray-700 leading-7 mb-4">
            Please read these terms and conditions carefully before using the
            Musique website. This website is owned and operated by Musique (PT
            V2 Indonesia).
          </p>
          <p className="text-lg text-gray-700 leading-7 mb-4">
            By placing an order on the web shop, you confirm that you have read
            and agree to these Terms and Conditions. We must receive payment for
            the full price of the goods you order before your order can be
            accepted.
          </p>
          <p className="text-lg text-gray-700 leading-7 mb-4">
            Once payment has been received, we will confirm that your order has
            been received by sending you an email.
          </p>
          <p className="text-lg text-gray-700 leading-7 font-bold">
            Returns & Exchanges
          </p>

          <p className="text-lg text-gray-700 leading-7 mb-4">
            If you are not satisfied, please let us know on WhatsApp number
            (+62) 821-2121-1509 or visit the
            <a href="/return-page" className="text-blue-500 underline">
              {" "}
              Return & Exchange Policy
            </a>
            . We will do our best to fix this problem.
          </p>
          <p className="text-lg text-gray-700 leading-7 font-bold">
            Availability
          </p>
          <p className="text-lg text-gray-700 leading-7 mb-4">
            All items depend on stock availability in our warehouse. Stock
            changes may occur. We will notify you as soon as possible if the
            goods you have ordered are not available.
          </p>
          <p className="text-lg text-gray-700 leading-7 font-bold">
            Content Accuracy
          </p>
          <p className="text-lg text-gray-700 leading-7">
            We take great care to ensure that our product descriptions and
            specifications are correct. However, there may be a difference in
            the original color with the appearance on the website. We are not
            responsible for any differences in color variations caused by the
            browser software or computer system you use.
          </p>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Accordion;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Mail from "../assets/Mail.png";
import Phone from "../assets/Phone.png";
import Pay from "../assets/bca.png";
import Payy from "../assets/mandirilo.png";
import Visa from "../assets/visa.png";
import Mastercard from "../assets/mastercard.png";
import Jne from "../assets/jne.png";
import Grab from "../assets/grab.png";
import Paxel from "../assets/paxel.png";
import { green } from "@mui/material/colors";
import Facebook from "../assets/facebook.png";
import Instagram from "../assets/instagram.png";
import Tiktok from "../assets/tiktok.png";
import { useNavigate } from "react-router-dom";
import apiClient from "../components/apiClient";

function Footer() {
  const [companyData, setCompanyData] = useState(null);
  const [brands, setBrands] = useState([]);
  const [produk, setProduk] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetching data from the endpoint
    fetch(`${process.env.REACT_APP_API_BASE_URL}/company`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          setCompanyData(data[0]);
        }
      })
      .catch((error) => console.error("Error fetching company data:", error));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`)
      .then((response) => response.json())
      .then((data) => {
        // Sort brands alphabetically by name
        // const sortedBrands = data.sort((a, b) =>
        //   a.nama_brand.localeCompare(b.nama_brand)
        // );
        // setBrands(sortedBrands.slice(0, 5));
        setBrands(data.slice(0, 5));
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleImageClick = (id) => {
    if (!id) {
      console.error("Brand ID is undefined");
      return;
    }
    // Set brandId in localStorage
    localStorage.setItem("brandId", id);
    // Navigate to the detail page
    navigate(`/detail-brand/${id}`);
  };

  if (!companyData) {
    return <div className="text-center">Loading...</div>; // Display loading state until data is fetched
  }

  return (
    <footer className="bg-black text-white py-10">
      <div className="container mx-auto hidden sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
        {/* Column 1: Displaying Logo */}
        <div className="relative flex flex-col items-center justify-between h-full py-8">
          {/* Centered Logo (Vertically & Horizontally) */}
          <img
            src={companyData.logo}
            alt="Company Logo"
            className="mx-auto mt-6"
          />

          {/* Social Media Icons at the Bottom */}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=100064649685970"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="Facebook Logo" className="h-6" />
            </a>
            <a
              href="https://www.instagram.com/musique.co.id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} alt="Instagram Logo" className="h-6" />
            </a>
            <a
              href="https://www.tiktok.com/@musique.co.id?_t=ZS-8u0lY1NFYRg&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Tiktok} alt="Tiktok Logo" className="h-6" />
            </a>
          </div>
        </div>

        {/* Column 2: Company Info */}
        {/* <div>
          <h5 className="text-lg font-semibold">MUSIQUE The Leading Stuff</h5>
        </div> */}
        {/* Column 3: Help Section */}
        <div>
          <h5 className="text-lg font-semibold">Support</h5>
          <Link to={"/aboutus-page"} className="block mt-2">
            About Us
          </Link>
          <Link to={"/term-page"} className="block mt-1">
            Term and Condition
          </Link>
          <Link to={"/faq-page"} className="block mt-1">
            FAQs
          </Link>
          <Link to={"/privacy-page"} className="block mt-1">
            Privacy Policy
          </Link>
          {/* <Link to={"/howto-page"} className="block mt-1">
            How to Place An Order
          </Link>
          <Link to={"/benefit-page"} className="block mt-1">
            Benefits of Creating A Musique Account
          </Link>
          <Link to={"/theorder-page"} className="block mt-1">
            The Order Not Being Accepted
          </Link>
          <Link to={"/paymentoption-page"} className="block mt-1">
            Payment Option
          </Link> */}
          <Link to={"/shiping-page"} className="block mt-1">
            Shipping and Delivery
          </Link>
          <Link to={"/return-page"} className="block mt-1">
            Return And Change
          </Link>
        </div>
        <div>
          <h5 className="text-lg font-semibold">Brands</h5>
          {brands.map((brand, item, index) => (
            <div
              // key={index}
              key={brand.id}
              // to={`/detail-brand/${brand.id}`} // Assuming a dynamic route for brands
              onClick={() => handleImageClick(brand.id)}
              className="block mt-1 cursor-pointer"
            >
              {brand.nama_brand}
            </div>
          ))}
          <Link
            to={"/all-brand"}
            className="block mt-1 font-medium text-blue-600"
          >
            All Brands
          </Link>
        </div>
        {/* Column 4: Contact Info */}
        <div>
          <h6 className="text-lg font-semibold">Contact Us</h6>
          <p className="flex items-center mt-2">
            <img src={Phone} alt="Phone Icon" className="mr-2" />
            {companyData.telepon}
          </p>
          <p className="flex items-center mt-1">
            <img src={Mail} alt="Mail Icon" className="mr-2" />
            {companyData.email}
          </p>
        </div>
        {/* Column 5: Payment Method */}
        <div>
          <h6 className="text-lg font-semibold">Payment Method</h6>
          <div className="flex flex-wrap gap-2 mt-2">
            <img src={Pay} alt="Payment Method Icon" className="w-12 h-8" />
            <img src={Payy} alt="Payment Method Icon" className="w-12 h-8" />
            <img src={Visa} alt="Payment Method Icon" className="w-12 h-8" />
            <img
              src={Mastercard}
              alt="Payment Method Icon"
              className="w-12 h-8"
            />
          </div>
          <h6 className="text-lg font-semibold mt-4">Shipment</h6>
          <div className="flex flex-wrap gap-2 mt-2">
            <img src={Jne} alt="JNE Icon" className="w-12 h-8" />
            <img src={Grab} alt="Grab Icon" className="w-12 h-8" />
            <img src={Paxel} alt="Paxel Icon" className="w-12 h-8" />
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="px-10 py-4 grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <img
              src={companyData.logo}
              alt="Company Logo"
              className="mx-auto w-32 h-10"
            />
          </div>

          <div className="col-span-2 flex justify-end items-center gap-4">
            <a
              href="https://www.facebook.com/profile.php?id=100064649685970"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="Facebook Logo" className="h-6" />
            </a>
            <a
              href="https://www.instagram.com/musique.co.id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} alt="Instagram Logo" className="h-6" />
            </a>
            <a
              href="https://www.tiktok.com/@musique.co.id?_t=ZS-8u0lY1NFYRg&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Tiktok} alt="Tiktok Logo" className="h-6" />
            </a>
          </div>
        </div>

        <div className="px-8 py-4 grid grid-cols-3 gap-4">
          <div className="col-span-1 text-sm">
            <h5 className="text-lg font-bold">Support</h5>
            <Link to={"/aboutus-page"} className="block mt-2">
              About Us
            </Link>
            <Link to={"/term-page"} className="block mt-1">
              Term and Condition
            </Link>
            <Link to={"/faq-page"} className="block mt-1">
              FAQs
            </Link>
            <Link to={"/privacy-page"} className="block mt-1">
              Privacy Policy
            </Link>

            <Link to={"/shiping-page"} className="block mt-1">
              Shipping and Delivery
            </Link>
            <Link to={"/return-page"} className="block mt-1">
              Return And Change
            </Link>
          </div>
          <div className="col-span-2 top-0 left-0 w-full text-sm flex justify-end items-start gap-4">
            <div>
              <h5 className="text-lg font-bold">Brands</h5>
              {brands.map((brand, item, index) => (
                <div
                  key={brand.id}
                  onClick={() => handleImageClick(brand.id)}
                  className="block mt-1 cursor-pointer"
                >
                  {brand.nama_brand}
                </div>
              ))}
              <Link
                to={"/all-brand"}
                className="block mt-1 font-medium text-blue-600"
              >
                All Brands
              </Link>
            </div>
          </div>
        </div>
        <div className="px-8 py-4 grid grid-cols-2 gap-4">
          {/* <div className="col-span-1"> */}
          <div className="flex flex-col justify-start items-start text-start gap-2">
            <h6 className="text-md font-semibold">Contact Us</h6>
            <p className="flex text-xs items-center mt-2">
              <img src={Phone} alt="Phone Icon" className="mr-2" />
              {companyData.telepon}
            </p>
            <p className="flex text-xs items-center mt-1">
              <img src={Mail} alt="Mail Icon" className="mr-2" />
              {companyData.email}
            </p>
          </div>
          {/* <div className="col-span-2 flex justify-end items-center text-end gap-4"> */}
          <div className="flex justify-end items-center text-end">
            <div>
              <h6 className="text-md font-semibold">Payment Method</h6>
              <div className="justify-end flex flex-wrap gap-2 mt-2">
                <img src={Pay} alt="Payment Method Icon" className="w-10 h-6" />
                <img
                  src={Payy}
                  alt="Payment Method Icon"
                  className="w-10 h-6"
                />
                <img
                  src={Visa}
                  alt="Payment Method Icon"
                  className="w-10 h-6"
                />
                <img
                  src={Mastercard}
                  alt="Payment Method Icon"
                  className="w-10 h-6"
                />
              </div>
              <h6 className="text-lg font-semibold mt-4">Shipment</h6>
              <div className="justify-end flex flex-wrap gap-2 mt-2">
                <img src={Jne} alt="JNE Icon" className="w-10 h-6" />
                <img src={Grab} alt="Grab Icon" className="w-10 h-6" />
                <img src={Paxel} alt="Paxel Icon" className="w-10 h-6" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-10">
        <hr className="border-gray-600" />
        <p className="mt-2">Copyright Musique. All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import { useLocation } from "react-router-dom";

function PDFViewer() {
    const location = useLocation();
    const { fileUrl } = location.state || {}; // Retrieve the file URL from state

    if (!fileUrl) {
        return <p>Invalid file URL. Please go back and try again.</p>;
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                flexDirection: "column",
                marginTop: "5rem"
            }}
        >
            <h1 style={{ marginBottom: "20px" }}>PDF Viewer</h1>
            <iframe
                src={fileUrl}
                title="PDF Viewer"
                style={{
                    width: "80%",
                    height: "80%",
                    border: "1px solid black",
                    borderRadius: "10px",
                }}
            />
        </div>
    );
}

export default PDFViewer;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode

const ProtectedRoute = ({ element }) => {
  const token = localStorage.getItem('token');
  
  // If there's no token, redirect to login page
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Decode the token to check expiration
  try {
    const decodedToken = jwtDecode(token); // Use jwtDecode to decode the token
    const currentTime = Date.now() / 1000; // Current time in seconds
    const tokenExpTime = decodedToken.exp; // Expiration time from the token

    // If the token has expired, redirect to the login page
    if (currentTime > tokenExpTime) {
      localStorage.removeItem('token'); // Optionally remove expired token
      return <Navigate to="/login" />;
    }
  } catch (error) {
    // If token is invalid or can't be decoded, redirect to login
    localStorage.removeItem('token');
    return <Navigate to="/login" />;
  }

  // If the token is valid, return the requested component
  return element;
};

export default ProtectedRoute;

import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Footer from "./Footer";
import {
  Snackbar,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Box, Modal } from "@mui/material";
import Bca from "../assets/bcalogo.png";
import Mandiri from "../assets/mandiri.png";
import Mastercard from "../assets/mastercardd.png";
import Visa from "../assets/visaa.png";
import Sceletol from "../assets/sceleton.png";
import { useDropzone } from "react-dropzone";

// function Checkout() {
const PaymentPage = () => {
  const [cart, setCart] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [cartLoading, setCartLoading] = useState(true);
  const [addressLoading, setAddressLoading] = useState(true);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [products, setProducts] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to control the pop-up visibility
  const [productSpecs, setProductSpecs] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [courier, setCourier] = useState("jne");
  const [origin, setOrigin] = useState(null);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editingAddress, setEditingAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [snapUrl, setSnapUrl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openGateway, setOpenGateway] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [firstItemClicked, setFirstItemClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showCards, setShowCards] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  const onClose = () => {
    setIsOpen(false); // Close modal or similar
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle form submission logic
  // };

  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState({
    namapenerima: "",
    nomerteleponpenerima: "",
  });

  const handleAddressSelects = () => {
    setIsAddressSelected(true);
  };

  useEffect(() => {
    const totalCartWeight = cart.reduce((acc, item) => {
      const product = item.Produk;
      return acc + product.berat * item.jumlah; // Multiply weight by quantity
    }, 0);
    setTotalWeight(totalCartWeight); // Update total weight
  }, [cart]);

  useEffect(() => {
    const fetchOrigin = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/origin`
        );
        const data = await response.json();
        console.log("Origin API Response:", data); // Debugging response
        if (data && data[0] && data[0].kotaId) {
          setOrigin(data[0].kotaId); // Use kotaId from the response
        } else {
          console.error("No origin city ID found in the response.");
        }
      } catch (error) {
        console.error("Error fetching origin:", error);
      }
    };

    fetchOrigin();
  }, []); // This runs once when the component mounts

  useEffect(() => {
    const fetchShippingCost = async () => {
      if (!origin || !selectedAddress) return; // Ensure origin and selectedAddress are available

      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`);

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/cost`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              origin: origin, // Use dynamic origin
              destination: selectedAddress.kotaId, // Use cityId from selectedAddress
              weight: totalWeight, // Use total weight calculated from cart
              courier: courier, // Selected courier
            }),
          }
        );

        const data = await response.json();
        console.log("Shipping Cost API Response:", data); // Debugging response
        if (data.rajaongkir?.results?.length > 0) {
          const costs = data.rajaongkir.results[0].costs;
          setShippingOptions(costs);
        } else {
          console.error("No shipping options found.");
          setShippingOptions([]); // Set to empty if no results found
        }
      } catch (error) {
        console.error("Error fetching shipping cost:", error);
        setShippingOptions([]);
      }
      setLoading(false);
    };

    if (totalWeight > 0 && origin && selectedAddress?.kotaId) {
      fetchShippingCost(); // Fetch shipping cost when total weight, origin, and selected address are available
    }
  }, [totalWeight, courier, origin, selectedAddress]); // Re-fetch when total weight, courier, origin, or selectedAddress changes

  const handleCourierChange = (e) => {
    setCourier(e.target.value);
  };
  console.log(setCourier);

  const handleOptionSelect = (option) => {
    setSelectedOption(option); // Store the selected option
  };

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    console.log("Stored Option:", storedOption);
    if (storedOption) {
      setSelectedOption(JSON.parse(storedOption)); // Convert back to object
    }
  }, []);

  const handleAddressSubmit = async (event) => {
    event.preventDefault();

    // Check for required fields
    if (
      !formData.alamat ||
      !formData.kotaataukelurahan ||
      !formData.provinsi ||
      !formData.kodePos ||
      !formData.status
    ) {
      setSnackbarMessage("Please fill out all required fields.");
      setOpenSnackbar(true);
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      setSnackbarMessage("User is not authenticated.");
      setOpenSnackbar(true);
      return;
    }

    // Prepare the request body
    const requestBody = {
      namapenerima: formData.namapenerima || "", // Provide empty string if not available
      nomerteleponpenerima: formData.nomerteleponpenerima || "", // Provide empty string if not available
      alamat: formData.alamat,
      kotaataukelurahan: formData.kotaataukelurahan,
      kotaId: formData.kotaId, // Ensure this is set when city is selected
      provinsi: formData.provinsi,
      provinsiId: formData.provinsiId, // Ensure this is set when province is selected
      kodePos: formData.kodePos,
      status: formData.status,
      main: formData.main || "", // Provide empty string if not available
      nomeralamat: formData.nomeralamat || "", // Provide empty string if not available
    };

    try {
      const url = editingAddress
        ? `${process.env.REACT_APP_API_BASE_URL}/alamat/${editingAddress.id}`
        : `${process.env.REACT_APP_API_BASE_URL}/alamat`;

      const method = editingAddress ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      console.log("Request Body:", requestBody);

      if (response.ok) {
        const data = await response.json();
        if (editingAddress) {
          setAddresses((prev) =>
            prev.map((address) => (address.id === data.id ? data : address))
          );
          setSnackbarMessage("Address updated successfully!");
        } else {
          setAddresses((prev) => [...prev, data]);
          setSnackbarMessage("Address added successfully!");
        }
        setOpenForm(false);
        setOpenSnackbar(true);
        window.location.reload();
      } else {
        const error = await response.json();
        setSnackbarMessage(
          `Failed to submit address: ${error.message || "Unknown error"}`
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error submitting address:", error);
      setSnackbarMessage("Failed to submit address. Please try again later.");
      setOpenSnackbar(true);
    }
  };

  const handleInputsChange = (e) => {
    const { name, value } = e.target;
    setAddressToEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/alamat/${addressToEdit.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(addressToEdit),
        }
      );

      if (response.ok) {
        const updatedAddress = await response.json();
        setAddresses((prevAddresses) =>
          prevAddresses.map((addr) =>
            addr.id === updatedAddress.id ? updatedAddress : addr
          )
        );
        closeEditPopup();
      } else {
        const error = await response.json();
        alert(`Failed to update address: ${error.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alert("Failed to update address. Please try again later.");
    }

    console.log("Updated Address:", addressToEdit);
    setIsEditPopupVisible(false);
  };

  const closeEditPopup = () => {
    setIsEditPopupVisible(false);
  };

  const openEditPopup = (address) => {
    setAddressToEdit(address);
    setIsEditPopupVisible(true);
  };

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      setAddressLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");

        const addressResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/alamat`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!addressResponse.ok) {
          throw new Error(
            `Address fetch failed with status: ${addressResponse.status}`
          );
        }

        const addresses = await addressResponse.json();
        setAddresses(addresses || []);
      } catch (error) {
        console.error("Failed to fetch addresses", error);
        setAddresses([]);
      } finally {
        setAddressLoading(false);
      }
    };

    // Retrieve the full cart data from localStorage
    const storedSelectedCartItems =
      JSON.parse(localStorage.getItem("selectedCartItems")) || [];
    console.log("Stored selected cart items:", storedSelectedCartItems); // Debug log
    setCart(storedSelectedCartItems); // Set cart state with full items (not just ids)

    const fetchProductData = async () => {
      const productIds = storedSelectedCartItems.map((item) => item.produkId); // Extract produkId from cart items

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/produk/all`
        );
        if (!response.ok) {
          throw new Error(`Error fetching products: ${response.statusText}`);
        }

        const allProducts = await response.json();

        const selectedProducts = allProducts.filter((product) =>
          productIds.includes(product.id)
        );

        // Ensure all selected products have the `spesifikasi` field available
        setProducts(selectedProducts);
      } catch (error) {
        console.error("Failed to fetch products:", error);
        toast.error("Failed to fetch products. Please try again later.");
      }
    };

    fetchAddresses();
    fetchProductData();

    setCurrentStep(2);
    setCompletedSteps([true, false, false, false, false]);
  }, []);

  const formatRupiah = (angka) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(angka);
  };

  const calculateTotal = (selectedCart) => {
    return selectedCart.reduce((total, item) => {
      const product = item.Produk; // Assuming Produk is the product object

      if (product && product.harga && product.diskon !== undefined) {
        const price = parseFloat(product.harga) || 0;
        const discountPercentage = parseFloat(product.diskon) || 0;
        const quantity = parseInt(item.jumlah, 10) || 0;

        // Apply the discount if available
        const priceAfterDiscount =
          discountPercentage > 0
            ? price - (price * discountPercentage) / 100
            : price;

        // Add the price of this product (after discount) * quantity to the total
        return total + priceAfterDiscount * quantity;
      }

      return total;
    }, 0);
  };

  const totalPrice = calculateTotal(cart);

  const handleAddressSelect = (address) => {
    setSelectedAddress(address); // Simpan ke state
    localStorage.setItem("selectedAddressId", address.id); // Simpan id alamat ke localStorage
  };

  let colorsData = {};

  const fetchColors = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/color`
      );
      const data = await response.json();
      colorsData = data.colorList.reduce((acc, color) => {
        acc[color.color_hex.toLowerCase()] = color.color_name;
        return acc;
      }, {});
    } catch (error) {
      console.error("Failed to fetch colors:", error);
    }
  };

  const hexToName = (hex) => {
    return colorsData[hex.toLowerCase()] || hex;
  };
  const nextStep = async () => {
    if (currentStep < 5) {
      setCompletedSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[currentStep - 1] = true;
        return updatedSteps;
      });
    }

    // Ensure colorsData is populated before proceeding
    if (Object.keys(colorsData).length === 0) {
      await fetchColors();
    }

    // Get User Selected Payment Type
    const selectedPaymentType =
      localStorage.getItem("paymentType") || "credit_card";

    const payload = {
      produkData: cart.map((item) => ({
        produkId: item.Produk.id,
        jumlah: item.jumlah,
        // color: hexToName(item.colorName), // Convert hex to color name
        color: item.color_hex, // Convert hex to color name
        // harga_per_item: parseFloat(item.Produk.harga),
        harga_per_item: parseFloat(item.subtotal),
      })),
      shippingCost: selectedOption?.cost?.[0]?.value || 0,
      paymentType: selectedPaymentType,
    };
    console.log(payload);

    try {
      // Step 1: Create the order
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/order/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Order creation failed:", errorData);
        throw new Error(errorData.message || "Failed to create order");
      }

      const data = await response.json();
      console.log("Order Response:", data);

      if (data.orderId) {
        localStorage.setItem("orderId", data.orderId); // Store orderId
        console.log("Order ID saved to localStorage:", data.orderId);
      } else {
        throw new Error("Order ID is missing in the response");
      }

      toast.success("Order created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Step 2: Delete items from cart after successful order creation
      const cartDeletePromises = cart.map((item) =>
        fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/${item.id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
      );

      await Promise.all(cartDeletePromises); // Wait for all delete requests to complete

      // Step 3: Clear selected items from localStorage
      localStorage.removeItem("selectedCartItems");
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("selectedAddressId");
      localStorage.removeItem("cart");

      // Step 4: Set snapUrl to localStorage and update the step
      // localStorage.setItem("snapUrl", data.snapUrl);
      // setSnapUrl(data.snapUrl);
      // setCurrentStep(currentStep + 1);

      // if (data.snapUrl) {
      //   localStorage.setItem("snapUrl", data.snapUrl);
      //   setSnapUrl(data.snapUrl);
      //   setCurrentStep(currentStep + 1);
      // } else {
      //   throw new Error("snapUrl is undefined in the response");
      // }

      if (data.snapUrl) {
        localStorage.setItem("snapUrl", data.snapUrl);
        setSnapUrl(data.snapUrl);
        toast.success("Payment gateway opened successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setOpenModal(true); // Open modal right after showing toast
        setCurrentStep(currentStep + 1);
      } else {
        throw new Error("snapUrl is undefined in the response");
      }

      // const orderId = localStorage.getItem("orderId");
      // if (orderId) {
      //   const vaUrl = "https://inijokocontoh.co.id"; // This is the vaurl value you want to update

      //   const vaUrlResponse = await fetch(
      //     `${process.env.REACT_APP_API_BASE_URL}/order/vaurl/${orderId}`,
      //     {
      //       method: "PUT", // Assuming you're using PUT to update the resource
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       },
      //       body: JSON.stringify({
      //         vaurl: vaUrl, // The VA URL to update
      //       }),
      //     }
      //   );

      //   if (!vaUrlResponse.ok) {
      //     const errorData = await vaUrlResponse.json();
      //     console.error("Failed to update VA URL:", errorData);
      //     toast.error("Failed to update VA URL");
      //   } else {
      //     console.log("VA URL updated successfully.");
      //   }
      // }

      // Step 5: Redirect to homepage after successful order and clear cart state
      window.location.href = "/snap-url";
    } catch (error) {
      console.error("Error:", error);
      toast.error(`An error occurred: ${error.message || "Unknown error"}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const prevStep = () => {
    if (currentStep === 2) {
      navigate("/cart");
    } else if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepIndicator = (stepIndex, stepNumber) => {
    return completedSteps[stepIndex] ? (
      <FaCheckCircle size={45} color="#1D1615" />
    ) : (
      <div
        style={{
          width: "45px",
          height: "45px",
          borderRadius: "50%",
          border: "2px solid #1D1615",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        <span style={{ fontSize: "20px", color: "#1D1615" }}>{stepNumber}</span>
      </div>
    );
  };

  // Show pop-up with product specification when clicked
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [hoveredProductId, setHoveredProductId] = useState(null); // State untuk melacak produk yang di-hover

  // Perbaiki fungsi handleProductSpecificationClick
  const handleProductSpecificationClick = (product) => {
    setSelectedProductId(product.id); // Simpan ID produk yang diklik
    setShowPopup(true); // Tampilkan pop-up
  };

  // Perbaiki closePopup untuk reset selectedProductId
  const closePopup = () => {
    setShowPopup(false);
    setSelectedProductId(null); // Reset ID produk yang dipilih
  };

  const [formData, setFormData] = useState({
    namapenerima: "",
    alamat: "",
    kotaataukelurahan: "",
    provinsi: "",
    kodePos: "",
    main: "Rumah",
    nomeralamat: "",
    nomerteleponpenerima: "",
    status: "Utama",
    provinsiId: "",
    kotaId: "",
  });

  // Fetch provinces
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/provinces`
        );
        if (response.ok) {
          const data = await response.json();
          setProvinces(data.rajaongkir.results);
        } else {
          console.error("Failed to fetch provinces");
        }
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };

    fetchProvinces();
  }, []);

  // Fetch cities when province changes
  const fetchCities = async (provinceId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/cities?province=${provinceId}`
      );
      if (response.ok) {
        const data = await response.json();
        setCities(data.rajaongkir.results);
      } else {
        console.error("Failed to fetch cities");
        setCities([]);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCities([]);
    }
  };

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "provinsi") {
      const selectedProvince = provinces.find(
        (province) => province.province === value
      );
      if (selectedProvince) {
        setFormData((prevData) => ({
          ...prevData,
          provinsiId: selectedProvince.province_id,
        }));
        fetchCities(selectedProvince.province_id);
      }
      setFormData((prevData) => ({
        ...prevData,
        kotaataukelurahan: "",
        kotaId: "",
      }));
    }

    if (name === "kotaataukelurahan") {
      const selectedCity = cities.find((city) => city.city_name === value);
      if (selectedCity) {
        setFormData((prevData) => ({
          ...prevData,
          kotaId: selectedCity.city_id,
        }));
      }
    }
  };

  fetchColors();

  console.log(snapUrl);

  const orderid = localStorage.getItem("orderId");
  console.log(orderid);

  useEffect(() => {
    console.log("snapUrl updated:", snapUrl);
  }, [snapUrl]);

  // Update VA URL when snapUrl changes
  useEffect(() => {
    const updateVaUrl = async () => {
      const orderId = localStorage.getItem("orderId");
      const token = localStorage.getItem("token");
      if (orderId && snapUrl) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/order/vaurl/${orderId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ vaurl: snapUrl }),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            console.error("Failed to update VA URL:", errorData);
            toast.error("Failed to update VA URL");
          } else {
            console.log("VA URL updated successfully.");
          }
        } catch (error) {
          console.error("Error updating VA URL:", error);
          toast.error("Error updating VA URL");
        }
      }
    };

    if (snapUrl) updateVaUrl();
  }, [snapUrl]);

  useEffect(() => {
    const storedSnapUrl = localStorage.getItem("snapUrl");
    console.log(snapUrl);
    if (storedSnapUrl) {
      setSnapUrl(storedSnapUrl);
      setOpenGateway(true);
      toast.success("Payment gateway opened successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      localStorage.removeItem("snapUrl");
    }
  }, []);

  const handleCloseGateway = () => {
    setOpenGateway(false);
    setSnapUrl(null);
  };

  const nextShipping = () => {
    window.location.href = "/shipping-page";
  };

  // const [activeIndex, setActiveIndex] = useState(0);

  // const toggleAccordion = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };

  const accordionItems = [
    {
      id: 1,
      ask: "Transfer Bank",
      title: "",
      content: "Bank BCA",
      image: Bca,
      norek: 7090366712,
      an: "V2 INDONESIA",
      conten: "Bank Mandiri",
      nore: 12200049991157,
      img: Mandiri,
    },
    {
      id: 2,
      ask: "Virtual Account",
    },
    {
      id: 3,
      ask: "Debit/Credit Card",
      gambar: Mastercard,
      gmbr: Visa,
    },
  ];

  const nextDelivery = () => {
    window.location.href = "/payment-page";
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleSectionClick = (bank) => {
    setShowCards(true);
    setSelectedBank(bank);
  };

  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  // Drag & Drop Logic
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*,application/pdf",
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ accountNumber, accountName, selectedFile });
  };

  // useEffect(() => {
  //   // Find if "Virtual Account" exists in accordionItems
  //   const virtualAccountItem = accordionItems.find(
  //     (item) => item.ask === "Virtual Account"
  //   );
  //   if (virtualAccountItem) {
  //     nextStep(); // Call the function
  //   }
  // }, []);

  return (
    <>
      <div className="mt-28 mb-28 px-10 md:px-40 bg-gray-100 p-8">
        {/* <ToastContainer /> */}
        <div className="flex justify-between items-center mb-6 md:mb-8 lg:mb-10">
          {["Cart", "Shipping", "Delivery", "Payment", "Summary"].map(
            (step, index) => (
              <div
                key={index}
                className="flex items-center text-sm md:text-base lg:text-lg font-medium text-gray-800"
              >
                {/* Step Indicator with Checkmark for Cart and Shipping */}
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full text-white font-bold
          ${index < 3 ? "bg-green-500" : "bg-gray-300"}`}
                >
                  {index < 3 ? "✔" : index + 1}
                </div>

                {/* Step Name */}
                <div className="ml-3 flex items-center relative">
                  <p className="whitespace-nowrap">{step}</p>

                  {/* Connector Line */}
                  {index < 4 && (
                    <div className="absolute left-full ml-3 top-1/2 transform -translate-y-1/2 w-12 md:w-16 lg:w-20 border-b-4 border-gray-700"></div>
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <h2 className="text-3xl font-bold mb-6">Payment</h2>

        <div>
          {!showCards ? (
            <div className="accordion-group bg-white p-5 mb-14 shadow-lg rounded-lg">
              <div>
                {accordionItems.map((item, index) => (
                  <div
                    key={item.id}
                    className={`accordion py-8 border-b border-solid border-gray-200 ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    <button
                      className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 bg-transparent border-b border-black p-0 hover:bg-transparent"
                      onClick={() => {
                        console.log(`Clicked on: ${item.ask}`); // Debugging
                        toggleAccordion(index);
                      }}
                      aria-controls={`accordion-content-${item.id}`}
                      aria-expanded={activeIndex === index}
                    >
                      <div className="flex items-center gap-4">
                        <h5
                          className="pb-2.5"
                          onClick={() => {
                            if (item.ask.trim() === "Virtual Account") {
                              console.log(
                                "Running nextStep() for Virtual Account"
                              );
                              localStorage.setItem(
                                "paymentType",
                                "bank_transfer"
                              ); // Store VA choice
                              nextStep();
                            } else if (item.ask.trim() === "Debit/Credit Card") {
                              console.log(
                                "Running nextStep() for Debit Card/CC"
                              );
                              localStorage.setItem(
                                "paymentType",
                                "credit_card"
                              ); // Store CC choice
                              nextStep();
                            }
                          }}
                        >
                          {item.ask}
                        </h5>

                        {item.gambar && (
                          <img
                            src={item.gambar}
                            className="w-16 h-12"
                            alt={`Gambar ${item.id}`}
                          />
                        )}
                        {item.gmbr && (
                          <img
                            src={item.gmbr}
                            className="w-16 h-6"
                            alt={`Gmbr ${item.id}`}
                          />
                        )}
                      </div>

                      <svg
                        className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 ${
                          activeIndex === index ? "rotate-180" : ""
                        }`}
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                          stroke="currentColor"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>

                    <div
                      id={`accordion-content-${item.id}`}
                      className={`accordion-content w-full px-0 overflow-hidden pr-4 transition-max-height duration-500 ease-in-out ${
                        activeIndex === index ? "max-h-screen" : "max-h-0"
                      }`}
                      style={{
                        maxHeight: activeIndex === index ? "300px" : "0",
                      }}
                    >
                      <h2 className="font-normal text-xl mb-4">{item.title}</h2>

                      {(item.image || item.content) && (
                        <div
                          className="flex items-center gap-4 cursor-pointer"
                          onClick={() => handleSectionClick("Bca")}
                        >
                          {item.image && (
                            <img
                              src={item.image}
                              className="w-16 h-12"
                              alt={`Bank ${item.id}`}
                            />
                          )}
                          {item.content && (
                            <p className="text-base font-normal text-gray-600">
                              {item.content}
                            </p>
                          )}
                        </div>
                      )}

                      {(item.img || item.conten) && (
                        <div
                          className="flex items-center gap-4 mt-2 cursor-pointer"
                          onClick={() => handleSectionClick("Mandiri")}
                        >
                          {item.img && (
                            <img
                              src={item.img}
                              className="w-16 h-8"
                              alt={`Bank ${item.id}`}
                            />
                          )}
                          {item.conten && (
                            <p className="text-base font-normal text-gray-600">
                              {item.conten}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-6 p-5 rounded-lg">
                <div className="col-span-2 grid grid-cols-2 p-4 border border-gray-300 rounded-lg shadow-lg bg-white">
                  <div className="justify-center space-y-4 mt-auto mb-auto">
                    <div>
                      <h3 className="text-lg font-semibold">Account Number</h3>
                      <p>79036612</p>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold">Account Name</h3>
                      <p>V2 INDONESIA</p>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold">Amount to Pay</h3>
                      <p>IDR 00.000.000</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 cursor-pointer justify-center">
                    <img
                      src={selectedBank === "Bca" ? Bca : Mandiri}
                      className="w-32 h-18"
                      alt={selectedBank}
                    />
                  </div>
                </div>
                <div className="col-span-1 p-4 border border-gray-300 rounded-lg shadow-lg bg-white">
                  <h3 className="text-lg font-semibold">Order Summary</h3>
                  <div className="product-selected">
                    {cart.length === 0 ? (
                      <p></p>
                    ) : (
                      <>
                        {cart.map((item) => {
                          const product = item.Produk;
                          const quantity = item.jumlah;
                          const color = item.colorName;
                          const subtotal = parseFloat(item.subtotal) || 0;
                          const discount = parseFloat(product.diskon) || 0;

                          let finalPrice = subtotal;
                          if (discount > 0) {
                            finalPrice -= (finalPrice * discount) / 100;
                          }
                          const totalPrice = finalPrice * quantity;

                          return (
                            <div
                              key={product.id}
                              className="bg-white p-2 rounded-lg"
                            >
                              <div className="flex flex-col md:flex-row items-center gap-4">
                                {/* Product Image */}
                                <div className="flex-shrink-0"></div>

                                {/* Product Details */}
                                <div className="flex-1">
                                  <h2 className="text-lg font-semibold">
                                    {product.nama_produk}
                                  </h2>
                                </div>

                                {/* Pricing & Other Details */}
                                <div className="text-right">
                                  <p className="text-sm">
                                    Quantity: {quantity}
                                  </p>
                                  <p className="text-sm">
                                    Weight: {product.berat} Kg
                                  </p>
                                  <p className="text-sm">Color: {color}</p>

                                  <div className="mt-2">
                                    <p className="text-sm">Subtotal:</p>
                                    <p className="font-bold text-lg">
                                      {formatRupiah(finalPrice)}
                                    </p>
                                    {product.diskon > 0 && (
                                      <p className="text-gray-500 line-through text-sm">
                                        {formatRupiah(subtotal)}
                                      </p>
                                    )}
                                  </div>

                                  <div className="mt-2">
                                    <p className="text-sm">Delivery:</p>
                                    <p className="font-bold text-lg">
                                      {/* {formatRupiah(finalPrice)} */}
                                      {formatRupiah(
                                        selectedOption
                                          ? selectedOption.cost[0].value
                                          : 0
                                      )}
                                    </p>
                                    {product.diskon && (
                                      <p className="text-gray-500 line-through text-sm">
                                        {formatRupiah(product.harga)}
                                      </p>
                                    )}
                                  </div>

                                  <div className="mt-2">
                                    <p className="text-sm">Total Amount Due:</p>
                                    <p className="font-bold text-lg">
                                      {/* {formatRupiah(finalPrice)} */}
                                      {formatRupiah(
                                        totalPrice +
                                          (selectedOption
                                            ? selectedOption.cost[0].value
                                            : 0)
                                      )}
                                    </p>
                                    {product.diskon && (
                                      <p className="text-gray-500 line-through text-sm">
                                        {formatRupiah(product.harga)}
                                      </p>
                                    )}
                                  </div>

                                  {/* <div className="flex justify-between items-center">
                                    <span className="text-xl md:text-xl font-bold">
                                      Total
                                    </span>
                                    <span className="text-xl md:text-xl font-bold">
                                      {formatRupiah(
                                        totalPrice +
                                          (selectedOption
                                            ? selectedOption.cost[0].value
                                            : 0)
                                      )}
                                      {formatRupiah(
                                        parseFloat(totalPrice) || 0
                                      )}
                                    </span>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="max-w-lg bg-white p-6 rounded-lg shadow-lg mt-10"> */}
              <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-6 p-5 rounded-lg">
                <div className="col-span-2 grid p-4 border border-gray-300 rounded-lg shadow-lg bg-white">
                  <h3 className="text-xl font-bold text-gray-800 text-center mb-6">
                    You need to enter your account information and payment proof
                    for payment verification by the admin.
                  </h3>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Account Number */}
                    <div>
                      <label className="block text-gray-700 font-medium">
                        Account Number
                      </label>
                      <input
                        type="text"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter Account Number"
                        required
                      />
                    </div>

                    {/* Account Name */}
                    <div>
                      <label className="block text-gray-700 font-medium">
                        Account Name
                      </label>
                      <input
                        type="text"
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter Account Name"
                        required
                      />
                    </div>

                    {/* File Upload Section */}
                    <div>
                      <label className="block text-gray-700 font-medium">
                        Upload File
                      </label>
                      {/* Drag & Drop Area */}
                      <div
                        {...getRootProps()}
                        // className="border-2 border-dashed border-gray-400 p-6 text-center rounded-lg cursor-pointer hover:border-blue-500"
                        className="border-2 border-dashed border-gray-400 p-6 text-center rounded-lg cursor-pointer hover:border-blue-500 flex flex-col items-center justify-center"
                      >
                        <input {...getInputProps()} />
                        {selectedFile ? (
                          <p className="text-gray-700">{selectedFile.name}</p>
                        ) : (
                          <>
                            <img
                              src={Sceletol}
                              className="w-20 h-20 object-contain mb-3 p-4"
                              alt="Upload Icon"
                            />
                            <label className="block text-gray-700 font-medium">
                              Drag & Drop file here, or click to select
                            </label>
                            <p className="text-gray-500 text-sm">
                              Attach bank receipt or transaction screenshot for
                              fast confirmation. File should not exceed 10mb.
                            </p>
                          </>
                        )}
                      </div>

                      {/* File Selection Button */}
                      {/* <input
                      type="file"
                      onChange={handleFileSelect}
                      className="mt-3 w-full p-2 border border-gray-300 rounded-lg"
                    /> */}
                    </div>

                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-all duration-300"
                    >
                      I HAVE PAID
                    </button>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentPage;

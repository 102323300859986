import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../components/Footer";
import {
  Snackbar,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
function Checkout() {
  const [cart, setCart] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [cartLoading, setCartLoading] = useState(true);
  const [addressLoading, setAddressLoading] = useState(true);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [products, setProducts] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to control the pop-up visibility
  const [productSpecs, setProductSpecs] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [courier, setCourier] = useState("jne");
  const [origin, setOrigin] = useState(null);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [editingAddress, setEditingAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [snapUrl, setSnapUrl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setIsOpen(false); // Close modal or similar
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic
  };

  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState({
    namapenerima: "",
    nomerteleponpenerima: "",
  });

  const handleAddressSelects = () => {
    setIsAddressSelected(true);
  };

  useEffect(() => {
    const totalCartWeight = cart.reduce((acc, item) => {
      const product = item.Produk;
      return acc + product.berat * item.jumlah; // Multiply weight by quantity
    }, 0);
    setTotalWeight(totalCartWeight); // Update total weight
  }, [cart]);

  useEffect(() => {
    const fetchOrigin = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/origin`
        );
        const data = await response.json();
        console.log("Origin API Response:", data); // Debugging response
        if (data && data[0] && data[0].kotaId) {
          setOrigin(data[0].kotaId); // Use kotaId from the response
        } else {
          console.error("No origin city ID found in the response.");
        }
      } catch (error) {
        console.error("Error fetching origin:", error);
      }
    };

    fetchOrigin();
  }, []); // This runs once when the component mounts

  useEffect(() => {
    const fetchShippingCost = async () => {
      if (!origin || !selectedAddress) return; // Ensure origin and selectedAddress are available

      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`);

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/cost`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              origin: origin, // Use dynamic origin
              destination: selectedAddress.kotaId, // Use cityId from selectedAddress
              weight: totalWeight, // Use total weight calculated from cart
              courier: courier, // Selected courier
            }),
          }
        );

        const data = await response.json();
        console.log("Shipping Cost API Response:", data); // Debugging response
        if (data.rajaongkir?.results?.length > 0) {
          const costs = data.rajaongkir.results[0].costs;
          setShippingOptions(costs);
        } else {
          console.error("No shipping options found.");
          setShippingOptions([]); // Set to empty if no results found
        }
      } catch (error) {
        console.error("Error fetching shipping cost:", error);
        setShippingOptions([]);
      }
      setLoading(false);
    };

    if (totalWeight > 0 && origin && selectedAddress?.kotaId) {
      fetchShippingCost(); // Fetch shipping cost when total weight, origin, and selected address are available
    }
  }, [totalWeight, courier, origin, selectedAddress]); // Re-fetch when total weight, courier, origin, or selectedAddress changes

  console.log("Selected Address Kota ID:", selectedAddress?.kotaId);

  const handleCourierChange = (e) => {
    setCourier(e.target.value);
  };
  console.log(setCourier);

  const handleOptionSelect = (option) => {
    setSelectedOption(option); // Store the selected option
  };

  // const handleAddressSubmit = async (event) => {
  //   event.preventDefault();

  //   console.log("Submitting form data:", formData);

  //   if (
  //     !formData.namapenerima ||
  //     !formData.alamat ||
  //     !formData.kotaataukelurahan ||
  //     !formData.kodePos
  //   ) {
  //     setSnackbarMessage("Please fill out all required fields.");
  //     setOpenSnackbar(true);
  //     return;
  //   }

  //   // if (
  //   //   !formData.alamat ||
  //   //   !formData.kota ||
  //   //   !formData.kecamatan ||
  //   //   !formData.kelurahan ||
  //   //   !formData.provinsi ||
  //   //   !formData.kodePos ||
  //   //   !formData.status
  //   // ) {
  //   //   setSnackbarMessage("Please fill out all required fields.");
  //   //   setOpenSnackbar(true);
  //   //   return;
  //   // }

  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     setSnackbarMessage("User is not authenticated.");
  //     setOpenSnackbar(true);
  //     return;
  //   }

  //   try {
  //     let response;
  //     if (editingAddress) {
  //       // Update existing address
  //       response = await fetch(
  //         `http://192.168.1.3:2000/alamat/${editingAddress.id}`,
  //         {
  //           method: "PUT",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //           body: JSON.stringify(formData),
  //         }
  //       );
  //     } else {
  //       // Add new address
  //       response = await fetch("http://192.168.1.3:2000/alamat", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //         body: JSON.stringify(formData),
  //       });
  //     }

  //     if (response.ok) {
  //       const data = await response.json();
  //       if (editingAddress) {
  //         setAddresses((prev) =>
  //           prev.map((address) => (address.id === data.id ? data : address))
  //         );
  //         setSnackbarMessage("Address updated successfully!");
  //       } else {
  //         setAddresses((prev) => [...prev, data]);
  //         setSnackbarMessage("Address added successfully!");
  //       }
  //       setOpenForm(false);
  //       setOpenSnackbar(true);

  //       // Reload the page after successful update or addition
  //       window.location.reload();
  //     } else {
  //       const error = await response.json();
  //       setSnackbarMessage(
  //         `Failed to submit address: ${error.message || "Unknown error"}`
  //       );
  //       setOpenSnackbar(true);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting address:", error);
  //     setSnackbarMessage("Failed to submit address. Please try again later.");
  //     setOpenSnackbar(true);
  //   }

  //   //   try {
  //   //     const url = editingAddress
  //   //         ? `http://192.168.1.3:2000/alamat/${editingAddress.id}`
  //   //         : "http://192.168.1.3:2000/alamat";

  //   //     const method = editingAddress ? "PUT" : "POST";

  //   //     const response = await fetch(url, {
  //   //         method,
  //   //         headers: {
  //   //             "Content-Type": "application/json",
  //   //             Authorization: `Bearer ${token}`,
  //   //         },
  //   //         body: JSON.stringify(formData),
  //   //     });

  //   //     const data = await response.json();

  //   //     if (!response.ok) {
  //   //         throw new Error(data.message || "Failed to submit address");
  //   //     }

  //   //     if (editingAddress) {
  //   //         setAddresses((prev) =>
  //   //             prev.map((address) => (address.id === data.id ? data : address))
  //   //         );
  //   //         setSnackbarMessage("Address updated successfully!");
  //   //     } else {
  //   //         setAddresses((prev) => [...prev, data]);
  //   //         setSnackbarMessage("Address added successfully!");
  //   //     }

  //   //     setOpenForm(false);
  //   //     setOpenSnackbar(true);

  //   // } catch (error) {
  //   //     console.error("Error submitting address:", error);
  //   //     setSnackbarMessage("Failed to submit address. Please try again later.");
  //   //     setOpenSnackbar(true);
  //   // }

  //   // try {
  //   //   const response = await fetch("http://192.168.1.3:2000/alamat", {
  //   //     method: "POST",
  //   //     headers: {
  //   //       "Content-Type": "application/json",
  //   //       Authorization: `Bearer ${token}`,
  //   //     },
  //   //     body: JSON.stringify(formData),
  //   //   });

  //   //   const data = await response.json();
  //   //   console.log("Response data:", data); // Log backend response

  //   //   if (!response.ok) {
  //   //     throw new Error(data.message || "Failed to submit address");
  //   //   }

  //   //   setSnackbarMessage("Address added successfully!");
  //   //   setOpenForm(false);
  //   //   setOpenSnackbar(true);
  //   // } catch (error) {
  //   //   console.error("Error submitting address:", error);
  //   //   setSnackbarMessage("Failed to submit address. Please try again later.");
  //   //   setOpenSnackbar(true);
  //   // }

  // };

  const handleAddressSubmit = async (event) => {
    event.preventDefault();

    // Check for required fields
    if (
      !formData.alamat ||
      !formData.kotaataukelurahan ||
      !formData.provinsi ||
      !formData.kodePos ||
      !formData.status
    ) {
      setSnackbarMessage("Please fill out all required fields.");
      setOpenSnackbar(true);
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      setSnackbarMessage("User is not authenticated.");
      setOpenSnackbar(true);
      return;
    }

    // Prepare the request body
    const requestBody = {
      namapenerima: formData.namapenerima || "", // Provide empty string if not available
      nomerteleponpenerima: formData.nomerteleponpenerima || "", // Provide empty string if not available
      alamat: formData.alamat,
      kotaataukelurahan: formData.kotaataukelurahan,
      kotaId: formData.kotaId, // Ensure this is set when city is selected
      provinsi: formData.provinsi,
      provinsiId: formData.provinsiId, // Ensure this is set when province is selected
      kodePos: formData.kodePos,
      status: formData.status,
      main: formData.main || "", // Provide empty string if not available
      nomeralamat: formData.nomeralamat || "", // Provide empty string if not available
    };

    try {
      const url = editingAddress
        ? `${process.env.REACT_APP_API_BASE_URL}/alamat/${editingAddress.id}`
        : `${process.env.REACT_APP_API_BASE_URL}/alamat`;

      const method = editingAddress ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      console.log("Request Body:", requestBody);

      if (response.ok) {
        const data = await response.json();
        if (editingAddress) {
          setAddresses((prev) =>
            prev.map((address) => (address.id === data.id ? data : address))
          );
          setSnackbarMessage("Address updated successfully!");
        } else {
          setAddresses((prev) => [...prev, data]);
          setSnackbarMessage("Address added successfully!");
        }
        setOpenForm(false);
        setOpenSnackbar(true);
        window.location.reload();
      } else {
        const error = await response.json();
        setSnackbarMessage(
          `Failed to submit address: ${error.message || "Unknown error"}`
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error submitting address:", error);
      setSnackbarMessage("Failed to submit address. Please try again later.");
      setOpenSnackbar(true);
    }
  };

  const handleInputsChange = (e) => {
    const { name, value } = e.target;
    setAddressToEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/alamat/${addressToEdit.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(addressToEdit),
        }
      );

      if (response.ok) {
        const updatedAddress = await response.json();
        setAddresses((prevAddresses) =>
          prevAddresses.map((addr) =>
            addr.id === updatedAddress.id ? updatedAddress : addr
          )
        );
        closeEditPopup();
      } else {
        const error = await response.json();
        alert(`Failed to update address: ${error.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alert("Failed to update address. Please try again later.");
    }

    console.log("Updated Address:", addressToEdit);
    setIsEditPopupVisible(false);
  };

  const closeEditPopup = () => {
    setIsEditPopupVisible(false);
  };

  const openEditPopup = (address) => {
    setAddressToEdit(address);
    setIsEditPopupVisible(true);
  };

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      setAddressLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");

        const addressResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/alamat`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!addressResponse.ok) {
          throw new Error(
            `Address fetch failed with status: ${addressResponse.status}`
          );
        }

        const addresses = await addressResponse.json();
        setAddresses(addresses || []);
      } catch (error) {
        console.error("Failed to fetch addresses", error);
        setAddresses([]);
      } finally {
        setAddressLoading(false);
      }
    };

    // Retrieve the full cart data from localStorage
    const storedSelectedCartItems =
      JSON.parse(localStorage.getItem("selectedCartItems")) || [];
    console.log("Stored selected cart items:", storedSelectedCartItems); // Debug log
    setCart(storedSelectedCartItems); // Set cart state with full items (not just ids)

    const fetchProductData = async () => {
      const productIds = storedSelectedCartItems.map((item) => item.produkId); // Extract produkId from cart items

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/produk/all`
        );
        if (!response.ok) {
          throw new Error(`Error fetching products: ${response.statusText}`);
        }

        const allProducts = await response.json();

        const selectedProducts = allProducts.filter((product) =>
          productIds.includes(product.id)
        );

        // Ensure all selected products have the `spesifikasi` field available
        setProducts(selectedProducts);
      } catch (error) {
        console.error("Failed to fetch products:", error);
        toast.error("Failed to fetch products. Please try again later.");
      }
    };

    fetchAddresses();
    fetchProductData();

    setCurrentStep(2);
    setCompletedSteps([true, false, false, false, false]);
  }, []);

  const formatRupiah = (angka) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(angka);
  };

  const calculateTotal = (selectedCart) => {
    return selectedCart.reduce((total, item) => {
      const product = item.Produk; // Assuming Produk is the product object

      if (product && product.harga && product.diskon !== undefined) {
        // const price = parseFloat(product.harga) || 0;
        const price = localStorage.getItem("selectedPrice") || 0;
        console.log(price);
        const discountPercentage = parseFloat(product.diskon) || 0;
        const quantity = parseInt(item.jumlah, 10) || 0;

        // Apply the discount if available
        const priceAfterDiscount =
          discountPercentage > 0
            ? price - (price * discountPercentage) / 100
            : price;

        // Add the price of this product (after discount) * quantity to the total
        return total + priceAfterDiscount * quantity;
      }

      return total;
    }, 0);
  };

  const totalPrice = calculateTotal(cart);
  console.log(totalPrice);

  const handleAddressSelect = (address) => {
    setSelectedAddress(address); // Simpan ke state
    localStorage.setItem("selectedAddressId", address.id); // Simpan id alamat ke localStorage
    localStorage.setItem("selectedAddress", JSON.stringify(address));
  };

  let colorsData = {};

  const fetchColors = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/color`
      );
      const data = await response.json();
      colorsData = data.colorList.reduce((acc, color) => {
        acc[color.color_hex.toLowerCase()] = color.color_name;
        return acc;
      }, {});
    } catch (error) {
      console.error("Failed to fetch colors:", error);
    }
  };

  const hexToName = (hex) => {
    return colorsData[hex.toLowerCase()] || hex;
  };

  // const nextStep = async () => {
  //   if (!selectedAddress) {
  //     toast("You must select a shipping address to continue.", {
  //       type: "warning",
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //     return;
  //   }

  //   if (currentStep < 5) {
  //     setCompletedSteps((prevSteps) => {
  //       const updatedSteps = [...prevSteps];
  //       updatedSteps[currentStep - 1] = true;
  //       return updatedSteps;
  //     });
  //   }

  //   const payload = {
  //     produkData: cart.map((item) => ({
  //       produkId: item.Produk.id,
  //       jumlah: item.jumlah,
  //       color: item.colorName,
  //       harga_per_item: parseFloat(item.Produk.harga),
  //       // color: item.Produk.color || "#ffffff",
  //       color: item.Produk.color,
  //     })),
  //   };
  //   console.log(payload);

  //   try {
  //     // Step 1: Create the order
  //     const response = await fetch("http://192.168.1.3:2000/order/create", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       console.error("Order creation failed:", errorData);
  //       throw new Error(errorData.message || "Failed to create order");
  //     }

  //     const data = await response.json();
  //     console.log("Order Response:", data);

  //     toast.success("Order created successfully!", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });

  //     // Step 2: Delete items from cart after successful order creation
  //     const cartDeletePromises = cart.map((item) =>
  //       fetch(`http://192.168.1.3:2000/cart/${item.Produk.id}`, {
  //         method: "DELETE",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       })
  //     );

  //     await Promise.all(cartDeletePromises); // Wait for all delete requests to complete

  //     // Step 3: Clear selected items from localStorage
  //     localStorage.removeItem("selectedCartItems"); // Clear selected cart items
  //     localStorage.removeItem("selectedItems"); // Clear selected items
  //     localStorage.removeItem("selectedAddressId"); // Clear selected items
  //     localStorage.removeItem("cart"); // Clear selected items

  //     // Step 4: Set snapUrl to localStorage and update the step
  //     localStorage.setItem("snapUrl", data.snapUrl); // Save snapUrl to localStorage
  //     setSnapUrl(data.snapUrl); // Optionally set it in state as well
  //     setCurrentStep(currentStep + 1);

  //     // Step 5: Redirect to homepage after successful order and clear cart state
  //     window.location.href = "/"; // Redirect to homepage
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error(`An error occurred: ${error.message || "Unknown error"}`, {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //   }
  // };

  const ya = localStorage.getItem("orderId");
  console.log(ya);

  const nextStep = async () => {
    if (!selectedAddress) {
      toast("You must select a shipping address to continue.", {
        type: "warning",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (currentStep < 5) {
      setCompletedSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[currentStep - 1] = true;
        return updatedSteps;
      });
    }

    // Ensure colorsData is populated before proceeding
    if (Object.keys(colorsData).length === 0) {
      await fetchColors();
    }

    const payload = {
      produkData: cart.map((item) => ({
        produkId: item.Produk.id,
        jumlah: item.jumlah,
        // color: hexToName(item.colorName), // Convert hex to color name
        color: item.color_hex, // Convert hex to color name
        harga_per_item: parseFloat(item.Produk.harga),
      })),
    };
    console.log(payload);

    try {
      // Step 1: Create the order
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/order/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Order creation failed:", errorData);
        throw new Error(errorData.message || "Failed to create order");
      }

      const data = await response.json();
      console.log("Order Response:", data);

      if (data.orderId) {
        localStorage.setItem("orderId", data.orderId); // Store orderId
        console.log("Order ID saved to localStorage:", data.orderId);
      } else {
        throw new Error("Order ID is missing in the response");
      }

      toast.success("Order created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Step 2: Delete items from cart after successful order creation
      const cartDeletePromises = cart.map((item) =>
        fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/${item.id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
      );

      await Promise.all(cartDeletePromises); // Wait for all delete requests to complete

      // Step 3: Clear selected items from localStorage
      localStorage.removeItem("selectedCartItems");
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("selectedAddressId");
      localStorage.removeItem("cart");

      // Step 4: Set snapUrl to localStorage and update the step
      // localStorage.setItem("snapUrl", data.snapUrl);
      // setSnapUrl(data.snapUrl);
      // setCurrentStep(currentStep + 1);

      // if (data.snapUrl) {
      //   localStorage.setItem("snapUrl", data.snapUrl);
      //   setSnapUrl(data.snapUrl);
      //   setCurrentStep(currentStep + 1);
      // } else {
      //   throw new Error("snapUrl is undefined in the response");
      // }

      if (data.snapUrl) {
        localStorage.setItem("snapUrl", data.snapUrl);
        setSnapUrl(data.snapUrl);
        toast.success("Payment gateway opened successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setOpenModal(true); // Open modal right after showing toast
        setCurrentStep(currentStep + 1);
      } else {
        throw new Error("snapUrl is undefined in the response");
      }

      const orderId = localStorage.getItem("orderId");
      if (orderId) {
        const vaUrl = "https://inijokocontoh.co.id"; // This is the vaurl value you want to update

        const vaUrlResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/order/vaurl/${orderId}`,
          {
            method: "PUT", // Assuming you're using PUT to update the resource
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              vaurl: vaUrl, // The VA URL to update
            }),
          }
        );

        if (!vaUrlResponse.ok) {
          const errorData = await vaUrlResponse.json();
          console.error("Failed to update VA URL:", errorData);
          toast.error("Failed to update VA URL");
        } else {
          console.log("VA URL updated successfully.");
        }
      }

      // Step 5: Redirect to homepage after successful order and clear cart state
      window.location.href = "/snap-url";
    } catch (error) {
      console.error("Error:", error);
      toast.error(`An error occurred: ${error.message || "Unknown error"}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const nextDelivery = () => {
    window.location.href = "/delivery-page";
  };

  const prevStep = () => {
    if (currentStep === 2) {
      navigate("/cart");
    } else if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepIndicator = (stepIndex, stepNumber) => {
    return completedSteps[stepIndex] ? (
      <FaCheckCircle size={45} color="#1D1615" />
    ) : (
      <div
        style={{
          width: "45px",
          height: "45px",
          borderRadius: "50%",
          border: "2px solid #1D1615",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        <span style={{ fontSize: "20px", color: "#1D1615" }}>{stepNumber}</span>
      </div>
    );
  };

  // Show pop-up with product specification when clicked
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [hoveredProductId, setHoveredProductId] = useState(null); // State untuk melacak produk yang di-hover

  // Perbaiki fungsi handleProductSpecificationClick
  const handleProductSpecificationClick = (product) => {
    setSelectedProductId(product.id); // Simpan ID produk yang diklik
    setShowPopup(true); // Tampilkan pop-up
  };

  // Perbaiki closePopup untuk reset selectedProductId
  const closePopup = () => {
    setShowPopup(false);
    setSelectedProductId(null); // Reset ID produk yang dipilih
  };

  const [formData, setFormData] = useState({
    namapenerima: "",
    alamat: "",
    kotaataukelurahan: "",
    provinsi: "",
    kodePos: "",
    main: "Rumah",
    nomeralamat: "",
    nomerteleponpenerima: "",
    status: "Utama",
    provinsiId: "",
    kotaId: "",
  });

  const handleAddAddress = async (event) => {
    event.preventDefault();

    // Validate data
    if (
      !formData.namapenerima ||
      !formData.alamat ||
      !formData.kotaataukelurahan ||
      !formData.provinsi ||
      !formData.kodePos
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/alamat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert("Address added successfully!");
        setAddresses((prev) => [...prev, data]); // Add the new address to the list
        setFormData({
          namapenerima: "",
          alamat: "",
          kotaataukelurahan: "",
          provinsi: "",
          kodePos: "",
          main: "Rumah",
          nomeralamat: "",
          nomerteleponpenerima: "",
          status: "Utama",
          provinsiId: "", // Added provinsiId field
          kotaId: "", // Added kotaId field
        }); // Reset form data
      } else {
        const error = await response.json();
        alert(`Failed to add address: ${error.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error adding address:", error);
      alert("Failed to add address. Please try again later.");
    }
  };

  // Fetch provinces
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/provinces`
        );
        if (response.ok) {
          const data = await response.json();
          setProvinces(data.rajaongkir.results);
        } else {
          console.error("Failed to fetch provinces");
        }
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };

    fetchProvinces();
  }, []);

  // Fetch cities when province changes
  const fetchCities = async (provinceId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/cities?province=${provinceId}`
      );
      if (response.ok) {
        const data = await response.json();
        setCities(data.rajaongkir.results);
      } else {
        console.error("Failed to fetch cities");
        setCities([]);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCities([]);
    }
  };

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "provinsi") {
      const selectedProvince = provinces.find(
        (province) => province.province === value
      );
      if (selectedProvince) {
        setFormData((prevData) => ({
          ...prevData,
          provinsiId: selectedProvince.province_id,
        }));
        fetchCities(selectedProvince.province_id);
      }
      setFormData((prevData) => ({
        ...prevData,
        kotaataukelurahan: "",
        kotaId: "",
      }));
    }

    if (name === "kotaataukelurahan") {
      const selectedCity = cities.find((city) => city.city_name === value);
      if (selectedCity) {
        setFormData((prevData) => ({
          ...prevData,
          kotaId: selectedCity.city_id,
        }));
      }
    }
  };

  fetchColors();

  const steps = ["Cart", "Shipping", "Delivery", "Payment", "Summary"];

  return (
    <>
      <div className="mt-28 mb-28 px-10 md:px-40 bg-gray-100">
        <ToastContainer />
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          {["Cart", "Shipping", "Payment", "Summary"].map((step, index) => (
            <div
              key={index}
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {renderStepIndicator(index, index + 1)}
              <div
                style={{
                  marginLeft: "36px",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <p style={{ margin: 0 }}>{step}</p>
                <div
                  style={{
                    position: "absolute",
                    left: "100%",
                    marginLeft: "36px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "50.5px",
                    borderBottom: "4px solid #1D1615",
                    height: "0",
                  }}
                />
              </div>
              {index < 4 && (
                <div
                  style={{
                    marginLeft: "36px",
                  }}
                ></div>
              )}
            </div>
          ))}
        </div> */}

        <div className="flex justify-between items-center mb-6 md:mb-8 lg:mb-10">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center relative">
              {/* Step Indicator */}
              {renderStepIndicator(index, index + 1)}

              {/* Step Label */}
              <div className="ml-4 flex items-center relative">
                <p className="text-sm md:text-base lg:text-lg font-medium text-gray-800">
                  {step}
                </p>

                {/* Connecting Line */}
                {index < steps.length - 1 && (
                  <div className="absolute left-full ml-4 top-1/2 transform -translate-y-1/2 w-12 md:w-16 lg:w-20 border-b-4 border-gray-700"></div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "32px",
          }}
        >
          <div>
            {currentStep === 2 && (
              <div>
                <div
                  style={{
                    fontSize: "40px",
                    fontWeight: "700",
                    marginBottom: "50px",
                  }}
                >
                  Your Shipping Address
                </div>
                {addressLoading ? (
                  <p>Loading addresses...</p>
                ) : addresses.length === 0 ? (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      {!addresses.length && (
                        <div
                          style={{
                            fontSize: "24px",
                            fontWeight: "800",
                            marginBottom: "16px",
                          }}
                        >
                          You do not have any addresses added to your account.
                        </div>
                      )}
                      <button
                        onClick={() => setOpenForm(true)}
                        style={{
                          width: "auto",
                          height: "56px",
                          padding: "0 32px",
                          backgroundColor: "#1D1615",
                          fontSize: "24px",
                          fontWeight: "500",
                          borderRadius: "0",
                          color: "#fff",
                          display: "inline-block",
                          marginTop: "16px",
                        }}
                      >
                        ADD NEW ADDRESS
                      </button>
                    </div>
                    <Dialog
                      open={openForm}
                      onClose={() => setOpenForm(false)}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle>
                        {editingAddress ? "Edit Address" : "Add New Address"}
                      </DialogTitle>
                      <DialogContent>
                        <form onSubmit={handleAddressSubmit}>
                          <TextField
                            label="Recipient Name"
                            name="namapenerima"
                            value={formData.namapenerima}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            margin="normal"
                          />
                          <TextField
                            label="Recipient's phone number"
                            name="nomerteleponpenerima"
                            value={formData.nomerteleponpenerima}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            margin="normal"
                          />
                          <TextField
                            label="Address"
                            name="alamat"
                            value={formData.alamat}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            margin="normal"
                          />
                          <FormControl fullWidth required margin="normal">
                            <InputLabel>Province</InputLabel>
                            <Select
                              name="provinsi"
                              value={formData.provinsi}
                              onChange={handleInputChange}
                              label="Province"
                            >
                              {provinces.map((province) => (
                                <MenuItem
                                  key={province.province_id}
                                  value={province.province}
                                >
                                  {province.province}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth required margin="normal">
                            <InputLabel>City</InputLabel>
                            <Select
                              name="kotaataukelurahan"
                              value={formData.kotaataukelurahan}
                              onChange={handleInputChange}
                              label="City"
                            >
                              {cities.map((city) => (
                                <MenuItem
                                  key={city.city_id}
                                  value={city.city_name}
                                >
                                  {city.city_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Postal Code"
                            name="kodePos"
                            value={formData.kodePos}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            margin="normal"
                          />
                          <TextField
                            label="Address Number"
                            name="nomeralamat"
                            value={formData.nomeralamat}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                          />
                          <FormControl fullWidth required margin="normal">
                            <InputLabel>Main Address</InputLabel>
                            <Select
                              name="main"
                              value={formData.main}
                              onChange={handleInputChange}
                              label="Main Address"
                            >
                              <MenuItem value="Rumah">Rumah</MenuItem>
                              <MenuItem value="Kantor">Kantor</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth required margin="normal">
                            <InputLabel>Status</InputLabel>
                            <Select
                              name="status"
                              value={formData.status}
                              onChange={handleInputChange}
                              label="Status"
                            >
                              <MenuItem value="Utama">Utama</MenuItem>
                              <MenuItem value="Cadangan">Cadangan</MenuItem>
                            </Select>
                          </FormControl>
                          <DialogActions>
                            <Button
                              onClick={() => setOpenForm(false)}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button type="submit" color="primary">
                              {editingAddress ? "Update" : "Add"} Address
                            </Button>
                          </DialogActions>
                        </form>
                      </DialogContent>
                    </Dialog>
                  </div>
                ) : (
                  <div>
                    {addresses.map((address) => {
                      const isPrimary = address.status === "Utama";
                      return (
                        <div
                          key={address.id}
                          className="p-8 mb-2 bg-white relative rounded-lg shadow-md"
                        >
                          <div>
                            {/* Address Title */}
                            <div className="text-xl font-extrabold">
                              {address.main}{" "}
                              <span className="text-gray-500">
                                ({isPrimary ? "Primary" : "Other address"})
                              </span>
                            </div>

                            {/* Recipient Name */}
                            <div className="text-xl font-extrabold pb-8">
                              {address.namapenerima}
                            </div>

                            {/* Contact Information */}
                            <p className="text-lg font-medium">
                              {address.nomerteleponpenerima}
                            </p>

                            {/* Full Address */}
                            <p className="text-lg font-medium">
                              {address.alamat}, {address.kecamatan},{" "}
                              {address.kelurahan}, {address.kota},{" "}
                              {address.provinsi}, {address.kodePos}
                            </p>
                          </div>

                          {/* Checkbox for Address Selection */}
                          <div className="absolute top-2 right-2 bg-gray-100 w-14 h-14 flex items-center justify-center rounded-md">
                            <input
                              type="checkbox"
                              checked={selectedAddress?.id === address.id}
                              onChange={() => handleAddressSelect(address)}
                              className="w-6 h-6 accent-black cursor-pointer"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>

          <div style={{ marginTop: "93px", overflowY: "auto" }}>
            <div className="product-selected">
              {cart.length === 0 ? (
                <p></p>
              ) : (
                <>
                  {cart.map((item) => {
                    const product = item.Produk;
                    const quantity = item.jumlah;
                    const color = item.colorName;
                    const subtotal = parseFloat(item.subtotal) || 0;
                    const discount = parseFloat(product.diskon) || 0;

                    let finalPrice = subtotal;
                    if (discount > 0) {
                      finalPrice -= (finalPrice * discount) / 100;
                    }
                    const totalPrice = finalPrice * quantity;

                    return (
                      <div
                        key={product.id}
                        className="bg-white p-4 rounded-lg shadow-md"
                      >
                        <div className="flex flex-col md:flex-row items-center gap-4">
                          {/* Product Image */}
                          <div className="flex-shrink-0">
                            <img
                              src={product.image[0]}
                              alt={product.nama_produk}
                              className="w-36 h-28 object-cover rounded-lg"
                            />
                          </div>

                          {/* Product Details */}
                          <div className="flex-1">
                            <h2 className="text-lg font-semibold">
                              {product.nama_produk}
                            </h2>
                            <p
                              className={`text-sm cursor-pointer ${
                                hoveredProductId === product.id
                                  ? "text-blue-500"
                                  : "text-gray-500"
                              }`}
                              onMouseEnter={() =>
                                setHoveredProductId(product.id)
                              }
                              onMouseLeave={() => setHoveredProductId(null)}
                              onClick={() =>
                                handleProductSpecificationClick(product)
                              }
                            >
                              Product Specification
                            </p>
                          </div>

                          {/* Pricing & Other Details */}
                          <div className="text-right">
                            <p className="text-sm">Quantity: {quantity}</p>
                            <p className="text-sm">
                              Weight: {product.berat} Kg
                            </p>
                            <p className="text-sm">Color: {color}</p>

                            <div className="mt-2">
                              <p className="font-bold text-lg">
                                {formatRupiah(finalPrice)}
                              </p>
                              {product.diskon > 0 && (
                                <p className="text-gray-500 line-through text-sm">
                                  {formatRupiah(subtotal)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            <div
              style={{
                backgroundColor: "white",
                height: "100px",
                padding: "20px",
                marginBottom: "60px",
              }}
            >
              <h3>
                <strong>Total Weight:</strong>
              </h3>
              <p>{totalWeight} Kg</p> {/* Menampilkan total weight */}
            </div>

            {/* Shipping Methode */}
            {/* <div className="bg-white p-5 mb-14 shadow-lg rounded-lg">
              <label htmlFor="courier" className="text-lg font-semibold">
                Select Shipping Method
              </label>
              <select
                id="courier"
                value={courier}
                onChange={handleCourierChange}
                className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                <option value="jne">JNE</option>
                <option value="">Free Shipping</option>
                <option value="">Grab</option>
                <option value="">Gojek</option>
              </select>

              <div className="mt-8 max-h-[300px] overflow-y-auto">
                {loading ? (
                  <p className="text-gray-600">Loading shipping cost...</p>
                ) : shippingOptions.length > 0 ? (
                  <div className="bg-white p-5 rounded-lg shadow">
                    <h3 className="text-lg font-semibold">Shipping Options:</h3>
                    {shippingOptions.map((option, index) => (
                      <div
                        key={index}
                        className={`p-4 my-2 rounded-lg border ${
                          selectedOption === option
                            ? "border-green-500"
                            : "border-gray-300"
                        }`}
                      >
                        <h4 className="text-md font-medium">
                          {option.service} - {option.description}
                        </h4>
                        <p>
                          <strong>Cost:</strong>{" "}
                          {formatRupiah(option.cost[0].value)}
                        </p>
                        <p>
                          <strong>Estimated Delivery Time (ETD):</strong>{" "}
                          {option.cost[0].etd} days
                        </p>
                        <button
                          className="mt-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                          onClick={() => handleOptionSelect(option)}
                        >
                          Select this option
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-600">No shipping options available</p>
                )}

                {selectedOption && (
                  <div className="bg-white p-5 mt-5 rounded-lg shadow">
                    <h3 className="text-lg font-semibold">You selected:</h3>
                    <div>
                      <h4 className="text-md font-medium">
                        {selectedOption.service} - {selectedOption.description}
                      </h4>
                      <p>
                        <strong>Cost:</strong>{" "}
                        {formatRupiah(selectedOption.cost[0].value)}
                      </p>
                      <p>
                        <strong>Estimated Delivery Time (ETD):</strong>{" "}
                        {selectedOption.cost[0].etd} days
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div> */}

            {/* <div className="bg-white p-5 md:p-6 lg:p-8 rounded-lg shadow-md mb-14">
              {selectedOption && (
                <div className="flex justify-between items-center text-green-600 text-lg font-medium mb-5">
                  <span>Biaya Pengiriman :</span>
                  <strong>{formatRupiah(selectedOption.cost[0].value)}</strong>
                </div>
              )}

              <hr className="border-gray-300 my-4" />

              <div className="flex justify-between items-center">
                <span className="text-2xl md:text-3xl font-bold">Total</span>
                <span className="text-2xl md:text-3xl font-bold">
                  {formatRupiah(
                    totalPrice +
                      (selectedOption ? selectedOption.cost[0].value : 0)
                  )}
                   {formatRupiah(parseFloat(totalPrice) || 0)}
                </span>
              </div>

              <p className="text-center text-sm md:text-base text-gray-500 mt-6">
                By confirming the order, lorem ipsum dolor sit amet
              </p>
            </div> */}

            {cart.length === 0 ? (
              <p></p>
            ) : (
              <>
                {cart.map((item) => {
                  const product = item.Produk;
                  const quantity = item.jumlah;
                  const color = item.colorName;
                  const subtotal = parseFloat(item.subtotal) || 0;
                  const discount = parseFloat(product.diskon) || 0;

                  let finalPrice = subtotal;
                  if (discount > 0) {
                    finalPrice -= (finalPrice * discount) / 100;
                  }
                  const totalPrice = finalPrice * quantity;

                  return (
                    <div className="bg-white p-5 md:p-6 lg:p-8 rounded-lg shadow-md mb-14">
                    <div className="flex justify-between items-center">
                      <span className="text-2xl md:text-3xl font-bold">Total</span>
                      <span className="text-2xl md:text-3xl font-bold">
                        {formatRupiah(parseFloat(totalPrice) || 0)}
                      </span>
                    </div>
                  
                    <p className="text-center text-sm md:text-base text-gray-500 mt-6">
                      By confirming the order, lorem ipsum dolor sit amet
                    </p>
                  </div>
                  
                  );
                })}
              </>
            )}

            {/* Button Container at Bottom-Right */}
            <div className="mt-8 relative flex justify-end gap-5 md:gap-6 lg:gap-8">
              <button
                onClick={prevStep}
                disabled={currentStep === 1}
                className="w-[227px] h-14 text-lg border border-gray-800 text-gray-900 bg-white transition-all duration-300 ease-in-out 
               hover:bg-red-600 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {currentStep === 2 ? "Cancel" : "Previous"}
              </button>

              <button
                onClick={nextDelivery}
                disabled={currentStep === 4}
                className="w-[227px] h-14 text-lg text-white bg-gray-900 transition-all duration-300 ease-in-out 
               hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
              </button>
            </div>
          </div>
        </div>

        {/* Product Specification Popup */}

        {showPopup && selectedProductId && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1000",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                width: "80%",
                maxWidth: "600px",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Cari produk berdasarkan ID yang dipilih */}
              {products
                .filter((p) => p.id === selectedProductId)
                .map((product) => (
                  <React.Fragment key={product.id}>
                    {/* Gambar produk */}
                    <div
                      style={{
                        marginBottom: "20px",
                        alignSelf: "center",
                      }}
                    >
                      <img
                        src={product.image[0]}
                        alt={product.nama_produk}
                        style={{
                          width: "220px",
                          height: "240px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </div>

                    {/* Spesifikasi produk */}
                    <div
                      style={{
                        flexGrow: 1,
                        maxHeight: "300px",
                        overflowY: "auto",
                        marginBottom: "20px",
                      }}
                    >
                      <h3 style={{ marginBottom: "10px" }}>
                        Product Specifications
                      </h3>
                      <p>
                        {product.spesifikasi || "No specifications available"}
                      </p>
                    </div>
                  </React.Fragment>
                ))}

              {/* Tombol tutup */}
              <div style={{ textAlign: "center" }}>
                <button onClick={closePopup}>Close</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {isEditPopupVisible && (
          <Dialog open onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
              {isEditing ? "Edit Address" : "Add New Address"}
            </DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Recipient Name"
                  name="namapenerima"
                  value={formData.namapenerima}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Recipient's Phone Number"
                  name="nomerteleponpenerima"
                  value={formData.nomerteleponpenerima}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Address"
                  name="alamat"
                  value={formData.alamat}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  margin="normal"
                />
                <FormControl fullWidth required margin="normal">
                  <InputLabel>Province</InputLabel>
                  <Select
                    name="provinsi"
                    value={formData.provinsi}
                    onChange={handleInputChange}
                  >
                    {provinces.map((province) => (
                      <MenuItem
                        key={province.province_id}
                        value={province.province}
                      >
                        {province.province}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth required margin="normal">
                  <InputLabel>City</InputLabel>
                  <Select
                    name="kotaataukelurahan"
                    value={formData.kotaataukelurahan}
                    onChange={handleInputChange}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.city_id} value={city.city_name}>
                        {city.city_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <DialogActions>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button type="submit" variant="contained" color="primary">
                    {isEditing ? "Update" : "Add"}
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Checkout;

import React, { useState } from "react";
import Footer from "../components/Footer";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section className="py-24">
        <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-5 text-center">
            Shipping and Delivery
          </h2>
          <div className="mt-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              Shipping Policy
            </h3>
            <p className="text-base text-gray-600 mb-4">
              Orders will be sent within 2 - 3 working days for the Jabodetabek
              area and 4 - 7 working days outside Jabodetabek.
            </p>

            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
              Delivery Services Available at Musique:
            </h3>
            <ul className="list-disc list-inside text-base text-gray-600 space-y-2">
              <li>JNE</li>
              <li>Music Shop Courier</li>
            </ul>

            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
              Shipping Schedule
            </h3>
            <p className="text-base text-gray-600 mb-4">
              Monday - Friday: Regular delivery at 15:00 WIB
            </p>
            <p className="text-base text-gray-600 mb-4">
              There are no deliveries on Saturdays, Sundays, and National
              Holidays.
            </p>
            <p className="text-base text-gray-600 mb-4">
              Orders made after the order processing time limit will be
              processed the following day.
            </p>

            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
              Instant Delivery:
            </h3>
            <p className="text-base text-gray-600 mb-4">
              Instant delivery is carried out based on requests from customers
              by contacting Musique Customer Service on WhatsApp number
              (+62) 821-2121-1509.
            </p>

            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
              Instant Grab/Gojek Delivery Orders Schedule
            </h3>
            <ul className="list-disc list-inside text-base text-gray-600 space-y-2">
              <li>Monday - Friday: Instant Delivery 10:00 - 15:00 WIB</li>
              <li>
                Saturday, Sunday, and National Holidays delivery is not
                available.
              </li>
              <li>Next Day Delivery 10:00 - 15:00 WIB</li>
              <li>
                Saturday, Sunday, and National Holidays delivery is not
                available.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Accordion;

import React, { useState } from "react";
import Footer from "../components/Footer";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="flex flex-col min-h-screen shadow-lg">
      {/* Main Content */}
      <main className="flex-grow">
        <section className="py-24 mt-8">
          <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-4">
            {/* About Us Heading */}
            {/* <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-5 text-center">
              About Us
            </h2> */}

            <h2 className="text-4xl font-bold text-gray-900 leading-7 text-center max-w-6xl mx-auto mb-5">
              THE LEADING STUFF
            </h2>

            {/* About Us Description */}
            <p className="text-lg text-gray-700 leading-7 text-center max-w-6xl mx-auto mb-3">
              Musique is multi-brand company under PT V2 Indonesia.
            </p>
            {/* <p className="text-lg text-gray-700 leading-7 text-start max-w-6xl mx-auto">
              Musique is a multi-brand store selling high-end audio brands from
              around the world. Musique presents the best audio products for
              home cinema, portable audio, and custom installation to fulfill
              customer needs and complement lifestyles.
            </p> */}
            <p className="text-lg text-gray-700 leading-7 text-center max-w-6xl mx-auto mb-3">
              Created in 2019 Musique selling high end curated audio brand
              around the world. Musique presents the best audio products for
              home cinema, portable audio, and custom installation to fulfill
              customer needs and complement lifestyles.
            </p>
            <p className="text-lg text-gray-700 leading-7 text-center max-w-6xl mx-auto">
              Now, customers can enjoy and experience high quality of audio at
              home.
            </p>
          </div>
        </section>
      </main>

      {/* Footer Stays at Bottom */}
      <Footer />
    </div>
  );
};

export default Accordion;

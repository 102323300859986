import React, { useState, useEffect } from "react";
import axios from "axios";
import OrderPage from "./OrderPage"; // Adjust the path as necessary
import Navbar from "../components/Navbar"; // Adjust the path as necessary
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiClient from "../components/apiClient";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  Person,
  Room,
  ShoppingBag,
  ExitToApp,
  Visibility,
  VisibilityOff,
  CreditCard,
} from "@mui/icons-material"; // Import icons
import Footer from "../components/Footer";
import AlamatPage from "./AlamatPage";
import Wallet from "./Wallet";

const Setting = () => {
  const [profile, setProfile] = useState({
    nama: "",
    email: "",
    telepon: "",
    alamat: "",
    imageUrl: "",
  });
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // For image preview
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeItem, setActiveItem] = useState("Account Information");
  const [openEditModal, setOpenEditModal] = useState(false); // To control modal visibility

  const [openAlert, setOpenAlert] = useState(false); // For controlling the alert modal visibility
  const [acceptTerms, setAcceptTerms] = useState(false); // To track if the user accepted the terms

  const [formData, setFormData] = useState({
    nama: profile.nama,
    email: profile.email,
    telepon: profile.telepon,
  });
  const [productNames, setProductNames] = useState({});
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false); // State to toggle old password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  // Fetch profile data saat komponen di-mount
  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        window.location.href = "/login"; // Redirect to login if token is missing
        return;
      }
      try {
        const response = await apiClient.get("/user/profile", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    // Update formData whenever profile data changes
    setFormData({
      nama: profile.nama,
      email: profile.email,
      telepon: profile.telepon,
    });
  }, [profile]);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    setImagePreview(URL.createObjectURL(selectedImage)); // Preview selected image
  };

  const handleUpdateImage = async () => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("image", image);
      formData.append("nama", profile.nama);
      formData.append("telepon", profile.telepon);

      await apiClient.put("/user/update", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Display success toast
      toast.success("Profil berhasil diperbarui!", {
        position: "top-right", // Using string directly
        autoClose: 5000,
      });

      setProfile((prevProfile) => ({
        ...prevProfile,
        imageUrl: URL.createObjectURL(image),
      }));
      setImagePreview(null); // Hide the preview image after successful update

      // Reload the page to reflect changes
      window.location.reload(); // Reloads the page after update
    } catch (error) {
      console.error("Error updating image:", error);

      // Display error toast
      toast.error("Gagal memperbarui profil.", {
        position: "top-right", // Using string directly
        autoClose: 5000,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle the edit form submission
  const handleEditSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiClient.put("/user/update", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSuccessMessage("Profile updated successfully!");
      setProfile(response.data);
      setOpenEditModal(false); // Close modal after successful update
      // Reload the page after successful update
      window.location.reload();
    } catch (error) {
      console.error("Error updating profile:", error);
      setErrorMessage("Failed to update profile.");
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitPassword = async () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setErrorMessage("New password and confirmation don't match");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await apiClient.put(
        "/user/update", // Assuming this is the correct endpoint for password updates
        { password: passwordData.newPassword }, // Send the new password only
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMessage("Password updated successfully");
      setOpenPasswordModal(false); // Close the modal after successful password change
      localStorage.removeItem("token"); // Clear the token after password change
      setTimeout(() => {
        window.location.href = "/login"; // Redirect to login after password change
      }, 2000);
    } catch (error) {
      setErrorMessage("Failed to update password");
      console.error("Error updating password:", error);
    }
  };
  const handleTogglePasswordVisibility = (field) => {
    switch (field) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const isActive = (item) => activeItem === item;

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        window.location.href = "/login"; // Redirect if no token found
        return;
      }

      // Call the logout endpoint
      await apiClient.post("/user/logout", null, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Clear token from localStorage
      localStorage.removeItem("token");

      // Redirect to login page
      window.location.href = "/login";
    } catch (error) {
      console.error("Error during logout:", error);
      setErrorMessage("Logout failed. Please try again.");
    }
  };

  useEffect(() => {
    if (userId && token) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/wallet`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 404) {
            return [];
          }
          if (!response.ok) {
            throw new Error("Failed to fetch wallet data");
          }
          return response.json();
        })
        .then((data) => {
          const productNamesMap = {};
          data.forEach((item) => {
            if (item.Produk) {
              productNamesMap[item.produkId] = item.Produk.nama_produk;
            }
          });
          setProductNames(productNamesMap);
          setWalletData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setError(error.message);
          setLoading(false);
        });
    } else {
      setError("User is not authenticated");
      setLoading(false);
    }
  }, [userId, token]);
  const totalBalance = walletData.reduce(
    (total, item) => total + item.jumlah,
    0
  );

  const formatCurrency = (amount) =>
    new Intl.NumberFormat("id-ID", {
      style: "decimal",
      currency: "IDR",
    }).format(amount);

  console.log("Formatted Balance:", formatCurrency(totalBalance));

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage
      if (token) {
        // Send DELETE request to delete the account
        await apiClient.delete("/user/delete", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Clear localStorage to remove user data
        localStorage.clear();

        // Redirect to home page
        window.location.href = "/"; // Redirect to home
      } else {
        alert("No token found. Please log in again.");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      alert("There was an error deleting your account. Please try again.");
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true); // Show the alert modal
  };

  const handleCloseAlert = () => {
    setOpenAlert(false); // Close the alert modal
  };

  const handleConfirmDelete = () => {
    if (acceptTerms) {
      handleDeleteAccount(); // Proceed with account deletion if terms are accepted
    } else {
      alert("Please accept the terms before proceeding.");
    }
    setOpenAlert(false); // Close the alert modal after confirmation
  };
  return (
    <>
      <Navbar />
      <div className="flex-grow p-4">
        {/* <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4"> */}
        <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4 p-6">
          {/* Kolom Kiri - Profile Info */}
          {/* <div className="w-full md:w-1/2 bg-gray-100 p-6"> */}
          <div className="col-span-1 p-6">
            <div className="text-left pt-10">
              <h2 className="font-bold text-3xl md:text-5xl mb-6 p-6">
                {profile.nama || "Nama Pengguna"}
              </h2>
              <ul className="space-y-2">
                {/* Account Information */}
                <li
                  className={`flex items-center gap-3 p-4 rounded-lg cursor-pointer transition-all duration-200 ${
                    isActive("Account Information")
                      ? "bg-gray-800 text-white"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => setActiveItem("Account Information")}
                >
                  <Person
                    className={`text-2xl ${
                      isActive("Account Information")
                        ? "text-white"
                        : "text-black"
                    }`}
                  />
                  <span className="text-lg md:text-2xl font-medium">
                    Account Information
                  </span>
                </li>

                {/* Address */}
                <li
                  className={`flex items-center gap-3 p-4 rounded-lg cursor-pointer transition-all duration-200 ${
                    isActive("Address")
                      ? "bg-gray-800 text-white"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => setActiveItem("Address")}
                >
                  <Room
                    className={`text-2xl ${
                      isActive("Address") ? "text-white" : "text-black"
                    }`}
                  />
                  <span className="text-lg md:text-2xl font-medium">
                    Address
                  </span>
                </li>

                {/* Orders */}
                <li
                  className={`flex items-center gap-3 p-4 rounded-lg cursor-pointer transition-all duration-200 ${
                    isActive("Orders")
                      ? "bg-gray-800 text-white"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => setActiveItem("Orders")}
                >
                  <ShoppingBag
                    className={`text-2xl ${
                      isActive("Orders") ? "text-white" : "text-black"
                    }`}
                  />
                  <span className="text-lg md:text-2xl font-medium">
                    Orders
                  </span>
                </li>

                {/* Logout */}
                <li
                  className={`flex items-center gap-3 p-4 rounded-lg cursor-pointer transition-all duration-200 ${
                    isActive("Logout")
                      ? "bg-gray-800 text-white"
                      : "hover:bg-red-100 text-red-500"
                  }`}
                  onClick={handleLogout}
                >
                  <ExitToApp
                    className={`text-2xl ${
                      isActive("Logout") ? "text-white" : "text-red-500"
                    }`}
                  />
                  <span className="text-lg md:text-2xl font-medium">
                    Logout
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Kolom Kanan - Update Image */}
          {/* <div className="w-full mx-auto p-4 md:p-12 mb-24 min-h-screen"> */}
          <div className="col-span-2 p-6">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              {activeItem === "Account Information" && (
                <>
                  {/* Profile Image */}
                  {profile.imageUrl ? (
                    <img
                      src={profile.imageUrl}
                      alt="Profile Picture"
                      className="w-40 h-40 rounded-full object-cover mx-auto mb-4"
                    />
                  ) : (
                    <Avatar className="w-40 h-40 mx-auto mb-4 flex items-center justify-center">
                      <Person className="w-20 h-20" />
                    </Avatar>
                  )}

                  {/* Alerts */}
                  {successMessage && (
                    <Alert severity="success">{successMessage}</Alert>
                  )}
                  {errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}

                  {/* Image Upload */}
                  <div className="flex justify-center mt-6">
                    <Button
                      variant="contained"
                      component="label"
                      className="bg-black text-white px-6 py-3 text-lg rounded-lg shadow-md transition-all duration-300 hover:bg-gray-800 active:scale-95 w-full max-w-xs sm:max-w-sm md:max-w-md"
                    >
                      CHOOSE PHOTO
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Button>
                  </div>

                  {/* Image Preview */}
                  {imagePreview && (
                    <div className="flex justify-center mt-4">
                      <img
                        src={imagePreview}
                        alt="Selected"
                        className="w-48 h-auto"
                      />
                    </div>
                  )}

                  {/* Update Button */}
                  {image && (
                    <div className="flex justify-center mt-6">
                      <Button
                        variant="contained"
                        onClick={handleUpdateImage}
                        className="bg-gray-800 text-white w-72 h-14 hover:bg-gray-600"
                      >
                        Update
                      </Button>
                    </div>
                  )}

                  {/* Photo Info */}
                  <div className="mt-6 text-center text-gray-500 text-sm">
                    Photo size max 2 MB, supported formats (jpeg, png, jpg)
                  </div>

                  {/* User Info */}
                  <div className="mt-12 space-y-8">
                    {["Full Name", "Email", "Phone"].map((label, index) => (
                      <div key={index}>
                        <Typography className="text-2xl font-bold">
                          {label}
                        </Typography>
                        <Typography className="text-lg">
                          {profile[label.toLowerCase().replace(" ", "")]}
                        </Typography>
                      </div>
                    ))}
                  </div>

                  {/* Edit & Change Password Buttons */}
                  <div className="flex flex-col md:flex-row justify-center items-center gap-4 mt-10 px-4">
                    <Button
                      variant="contained"
                      onClick={() => setOpenEditModal(true)}
                      className="bg-black text-white px-6 py-3 text-lg rounded-lg shadow-md transition-all duration-300 hover:bg-gray-800 active:scale-95 w-full max-w-xs sm:max-w-sm md:max-w-md"
                    >
                      EDIT INFORMATION
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setOpenPasswordModal(true)}
                      className="bg-gray-100 text-black px-6 py-3 text-lg rounded-lg shadow-md transition-all duration-300 hover:bg-gray-200 active:scale-95 w-full max-w-xs sm:max-w-sm md:max-w-md"
                    >
                      CHANGE PASSWORD
                    </Button>
                  </div>

                  {/* Delete Account Section */}
                  <div className="mt-16 p-6 sm:p-8 bg-white rounded-xl shadow-lg max-w-lg mx-auto text-center">
                    <Typography className="text-2xl font-bold text-gray-900">
                      Delete Account
                    </Typography>
                    <Typography className="font-semibold mt-3 text-red-600">
                      ⚠️ Warning: Account Deletion
                    </Typography>
                    <Typography className="mt-3 text-sm text-gray-600">
                      You are attempting to delete your account. This action is{" "}
                      <strong>irreversible</strong>.
                    </Typography>
                    <ul className="list-disc list-inside mt-4 text-sm text-gray-600 text-left">
                      <li>Order and transaction history</li>
                      <li>User profile information</li>
                      <li>Personal settings</li>
                      <li>All other related data</li>
                    </ul>
                    <Button
                      variant="contained"
                      onClick={handleDeleteAccount}
                      className="bg-red-600 text-white font-semibold mt-6 w-full sm:w-64 h-14 rounded-lg shadow-md transition-all duration-300 hover:bg-red-700 active:scale-95"
                    >
                      DELETE ACCOUNT
                    </Button>
                  </div>
                </>
              )}

              {/* Orders Section */}
              {activeItem === "Orders" && (
                <div className="mt-6 p-8 bg-white rounded-lg">
                  <OrderPage />
                </div>
              )}

              {/* Address Section */}
              {activeItem === "Address" && (
                <div className="mt-6 p-8 bg-white rounded-lg shadow-lg">
                  <AlamatPage />
                </div>
              )}
            </div>
          </div>

          {/* Right Column - Content */}
        </div>
      </div>

      <Dialog open={openAlert} onClose={handleCloseAlert}>
        <DialogTitle>Account Deletion Warning</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Warning: By deleting your account, all data associated with your
            account will be lost. This includes:
            <ul>
              <li>Order and transaction history</li>
              <li>User profile information</li>
              <li>Personal settings</li>
              <li>All other related data</li>
            </ul>
            <p>
              If you agree to proceed with this irreversible action, please
              confirm by ticking the box below and clicking 'Yes'.
            </p>
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)} // Update state when checkbox is clicked
                name="acceptTerms"
              />
            }
            label="I understand and agree that all my data will be deleted, including orders and wallet balance."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary">
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            disabled={!acceptTerms}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        PaperProps={{
          sx: {
            width: "1094px", // Lebar tetap
            maxWidth: "none", // Hilangkan batasan maxWidth bawaan Material-UI
            position: "absolute",
            top: "50px",
            left: "433px",
          },
        }}
      >
        <Box
          sx={{ backgroundColor: "#F8F9FA", height: "826px", padding: "24px" }}
        >
          <DialogTitle
            sx={{
              fontSize: "48px",
              fontWeight: 900,
              display: "flex", // Gunakan flexbox
              flexDirection: "column", // Atur susunan menjadi column
              justifyContent: "center", // Posisi vertikal di tengah
              alignItems: "center", // Posisi horizontal di tengah
              width: "100%", // Lebar penuh agar centering berfungsi
              gap: "16px", // Tambahkan jarak antar tombol
              padding: "16px 0",
              marginBottom: "94px",
            }}
          >
            Edit Your Personal Information
          </DialogTitle>
          <DialogContent sx={{ paddingLeft: "177px", paddingRight: "177px" }}>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#9E9E9E",
                  marginBottom: "8px",
                }}
              >
                Full Name
              </Typography>
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "10px",
                  },
                }}
                placeholder="Enter Full Name"
                name="nama"
                value={formData.nama}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#9E9E9E",
                  marginBottom: "8px",
                }}
              >
                Email
              </Typography>
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "10px",
                  },
                }}
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#9E9E9E",
                  marginBottom: "8px",
                }}
              >
                Phone Number
              </Typography>
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "10px",
                  },
                }}
                placeholder="Enter Phone Number"
                name="telepon"
                value={formData.telepon}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                paddingLeft: "170px",
                paddingRight: "170px",
                display: "flex", // Gunakan flexbox
                flexDirection: "column", // Atur susunan menjadi column
                justifyContent: "center", // Posisi vertikal di tengah
                alignItems: "center", // Posisi horizontal di tengah
                width: "100%", // Lebar penuh agar centering berfungsi
                gap: "16px", // Tambahkan jarak antar tombol
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#1D1615",
                  height: "56px",
                  color: "white", // Warna teks
                  fontSize: "24px",
                  fontWeight: "700",
                }}
                fullWidth
                onClick={handleEditSubmit}
                color="primary"
                variant="contained"
              >
                UPDATE
              </Button>

              <Button
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  height: "56px",
                  backgroundColor: "transparent", // Warna default background

                  color: "#1D1615", // Warna teks
                  "&:hover": {
                    backgroundColor: "grey.300", // Warna abu-abu saat hover
                  },
                }}
                fullWidth
                onClick={() => setOpenEditModal(false)}
              >
                CANCEL
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>

      <Box sx={{ flexGrow: 1, p: 4, marginTop: "147px" }}>
        {/* Password Change Modal */}
        <Dialog
          open={openPasswordModal}
          onClose={() => setOpenPasswordModal(false)}
        >
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <TextField
              label="Old Password"
              type={showOldPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              name="oldPassword"
              value={passwordData.oldPassword}
              onChange={handlePasswordChange}
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleTogglePasswordVisibility("old")}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="New Password"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleTogglePasswordVisibility("new")}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={handlePasswordChange}
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleTogglePasswordVisibility("confirm")}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPasswordModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitPassword} color="primary">
              Change Password
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Footer />
    </>
  );
};

export default Setting;

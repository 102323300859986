import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home'; // Import Home
import DetailProduk from './pages/DetailProduk'; // Import DetailProduk
import DetailProdukKategori from './pages/DetailProdukKategori'; // Import DetailProduk
import Login from './components/Login'; // Import Login
import Cart from './components/Cart'; // Import Cart
import Navbar from './components/Navbar'; // Import Navbar
import OtpVerification from './components/OtpVerification';
import Register from './components/Register'; // Import Register
import Setting from './pages/Setting';
import SearchProdukPage from './pages/SearchProdukPage';
import AllProdukNewArrival from './pages/AllProdukNewArrival';
import AllBrand from './pages/AllBrand';
import DetailBrand from './pages/DetailBrand';
import OrderPage from './pages/OrderPage';
import ReviewPage from './pages/ReviewPage';
import Checkout from './pages/Checkout';
import FaqPage from './pages/FaqPage';
import PurchasPage from './pages/PurchasingOnline';
import HowTo from './pages/HowTo';
import Benefit from './pages/Benefit';
import TheOrder from './pages/TheOrder';
import PaymentOption from './pages/PaymentOption';
import ShipingDelivery from './pages/ShipingDelivery';
import ReturnChange from './pages/ReturnChange';
import AboutUs from './pages/AboutUs';
import TermCondition from './pages/TermCondition';
import OurOutlet from './pages/OurOutlet';
import Ecatalog from './pages/Ecatalog';
import ViewerPdf from './components/PdfViewer';
import SnapUrl from './pages/SnapUrl';
import Delivery from './components/DeliveryPage'
import Payment from './components/PaymentPage'


import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import './App.css'

const App = () => {
  return (
    <Router>
      <Navbar /> {/* Menambahkan Navbar ke halaman */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/produk/:id" element={<DetailProduk />} /> Halaman Detail Produk */}
        <Route path="/produk/all/:id" element={<DetailProduk />} /> {/* Halaman Detail Produk */}
        <Route path="/produk-kategori/:id" element={<DetailProdukKategori />} /> {/* Halaman Detail Produk */}
        <Route path="/login" element={<Login />} /> {/* Halaman Login */}
        <Route path="/cart" element={<ProtectedRoute element={<Cart />} />} /> {/* Proteksi Halaman Keranjang */}
        {/* <Route path="/cart" element={<Cart />} /> */}
        <Route path="/review-produk" element={<ProtectedRoute element={<ReviewPage />} />} /> {/* Proteksi Halaman Keranjang */}
        <Route path="/register" element={<Register />} /> {/* Halaman Register */}
        <Route path="/verify-otp" element={<ProtectedRoute element={<OtpVerification />}/>} /> {/* Halaman Register */}
        <Route path="/search-produk" element={<SearchProdukPage/>} />
        <Route path="/all-produk-new" element={<AllProdukNewArrival/>} />
        <Route path="/all-brand" element={<AllBrand/>} />
        <Route path="/detail-brand/:id" element={<DetailBrand/>} />
        <Route path="/settings" element={<ProtectedRoute element={<Setting />}/>} />
        <Route path="/checkout" element={<ProtectedRoute element={<Checkout />}/>} />
        <Route path="/order-history" element={<ProtectedRoute element={<OrderPage />}/>} />
        <Route path="/faq-page" element={<FaqPage />} />
        <Route path="/privacy-page" element={<PurchasPage />} />
        <Route path="/howto-page" element={<HowTo />} />
        <Route path="/benefit-page" element={<Benefit />} />
        <Route path="/theorder-page" element={<TheOrder />} />
        <Route path="/paymentoption-page" element={<PaymentOption />} />
        <Route path="/shiping-page" element={<ShipingDelivery />} />
        <Route path="/return-page" element={<ReturnChange />} />
        <Route path="/aboutus-page" element={<AboutUs />} />
        <Route path="/term-page" element={<TermCondition />} />
        <Route path="/our-outlet" element={<OurOutlet></OurOutlet>} />
        <Route path="/e-catalog" element={<Ecatalog></Ecatalog>} />
        <Route path="/viewer-pdf" element={<ViewerPdf></ViewerPdf>} />
        <Route path="/snap-url" element={<SnapUrl></SnapUrl>} />
        <Route path="/delivery-page" element={<Delivery></Delivery>} />
        <Route path="/payment-page" element={<Payment></Payment>} />


 
      </Routes>

    </Router>
  );
};

export default App;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import { toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import the toastify CSS
import apiClient from "../components/apiClient";

const Register = () => {
  const [nama, setNama] = useState('');
  const [email, setEmail] = useState('');
  const [telepon, setTelepon] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    // Validate password
    if (password !== confirmPassword) {
      setErrorMessage('Password dan konfirmasi password tidak cocok.');
      return;
    }

    // Validate phone number
    const isValidPhoneNumber = /^[0-9]{10,15}$/.test(telepon); // Format: 10-15 digits
    if (!isValidPhoneNumber) {
      setErrorMessage('Nomor telepon tidak valid. Masukkan 10-15 angka.');
      return;
    }

    try {
      const response = await apiClient.post("/user/register", {
        nama,
        email,
        password,
        telepon,
      });

      if (response.data && response.data.message === 'User created successfully') {
        toast.success('Registrasi berhasil! Silakan login.', { autoClose: 2000 }); // Show Toastify success
        setTimeout(() => {
          navigate('/login'); // Redirect after toast
        }, 2000);
      } else {
        setErrorMessage(response.data.message || 'Registrasi gagal. Coba lagi.');
      }
    } catch (error) {
      console.error('Error register:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage('Terjadi kesalahan: ' + error.response.data.message);
      } else if (error.request) {
        setErrorMessage('Tidak ada respons dari server. Coba lagi.');
      } else {
        setErrorMessage('Terjadi kesalahan saat registrasi.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '27px',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          You do not have a Musique account
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '17px',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          This email address is not yet associated with an account. Create an account if you are a new customer.
        </Typography>

        <TextField
          label="Nama"
          type="text"
          fullWidth
          value={nama}
          onChange={(e) => setNama(e.target.value)}
          sx={{
            mb: 2,
            height: '56px',
            width: '700px',
            backgroundColor: '#D9D9D9',
            '& .MuiInputLabel-root': { fontFamily: 'Inter', fontWeight: 400, fontSize: '16px' },
            '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#BDBDBD' }, '&:hover fieldset': { borderColor: '#1F1F1F' } },
          }}
          required
        />

        <TextField
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            mb: 2,
            height: '56px',
            width: '700px',
            backgroundColor: '#D9D9D9',
            '& .MuiInputLabel-root': { fontFamily: 'Inter', fontWeight: 400, fontSize: '16px' },
            '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#BDBDBD' }, '&:hover fieldset': { borderColor: '#1F1F1F' } },
          }}
          required
        />

        <TextField
          label="Telepon"
          type="text"
          fullWidth
          value={telepon}
          onChange={(e) => setTelepon(e.target.value)}
          sx={{
            mb: 2,
            height: '56px',
            width: '700px',
            backgroundColor: '#D9D9D9',
            '& .MuiInputLabel-root': { fontFamily: 'Inter', fontWeight: 400, fontSize: '16px' },
            '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#BDBDBD' }, '&:hover fieldset': { borderColor: '#1F1F1F' } },
          }}
          required
        />

        <TextField
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            mb: 2,
            height: '56px',
            width: '700px',
            backgroundColor: '#D9D9D9',
            '& .MuiInputLabel-root': { fontFamily: 'Inter', fontWeight: 400, fontSize: '16px' },
            '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#BDBDBD' }, '&:hover fieldset': { borderColor: '#1F1F1F' } },
          }}
          required
        />

        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{
            mb: 2,
            height: '56px',
            width: '700px',
            backgroundColor: '#D9D9D9',
            '& .MuiInputLabel-root': { fontFamily: 'Inter', fontWeight: 400, fontSize: '16px' },
            '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#BDBDBD' }, '&:hover fieldset': { borderColor: '#1F1F1F' } },
          }}
          required
        />

        <Button
          onClick={handleRegister}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            height: '56px',
            width: '700px',
            backgroundColor: '#1F1F1F',
            color: 'white',
            mb: 6,
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '24px',
          }}
        >
          REGISTER
        </Button>

        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Box>
    </Container>
  );
};

export default Register;

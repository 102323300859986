import React, { useState } from "react";
import Footer from "../components/Footer";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionItems = [
    {
      id: 1,
      ask: "Can I change the shipping address for my order?",
      title: "",
      content:
        "You can change your shipping address if you haven’t made any payment. If you have already paid, then address changes cannot be made.",
    },
    {
      id: 2,
      ask: "Why can't my order delivery receipt number be tracked?",
      title:
        "The following things may happen if your order receipt number cannot be tracked:",
      content:
        "The logistics agent has not updated the receipt data in the system. \nYou entered the wrong receipt when trying to track the delivery status.",
    },
    {
      id: 3,
      ask: "Can I cancel my order?",
      title: "",
      content:
        "You can cancel an order a maximum of 1x24 hours after the order is made.",
    },
    {
      id: 4,
      ask: "Can I change the shipping address for my order?",
      title: "",
      content:
        "Once the order has been placed, you cannot change the shipping address. We recommend you to ensure all details are correct before processing your order. You can cancel the order and reorder with the correct address.",
    },
    {
      id: 5,
      ask: "Delay in delivery",
      title: "",
      content:
        "The delivery process for your order may experience delays during national holidays.",
    },
    {
      id: 6,
      ask: "What should I do if I receive a defective or damaged product?",
      title: "",
      content:
        "If you receive your order damaged or defective, please visit the “Returns & Exchanges Information” page.",
    },
  ];

  return (
    <>
      <section className="py-24">
        <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full">
            {/* Image Section */}
            <div className="w-full lg:w-1/2">
              {/* <img
                src="https://pagedone.io/asset/uploads/1696230182.png"
                alt="FAQ tailwind section"
                className="w-full rounded-xl object-cover"
              /> */}
              <h5 className="font-normal text-xl">Need Support</h5>
              <h5 className="font-bold text-xl">(+62) 821-2121-1509</h5>
            </div>

            {/* FAQ Section */}
            <div className="w-full lg:w-1/1">
              <div className="lg:max-w-xl">
                <div className="mb-6 lg:mb-16">
                  {/* <h6 className="text-lg text-center font-medium text-indigo-600 mb-2 lg:text-left">
                    faqs
                  </h6> */}
                  <h2 className="text-4xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left">
                    FAQs
                  </h2>
                </div>

                {/* Accordion Group */}
                <div className="accordion-group">
                  <div>
                    {accordionItems.map((item, index) => (
                      <div
                        key={item.id}
                        className={`accordion py-8 border-b border-solid border-gray-200 ${
                          activeIndex === index ? "active" : ""
                        }`}
                      >
                        <button
                          className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 bg-transparent border-b border-black p-0 hover:bg-transparent"
                          onClick={() => toggleAccordion(index)}
                          aria-controls={`accordion-content-${item.id}`}
                          aria-expanded={activeIndex === index}
                        >
                          <h5 className="pb-2.5 text-left">{item.ask}</h5>
                          <svg
                            className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 ${
                              activeIndex === index ? "rotate-180" : ""
                            }`}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                              stroke="currentColor"
                              strokeWidth="1.6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <div
                          id={`accordion-content-${item.id}`}
                          className={`accordion-content w-full px-0 overflow-hidden pr-4 transition-max-height duration-500 ease-in-out ${
                            activeIndex === index ? "max-h-screen" : "max-h-0"
                          }`}
                          style={{
                            maxHeight: activeIndex === index ? "300px" : "0",
                          }}
                        >
                          <h2 className="font-normal text-xl mb-4">
                            {item.title}
                          </h2>
                          <p className="text-base font-normal text-gray-600">
                            {item.content.split("\n").map((line, index) => (
                              <span key={index}>
                                {line}
                                {index <
                                  item.content.split("\n").length - 1 && <br />}
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Accordion;

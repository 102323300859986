import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { TextField, Button, Box, Typography, Container } from "@mui/material";
import apiClient from "../components/apiClient";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Helper function untuk mengecek apakah token sudah kedaluwarsa
  const isTokenExpired = (token) => {
    if (!token) return true;

    try {
      const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
      const currentTime = Math.floor(Date.now() / 1000); // Waktu sekarang dalam detik
      return payload.exp < currentTime; // True jika token sudah kedaluwarsa
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };

  // Hapus userId jika token kedaluwarsa
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (isTokenExpired(token)) {
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
    }
  }, []);

  // const handleLogin = async () => {
  //   try {
  //     const response = await axios.post(
  //       "http://192.168.1.3:2000/user/login",
  //       {
  //         email: email,
  //         password: password,
  //       }
  //     );

  //     if (response.data.message === "Email tidak ditemukan") {
  //       navigate("/register");
  //     } else if (response.data.message === "OTP telah dikirim ke email Anda") {
  //       navigate("/verify-otp", { state: { email } });
  //     } else if (response.data.token) {
  //       // Simpan token dan userId ke localStorage
  //       localStorage.setItem("token", response.data.token);
  //       if (response.data.userId) {
  //         localStorage.setItem("userId", response.data.userId);
  //         // localStorage.setItem("userId", userId);
  //         console.log(localStorage.getItem("userId"));

  //       }

  //       // Kirim OTP dan navigasi ke halaman verifikasi
  //       await axios.post("http://192.168.1.3:2000/user/send-otp", { email });
  //       navigate("/verify-otp", { state: { email } });
  //     } else {
  //       setErrorMessage("Login gagal, token tidak ditemukan.");
  //     }
  //   } catch (error) {
  //     console.error("Error login:", error);
  //     setErrorMessage("Email atau password salah.");
  //   }
  // };

// localStorage.clear();
  const handleLogin = async () => {
    try {
      const response = await apiClient.post("/user/login", 
        { 
          email: email, 
          password: password,
         }
        );
      if (response.data.message === "Email tidak ditemukan") {
        navigate("/register");
      } else if (response.data.message === "OTP telah dikirim ke email Anda") {
        navigate("/verify-otp", { state: { email } });
      } else if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        if (response.data.userId) {
          localStorage.setItem("userId", response.data.userId);
        }

        await apiClient.post("/user/send-otp", { email });
        navigate("/verify-otp", { state: { email } });
      } else {
        setErrorMessage("Login gagal, token tidak ditemukan.");
      }
    } catch (error) {
      console.error("Error login:", error);
      setErrorMessage("Email atau password salah.");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter",
            fontWeight: 900,
            fontSize: "57px",
            lineHeight: "68.98px",
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          Sign In
        </Typography>

        <TextField
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            mb: 5,
            height: "56px",
            width: "700px",
            backgroundColor: "#D9D9D9",
            "& .MuiInputLabel-root": {
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "16px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#BDBDBD" },
              "&:hover fieldset": { borderColor: "#1F1F1F" },
            },
          }}
          required
        />

        <TextField
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            mb: 2,
            height: "56px",
            width: "700px",
            backgroundColor: "#D9D9D9",
            "& .MuiInputLabel-root": {
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "16px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#BDBDBD" },
              "&:hover fieldset": { borderColor: "#1F1F1F" },
            },
          }}
          required
        />

        <Button
          onClick={handleLogin}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            height: "56px",
            width: "700px",
            backgroundColor: "#1F1F1F",
            color: "white",
            mb: 6,
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: "24px",
          }}
        >
          CONTINUE
        </Button>

        {errorMessage && <Typography color="error">{errorMessage}</Typography>}

        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Typography variant="body2">
            New to Musique?{" "}
            <Link
              to="/register"
              style={{ color: "#CCAB5C", textDecoration: "none" }}
            >
              Sign Up now
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import "./BrandSwiper.css"; // Import file CSS
import apiClient from "../components/apiClient";

function BrandsSwiper() {
  const [brands, setBrands] = useState([]);

  // const responsive = {
  //   superLargeDesktop: {
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 7,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // Ambil data brand dari API
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await apiClient.get("/brand");
        console.log("API Response:", response.data);
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };
    fetchBrands();
  }, []);

  return (
    <>
      <section className="p-6 w-full text-center mt-6">
        <h1 className="text-4xl font-bold text-black">Our Brand</h1>
        <hr className="w-20 border-t-2 border-black mx-auto mt-2" />{" "}
        {/* Short line under the heading */}
      </section>
      <div className="carousel-container">
        <Carousel
          arrows={true}
          infinite={true} // Allows looping
          autoPlay={true} // Auto slides
          autoPlaySpeed={2000} // Speed of auto slide
          keyBoardControl={true}
          containerClass="carousel-wrapper"
          itemClass="carousel-item"
          responsive={responsive}
        >
          {/* {brands.slice(0, 5).map( */}
          {brands.map(
            (brand) => (
              //  {brands.map((brand) => (
              <div key={brand.id}>
                <img
                  src={brand.image}
                  alt={brand.nama_brand}
                  className="carousel-image" // Menambahkan kelas CSS
                />
              </div>
            )
            // }
          )}
        </Carousel>
      </div>
    </>
  );
}

export default BrandsSwiper;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ProdukSwipeCard.css";
import apiClient from "../components/apiClient";
import { Box, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const formatRupiah = (angka) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka);
};

const formatCashback = (angka) => {
  const num = parseInt(angka, 10);

  if (num >= 1_000_000_000_000) {
    return `${Math.floor(num / 1_000_000_000_000)} triliun`;
  } else if (num >= 1_000_000_000) {
    return `${Math.floor(num / 1_000_000_000)} miliar`;
  } else if (num >= 1_000_000) {
    return `${Math.floor(num / 1_000_000)}jt`;
  } else if (num >= 1_000) {
    return `${Math.floor(num / 1_000)}rb`;
  } else {
    return `Rp${num.toLocaleString("id-ID")}`;
  }
};

const ProdukSwipeCard = () => {
  const [produkList, setProdukList] = useState([]);
  const [cashbackList, setCashbackList] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL || "http://192.168.1.3:2000";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [produkResponse, cashbackResponse] = await Promise.all([
          // axios.get(`${baseURL}/produk`),
          // axios.get(`${baseURL}/produk/limit?limit=6`),
          // axios.get(`${baseURL}/cashback`),
          // apiClient.get(`/produk/limit?limit=6`),
          apiClient.get(`/produk/all?limit=4`),
          // apiClient.get("/produk/all", {
          //   params: { limit: 4 }, // Pass limit as query parameters
          // }),
          apiClient.get(`/cashback`),
        ]);

        setProdukList(produkResponse.data);

        // Convert cashback list to a dictionary for fast lookups
        const cashbackMap = {};
        cashbackResponse.data.forEach((cb) => {
          cashbackMap[cb.produkId] = cb;
        });
        setCashbackList(cashbackMap);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseURL]);

  const handleCardClick = (id) => {
    navigate(`/produk/all/${id}`);
  };

  const handleSeeAllClick = () => {
    navigate("/all-produk-new");
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <section className="produk-swipe-container w-full py-8">
      {/* Header Section */}
      <section className="pl-4 w-full text-center">
        <h1 className="text-4xl font-bold text-white">New Arrivals</h1>
      </section>

      {/* Product Cards Section */}
      {/* <section className="produk-card-container grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-0 px-0"> */}
      <section className="bg-[#1D1615] py-14 md:px-10 relative w-full">
        {/* Centering Content */}
        <div className="flex justify-center">
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-8 items-center mx-auto w-full px-8">
            {produkList.slice(2, 6).map((produk) => {
              const cashbackData = cashbackList[produk.id];
              const imageUrl =
                produk.image.length > 0
                  ? produk.image[0]
                  : "/default-image.jpg"; // Use first image, fallback if empty

              return (
                <div
                  key={produk.id}
                  className="bg-white border border-gray-300 rounded-lg shadow-lg p-6 h-[300px] sm:h-[400px] flex flex-col items-center justify-between transition-transform duration-300 hover:scale-105"
                  onClick={() => handleCardClick(produk.id)}
                >
                  {/* Product Image */}
                  <img
                    src={imageUrl}
                    alt={produk.nama_produk || "Product image"} // Added fallback alt text
                    className="w-full h-48 object-contain rounded-md"
                    // className="w-full h-40 sm:h-20 md:h-28 object-contain rounded-md aspect-[4/5]"
                  />

                  {/* Product Details */}
                  <div className="p-4 text-start w-full">
                    <h4 className="text-lg font-semibold text-gray-800 break-words line-clamp-2 h-[48px]">
                      {produk.nama_produk}
                    </h4>
                    <div className="text-gray-600 text-sm">
                      {formatRupiah(produk.harga[0]).replace("Rp", "Rp ")}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* "See All" Button - Takes Minimal Space */}
      <div className="mx-auto flex justify-center items-center w-fit mt-4">
        <button
          onClick={handleSeeAllClick}
          style={{ backgroundColor: "#CCAB5C" }}
          className="bg-yellow-500 text-black h-auto p-1 px-3 hover:bg-yellow-600 transition duration-300 text-s w-32"
        >
          See All
        </button>
      </div>

      {/* See All Button */}
      {/* <div className="text-center mt-8">
        <button
          onClick={handleSeeAllClick}
          className="see-all-button bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition-colors duration-300"
        >
          See All
        </button>
      </div> */}
    </section>
  );
};

export default ProdukSwipeCard;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import apiClient from "../components/apiClient";

const OrderPage = () => {
  const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);

  // Ambil data pesanan saat komponen dimuat
  const userid = localStorage.getItem("userId");
  console.log(userid);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!userid) return;
      try {
        const token = localStorage.getItem("token");
        const response = await apiClient.get(`/order/user/${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, []);

  // Get order ID from localStorage
  const orderId = localStorage.getItem("orderId");

  // Fetch order items based on orderId
  useEffect(() => {
    const fetchOrderItems = async () => {
      if (!orderId) return;
      try {
        const token = localStorage.getItem("token");
        const response = await apiClient.get(`/order/items/${orderId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrderItems(response.data);
      } catch (error) {
        console.error("Error fetching order items:", error);
      }
    };
    fetchOrderItems();
  }, [orderId]);

  return (
    <>
      <h1 className="text-3xl font-bold mb-6">Orders</h1>
      <div className="p-8 bg-white rounded-2xl shadow-md pt-0">
        <h1 className="text-3xl font-bold mb-6"></h1>
        {orders.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    No.
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Product
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Warna
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Jumlah
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Virtual Account
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {[...orders].reverse().map((order, index) => (
                  <tr key={order.id} className="hover:bg-gray-50 transition">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {index + 1}
                    </td>
                    <td className="px-2 py-2 text-sm font-medium text-gray-900 truncate max-w-[100px] cursor-pointer hover:max-w-none hover:whitespace-normal">
                      {order.id}
                    </td>
                    <td className="px-2 py-2 text-sm font-medium text-gray-900 truncate max-w-[100px] cursor-pointer hover:max-w-none hover:whitespace-normal">
                      <img
                        src={order.image}
                        alt="Product Image"
                        className="w-16 h-16 object-cover"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {/* {orderItems.find((item) => item.orderId === order.id)
                        ?.nama_produk || "No Product"} */}
                      {order.nama_produk}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {order.nama_warna}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {order.jumlah}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {order.order.status}
                    </td>
                    <td className="px-2 py-2 text-sm font-medium text-gray-900 truncate max-w-[100px] cursor-pointer hover:max-w-none hover:whitespace-normal">
                      <a
                        href={order.order.vaurl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        {order.order.vaurl}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-gray-500">No orders found.</p>
        )}
      </div>
    </>
  );
};

export default OrderPage;

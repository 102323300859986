import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify"; // Make sure to import Toastify
import "react-toastify/dist/ReactToastify.css"; // Ensure the CSS for Toastify is imported
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "./Footer";
import Fitur from "./Fitur";
import DeleteIcon from "../assets/delete.png";
import apiClient from "../components/apiClient";
import ClickPickup from "../assets/Click & Pickup crop.png";
import FreeShipping from "../assets/Free Shipping crop.png";
import SameDayDelivery from "../assets/Some Day Delivery crop.png";

const Cart = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const ProductModal = ({ product, onClose }) => {
    if (!product) return null;

    console.log(product); // Log to check product structure

    // Destructure product to avoid accessing deep properties repeatedly
    const { Produk } = product;
    const { nama_produk, image, deskripsi, spesifikasi, harga } = Produk;

    return (
      <div style={modalStyles.overlay}>
        <div style={modalStyles.modal}>
          <button style={modalStyles.closeButton} onClick={onClose}>
            X
          </button>
          <h2>{nama_produk}</h2>
          <img src={image[0]} alt={nama_produk} style={{ width: "100%" }} />

          {/* Check if description is present */}
          {deskripsi && (
            <p style={{ marginBottom: "10px", marginTop: "20px" }}>
              <strong>Description:</strong> {deskripsi}
            </p>
          )}
          {spesifikasi && (
            <p style={{ marginBottom: "10px" }}>
              <strong>Spesification:</strong> {spesifikasi}
            </p>
          )}

          {/* Display price */}
          <div
            style={{
              marginBottom: "10px",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            <strong>Price:</strong> {formatRupiah(harga)}
          </div>
        </div>
      </div>
    );
  };

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modal: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "8px",
      maxWidth: "600px",
      width: "100%",
      position: "relative", // Ensure close button positions correctly
      maxHeight: "80vh", // Limit modal height
      overflowY: "auto", // Allow scrolling in the content area if it overflows
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      border: "none",
      color: "black",
      backgroundColor: "transparent",
      fontSize: "20px",
      cursor: "pointer",
      zIndex: 2000, // Ensure button stays above other elements
    },
  };

  const { id } = useParams(); // Get the id from URL params
  const [product, setProduct] = useState(null); // Define the state and setter here

  // Fungsi untuk memetakan hex ke nama warna (simple approach)
  const hexToName = (hex) => {
    const colors = {
      "#ffffff": "White",
      "#0c0c0c": "Black Dove",
      "#e0bfb8": "Rose Gold",
      "#070707": "Matte Black",
      "#fefefe": "Light Chrome",
      "#f0f0f0": "Light Gray",
      "#808080": "Gray",
      "#000000": "Black",
      "#ff0000": "Red",
      "#00ff00": "Green",
      "#0000ff": "Blue",
      "#800080": "Purple",
      "#ffff00": "Yellow",
      "#ffa500": "Orange",
      "#800000": "Maroon",
      "#808000": "Olive",
      "#008000": "Dark Green",
      "#000080": "Navy Blue",
      "#ff00ff": "Magenta",
      "#c0c0c0": "Silver",
      "#a52a2a": "Brown",
      "#d3d3d3": "Light Gray",
      "#f5f5f5": "White Smoke",
      "#c0d6e4": "Light Steel Blue",
      "#ff6347": "Tomato",
      "#2e8b57": "Sea Green",
      "#3cb371": "Medium Sea Green",
      "#ff1493": "Deep Pink",
      "#00bfff": "Deep Sky Blue",
      "#00ced1": "Dark Turquoise",
      "#8a2be2": "Blue Violet",
      "#7fff00": "Chartreuse",
      "#adff2f": "Green Yellow",
      "#ffdead": "Navajo White",
      "#dda0dd": "Plum",
      "#ff4500": "Orange Red",
      "#2f4f4f": "Dark Slate Gray",
      "#bdb76b": "Dark Khaki",
      "#9acd32": "Yellow Green",
      "#8b4513": "Saddle Brown",
      "#ffb6c1": "Light Pink",
      "#cd5c5c": "Indian Red",
      "#b0e0e6": "Powder Blue",
      "#f0e68c": "Khaki",
      "#e0ffff": "Light Cyan",
    };

    return colors[hex.toLowerCase()] || hex;
  };
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);

  const handleImageClick = (id) => {
    // Set brandId in localStorage
    localStorage.setItem("brandId", id);
    // Navigate to the detail page
    navigate(`/detail-brand/${id}`);
  };

  const handleCardClick = (id) => {
    navigate(`/produk/all/${id}`); // Redirect ke URL produk
  };

  // Fetch data dari API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Check the received data
        setBrands(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await apiClient.get(`/produk/all/${id}`);
        if (response.status === 200) {
          setProduct(response.data);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await apiClient.get("/produk/all");
        if (response.status === 200) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const [cart, setCart] = useState([]); // Cart items state
  const [selectedItems, setSelectedItems] = useState([]); // Selected items for checkout
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCartData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        // Jika token tidak ada, bersihkan data dan arahkan ke login
        localStorage.removeItem("selectedCartItems");
        localStorage.removeItem("selectedItems");
        setSelectedItems([]);
        navigate("/login");
        return;
      }

      try {
        const cartResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/cart`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (cartResponse.status === 401) {
          // Jika token tidak valid, bersihkan data dan arahkan ke login
          localStorage.removeItem("selectedCartItems");
          localStorage.removeItem("selectedItems");
          setSelectedItems([]);
          toast.error("Session expired. Please log in again.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          navigate("/login");
          return;
        }

        const cartData = await cartResponse.json();
        if (cartData.cartItems) {
          setCart(cartData.cartItems);
        } else {
          setCart([]); // Pastikan array kosong jika data tidak ditemukan
        }
      } catch (error) {
        console.error("Failed to fetch cart data", error);
        setCart([]); // Pastikan array kosong jika terjadi error
      }
    };

    fetchCartData();
  }, [navigate]);

  const handleRemoveItem = async (productId) => {
    try {
      // Kirim permintaan ke server untuk menghapus item
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cart/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Pastikan token disertakan
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      // Hapus item dari localStorage
      const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedStoredCart = storedCart.filter(
        (item) => item.id !== productId
      );
      localStorage.setItem("cart", JSON.stringify(updatedStoredCart));

      // Hapus item dari state lokal
      const updatedCart = cart.filter((item) => item.id !== productId);
      setCart(updatedCart);
      setSelectedItems((prev) => prev.filter((id) => id !== productId));

      // Tampilkan notifikasi Toastify jika berhasil
      toast.success("Item berhasil dihapus!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Reload halaman
      setTimeout(() => {
        // window.location.reload();
      }, 1000); // Beri jeda sedikit sebelum reload
    } catch (error) {
      console.error("Error deleting item:", error);

      // Tampilkan notifikasi Toastify jika gagal
      toast.error("Gagal menghapus item dari keranjang.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleChangeQuantity = async (cartItemId, quantity) => {
    try {
      if (quantity <= 0) {
        return;
      }

      const updatedCart = cart.map((item) =>
        item.id === cartItemId ? { ...item, jumlah: Number(quantity) } : item
      );
      setCart(updatedCart);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/cart/${cartItemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            jumlah: Number(quantity),
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to update cart item");
        setCart(cart);
        // } else {
        //   window.location.reload();
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      setCart(cart);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(cart.map((item) => item.id)); // Select all items
    } else {
      setSelectedItems([]); // Deselect all items
    }
  };

  const handleSelectItem = (productId) => {
    setSelectedItems((prev) => {
      const updatedSelectedItems = prev.includes(productId)
        ? prev.filter((id) => id !== productId) // Deselect item
        : [...prev, productId]; // Select item

      // Save the updated selected items to localStorage
      localStorage.setItem(
        "selectedItems",
        JSON.stringify(updatedSelectedItems)
      );

      return updatedSelectedItems;
    });
  };

  console.log(localStorage.getItem("userId"));

  const handleCheckout = async () => {
    if (selectedItems.length === 0) {
      toast(
        "Please select at least one item or select all items before proceeding to checkout.",
        {
          type: "warning",
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      return;
    }

    // Save the selected cart items to localStorage
    const selectedCartItems = cart.filter((item) =>
      selectedItems.includes(item.id)
    );
    localStorage.setItem(
      "selectedCartItems",
      JSON.stringify(selectedCartItems)
    );

    // Check if the user is authenticated
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    // Retrieve userId from localStorage
    const userId = localStorage.getItem("userId");
    console.log("User ID:", userId);

    if (!userId) {
      toast("User ID not found, please log in again.", {
        type: "error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    // Logging selectedItems and userId before sending to the backend
    console.log("Selected Items:", selectedItems);
    console.log("User ID:", userId);

    // If you need to send only one item at a time, this could be the adjustment:
    const payload = {
      userId: userId,
      cartId: selectedItems[0], // Sending only the first selected item
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/selected`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send selected items to the server");
      }

      // If the request is successful, navigate to the checkout page
      navigate("/checkout");
    } catch (error) {
      console.error("Error during checkout:", error);
      toast("Failed to proceed with checkout. Please try again later.", {
        type: "error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const cartItems = cart || [];
  const selectedCart = cartItems.filter((item) =>
    selectedItems.includes(item.id)
  );

  // Format the currency using Indonesia's convention
  const formatRupiah = (angka) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(angka);
  };

  // Calculate the subtotal after applying the discounts
  // Menghitung subtotal setelah diskon
  const subtotalAfterDiscount = selectedCart.reduce((total, item) => {
    const price = parseFloat(item.subtotal) || 0;
    const discountPercentage = parseFloat(item.Produk.diskon) || 0; // Diskon dalam persentase
    const quantity = parseInt(item.jumlah, 10) || 0;

    // Hitung harga setelah diskon (jika ada)
    const priceAfterDiscount =
      discountPercentage > 0
        ? price - (price * discountPercentage) / 100
        : price;

    // Tambahkan ke total
    return total + priceAfterDiscount * quantity;
  }, 0);

  const calculateTotal = (selectedCart) => {
    return selectedCart.reduce((total, item) => {
      const product = item.Produk; // Assuming Produk is the product object

      if (product && product.harga && product.diskon !== undefined) {
        const price = parseFloat(product.harga) || 0;
        const discountPercentage = parseFloat(product.diskon) || 0;
        const quantity = parseInt(item.jumlah, 10) || 0;

        // Apply the discount if available
        const priceAfterDiscount =
          discountPercentage > 0
            ? price - (price * discountPercentage) / 100
            : price;

        // Add the price of this product (after discount) * quantity to the total
        return total + priceAfterDiscount * quantity;
      }

      return total;
    }, 0);
  };

  const totalPrice = calculateTotal(selectedCart); // Calculate the total

  const subtotal = selectedCart.reduce((total, item) => {
    // const price = parseFloat(item.harga) || 0;
    const price = localStorage.getItem("selectedPrice") || 0;
    console.log(price);

    const quantity = parseInt(item.jumlah, 10) || 0;
    return total + price * quantity;
  }, 0);

  // Hitung total akumulasi diskon dalam persentase
  const totalDiscount = selectedCart.reduce((total, item) => {
    const discount = parseFloat(item.Produk.diskon) || 0; // Diskon dalam persentase
    return total + discount;
  }, 0);

  const color = selectedCart.reduce((accumulator, item) => {
    // Check if colorName exists and add it to the accumulator
    if (item.colorName) {
      accumulator.push(item.colorName);
    }
    return accumulator; // Return the updated accumulator
  }, []);

  // Total setelah diskon
  const totalAfterDiscount = subtotalAfterDiscount;

  // Determine if all items are selected
  const isSelectAllChecked = selectedItems.length === cart.length;

  const [hoveredProductId, setHoveredProductId] = useState(null);

  const openModal = (productId) => {
    apiClient
      .get(`/produk/${productId}`)
      .then((response) => {
        setProduct(response.data);
        setIsModalOpen(true); // Open modal
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setProduct(null); // Reset the product state
  };

  const handleDetailClick = (item) => {
    setSelectedProduct(item);
    setIsModalOpen(true);
  };

  const totalWeight = cart
    .filter((item) => selectedItems.includes(item.id)) // Hanya produk yang dipilih
    // .reduce((acc, item) => acc + item.Produk.berat * item.jumlah, 0); // Berat * Jumlah
    .reduce((acc, item) => {
      console.log(item.jumlah); // Log item.jumlah during reduction
      return acc + item.Produk.berat * item.jumlah; // Calculate total weight
    }, 0);

  return (
    <div>
      <Navbar />
      <ToastContainer />
      <div className="mt-28">
        <h1 className="text-center text-5xl font-extrabold">
          CART <span className="text-gray-500">({cart.length})</span>
        </h1>
        <div className="text-left my-2 p-8">
          <label className="relative inline-block">
            <input
              type="checkbox"
              checked={isSelectAllChecked}
              onChange={handleSelectAll}
              className={`w-10 h-10 border-2 ${
                isSelectAllChecked ? "bg-black" : "bg-white"
              } appearance-none cursor-pointer`}
            />
            {isSelectAllChecked && (
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-lg font-bold">
                ✔
              </span>
            )}
          </label>
          <label className="pl-2 text-2xl font-medium">Select All</label>
        </div>

        {cart.length === 0 ? (
          <p className="text-center">Keranjang Anda kosong!</p>
        ) : (
          <div className="flex flex-col lg:flex-row gap-5 p-5">
            <div className="flex-1 space-y-5">
              {cart.map((item, index) => {
                let images = item.Produk.image || [];
                try {
                  if (typeof images === "string") images = JSON.parse(images);
                } catch (e) {
                  console.error("Error parsing images:", e);
                  images = [];
                }
                const firstImage =
                  images.length > 0 ? images[0] : "/placeholder.png";

                return (
                  <div
                    key={item.id}
                    className="flex flex-col md:flex-row items-center bg-white shadow-lg rounded-lg p-4"
                  >
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleSelectItem(item.id)}
                        className={`absolute top-2 left-2 w-8 h-8 border-2 ${
                          selectedItems.includes(item.id)
                            ? "bg-black"
                            : "bg-white"
                        } appearance-none cursor-pointer`}
                      />
                      {selectedItems.includes(item.id) && (
                        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold">
                          ✔
                        </span>
                      )}
                    </div>
                    <img
                      src={firstImage}
                      alt={item.Produk.nama_produk}
                      className="w-32 h-32 object-cover rounded-lg ml-14"
                    />
                    <div className="flex-1 px-5">
                      <h4 className="text-lg font-bold">
                        {item.Produk.nama_produk}
                      </h4>
                      <div className="flex justify-between items-center mt-2">
                        <h4 className="text-lg font-bold">
                          {item.Produk.diskon ? (
                            <>
                              {formatRupiah(
                                Math.floor(
                                  item.subtotal -
                                    (item.subtotal * item.Produk.diskon) / 100
                                )
                              )}
                              <span className="block text-sm text-gray-400 line-through">
                                {formatRupiah(Math.floor(item.subtotal))}
                              </span>
                            </>
                          ) : (
                            formatRupiah(Math.floor(item.subtotal))
                          )}
                        </h4>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <img
                        src={DeleteIcon}
                        alt="Hapus"
                        className="w-8 h-8 cursor-pointer ml-2"
                        onClick={() => handleRemoveItem(item.id)}
                      />
                      <button
                        onClick={() =>
                          handleChangeQuantity(item.id, item.jumlah - 1)
                        }
                        disabled={item.jumlah <= 1}
                        className="bg-black text-white w-10 h-10 flex items-center justify-center rounded"
                      >
                        -
                      </button>
                      <span className="text-xl font-bold">{item.jumlah}</span>
                      <button
                        onClick={() =>
                          handleChangeQuantity(item.id, item.jumlah + 1)
                        }
                        className="bg-black text-white w-10 h-10 flex items-center justify-center rounded"
                      >
                        +
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-full lg:w-1/3 bg-white shadow-lg rounded-lg p-6">
              <h3 className="text-2xl font-bold mb-4">Order Summary</h3>
              <p className="text-lg">
                Subtotal: {formatRupiah(subtotalAfterDiscount)}
              </p>
              <p className="text-lg">Discount: {totalDiscount}%</p>
              <p className="text-lg">Total Weight: {totalWeight} kg</p>
              <p className="text-lg">Color: {color || "N/A"}</p>
              <p className="text-xl font-bold mt-4">
                Total: {formatRupiah(totalAfterDiscount)}
              </p>
              <button
                onClick={handleCheckout}
                className="w-full bg-black text-white text-xl font-bold py-3 rounded mt-6"
              >
                CHECK OUT
              </button>
              <p className="text-center text-sm text-gray-600 mt-2">
                By confirming this order you accept our General terms and
                conditions of sale and data protection policy
              </p>

              {/* Fitur component added inside, below the order summary */}
              <div className="flex flex-wrap justify-center gap-4 transform scale-70 hover:scale-90 transition-transform duration-200">
                <div className="p-10 text-center rounded-lg flex-1 min-w-[250px] max-w-sm">
                  <div className="flex items-center justify-center gap-4">
                    <img
                      src={ClickPickup}
                      alt="Click & Pickup Icon"
                      className="w-14 h-14"
                    />
                    <div className="text-left">
                      <h6 className="text-xl font-semibold">Click & Pickup</h6>
                      <p className="text-sm w-56">
                        Shop online and pick up in store
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-10 text-center rounded-lg flex-1 min-w-[250px] max-w-sm">
                  <div className="flex items-center justify-center gap-4">
                    <img
                      src={FreeShipping}
                      alt="Free Shipping Icon"
                      className="h-14"
                    />
                    <div className="text-left">
                      <h6 className="text-xl font-semibold">Free Shipping</h6>
                      <p className="text-sm w-56">
                        Enjoy free delivery nationwide with no minimum spend
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-10 text-center rounded-lg flex-1 min-w-[250px] max-w-sm">
                  <div className="flex items-center justify-center gap-4">
                    <img
                      src={SameDayDelivery}
                      alt="Same Day Delivery Icon"
                      className="h-14"
                    />
                    <div className="text-left">
                      <h6 className="text-xl font-semibold">
                        Same Day Delivery
                      </h6>
                      <p className="text-sm w-56">
                        Order online and have it delivered to your door that
                        same day
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="w-full py-18 px-4 md:px-20 text-center">
        <div>
          <h2 className="text-3xl md:text-4xl font-semibold mb-8">
            You may also like
          </h2>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            className="mt-5"
          >
            {products.map((product) => {
              const discountPercentage = product.diskon
                ? Math.round((product.diskon / product.harga[0]) * 100)
                : null;

              return (
                <div
                  key={product.id}
                  className="p-4 cursor-pointer relative bg-white rounded-lg hover:shadow-xl transition-shadow duration-300"
                  onClick={() => handleCardClick(product.id)}
                >
                  {product.diskon && (
                    <div className="absolute top-4 left-4 bg-red-500 text-white px-3 py-1 rounded text-sm font-bold">
                      {product.diskon}% OFF
                    </div>
                  )}

                  <img
                    src={product.image[0]}
                    alt={product.nama_produk}
                    className="w-full h-32 object-contain rounded-t-lg"
                  />

                  <h4 className="text-left text-lg md:text-md font-semibold mt-4">
                    {product.nama_produk}
                  </h4>

                  <div className="flex justify-between items-center mt-4">
                    {/* Product Colors */}
                    <div className="flex space-x-2">
                      {product.color &&
                        product.color.map((color, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center"
                          >
                            <div
                              className="w-6 h-6 rounded-full"
                              style={{ backgroundColor: color }}
                            ></div>
                          </div>
                        ))}
                    </div>

                    {/* Pricing */}
                    <div className="flex flex-col items-end">
                      <p className="text-lg font-bold text-gray-800">
                        {formatRupiah(product.harga[0] - (product.diskon || 0))}
                      </p>
                      {product.diskon && (
                        <p className="text-sm text-gray-400 line-through">
                          {formatRupiah(product.harga[0])}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>

      {/* Carousel for Brands */}
      <div className="mt-7 mb-14 bg-white rounded-lg shadow-lg p-4">
        {brands.length > 0 && (
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
          >
            {brands.map((brand, index) => (
              <img
                key={index}
                src={brand.image}
                alt={`Brand ${index}`}
                onClick={() => handleImageClick(brand.id)}
                className="cursor-pointer w-full h-40 sm:h-48 md:h-56 lg:h-64 object-contain hover:scale-105 transition-transform duration-300"
              />
            ))}
          </Carousel>
        )}
      </div>

      {isModalOpen && (
        <ProductModal
          product={selectedProduct}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      <Footer />
    </div>
  );
};

export default Cart;

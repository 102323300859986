import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "react-toastify/dist/ReactToastify.css"; // Import the Toastify styles
import { FaChevronDown, FaMinus } from "react-icons/fa"; // Menggunakan React Icons
import Fitur from "../components/Fitur";
import apiClient from "../components/apiClient";
import Footer from "../components/Footer";

const formatRupiah = (angka) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka);
};

// Fungsi untuk memetakan hex ke nama warna (simple approach)
// const hexToName = (hex) => {
//   const colors = {
//     "#ffffff": "White",
//     "#0c0c0c": "Black Dove",
//     "#e0bfb8": "Rose Gold",
//     "#070707": "Matte Black",
//     "#fefefe": "Light Chrome",
//     "#f0f0f0": "Light Gray",
//     "#808080": "Gray",
//     "#000000": "Black",
//     "#ff0000": "Red",
//     "#00ff00": "Green",
//     "#0000ff": "Blue",
//     "#800080": "Purple",
//     "#ffff00": "Yellow",
//     "#ffa500": "Orange",
//     "#800000": "Maroon",
//     "#808000": "Olive",
//     "#008000": "Dark Green",
//     "#000080": "Navy Blue",
//     "#ff00ff": "Magenta",
//     "#c0c0c0": "Silver",
//     "#a52a2a": "Brown",
//     "#d3d3d3": "Light Gray",
//     "#f5f5f5": "White Smoke",
//     "#c0d6e4": "Light Steel Blue",
//     "#ff6347": "Tomato",
//     "#2e8b57": "Sea Green",
//     "#3cb371": "Medium Sea Green",
//     "#ff1493": "Deep Pink",
//     "#00bfff": "Deep Sky Blue",
//     "#00ced1": "Dark Turquoise",
//     "#8a2be2": "Blue Violet",
//     "#7fff00": "Chartreuse",
//     "#adff2f": "Green Yellow",
//     "#ffdead": "Navajo White",
//     "#dda0dd": "Plum",
//     "#ff4500": "Orange Red",
//     "#2f4f4f": "Dark Slate Gray",
//     "#bdb76b": "Dark Khaki",
//     "#9acd32": "Yellow Green",
//     "#8b4513": "Saddle Brown",
//     "#ffb6c1": "Light Pink",
//     "#cd5c5c": "Indian Red",
//     "#b0e0e6": "Powder Blue",
//     "#f0e68c": "Khaki",
//     "#e0ffff": "Light Cyan",
//   };

//   return colors[hex.toLowerCase()] || hex;
// };

let colorsData = {}; // To store the fetched colors data

// Function to fetch color data from the endpoint
const fetchColors = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/color`);
    const data = await response.json();
    colorsData = data.colorList.reduce((acc, color) => {
      acc[color.color_hex.toLowerCase()] = color.color_name;
      return acc;
    }, {});
    return colorsData;
  } catch (error) {
    console.error("Failed to fetch colors:", error);
    return {};
  }
};

const hexToName = (hex) => {
  return colorsData[hex.toLowerCase()] || hex;
};

// Fungsi untuk membuat rating bintang
const RatingStars = ({ rating }) => {
  const fullStar = "#CCAB5C";
  const emptyStar = "#9E9E9E";
  let stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <span key={i} style={{ color: fullStar, fontSize: "19px" }}>
          ★
        </span>
      );
    } else {
      stars.push(
        <span key={i} style={{ color: emptyStar, fontSize: "19px" }}>
          ★
        </span>
      );
    }
  }

  return <div>{stars}</div>;
};

const DetailProduk = () => {
  const [cartCount, setCartCount] = useState(
    parseInt(localStorage.getItem("cartCount")) || 0
  ); // Store the cart count state
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDropdownOpenSpesifictaion, setIsDropdownOpenSpesification] =
    useState(false);
  const [isDropdownOpenWhatsInTheBox, setIsDropdownOpenWhatsInTheBox] =
    useState(false);
  const [isDropdownOpenReview, setIsDropdownOpenReview] = useState(false);

  const { id } = useParams(); // Mengambil id dari URL
  const [produk, setProduk] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0); // Untuk melacak thumbnail yang dipilih
  const [rating, setRating] = useState(5); // Default rating 5
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);

  const [product, setProduct] = useState(null); // Define the state and setter here
  const [ratings, setRatings] = useState([]);
  const [ratingCount, setRatingCount] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(0);

  const handleCardClick = (id) => {
    // Redirect to product detail page
    navigate(`/produk/all/${id}`);

    // Reload the page to reflect the changes
    window.location.reload();
  };

  const handleImageClick = (id) => {
    // Set brandId in localStorage
    localStorage.setItem("brandId", id);
    // Navigate to the detail page
    navigate(`/detail-brand/${id}`);
  };

  // Fetch data dari API
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data); // Memeriksa data yang diterima
  //       setBrands(data);
  //     })
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, []);

  useEffect(() => {
    // Simulate fetching data
    const fetchBrands = async () => {
      // Replace with your actual data fetching logic
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/brand`
      );
      const data = await response.json();
      setBrands(data);
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    // localStorage.clear();
    const fetchProductDetails = async () => {
      try {
        const response = await apiClient.get(
          // `${process.env.REACT_APP_API_BASE_URL}/produk/all/${id}`
          "/produk/all/${id}"
        );
        if (response.status === 200) {
          console.log(response.data); // Check the structure of the response
          setProduct(response.data);
          setSelectedImage(response.data.imageUrls?.[0] || ""); // Set first image if available
          setSelectedPrice(response.data.harga?.[0] || 0); // Set first price if available
        }
      } catch (error) {
        console.error(
          "Error fetching product details:",
          error.response || error.message
        );
      }
    };
    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/produk/all`);
        const response = await apiClient.get("/produk/all");
        if (response.status === 200) {
          setProducts(response.data); // Asumsi bahwa API mengembalikan array produk
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  useEffect(() => {
    const syncCartCount = () => {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const newCartCount = cart.reduce((total, item) => total + item.jumlah, 0);
      setCartCount(newCartCount); // Perbarui state
    };

    const currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const apaankeq = currentCartItems.map((item) => {
      console.log("Item jumlah:", item.jumlah); // Logging item.jumlah for each item
    });

    // Menyinkronkan saat halaman di-mount
    syncCartCount();

    // Menambahkan event listener untuk perubahan localStorage
    window.addEventListener("storage", syncCartCount);

    return () => {
      window.removeEventListener("storage", syncCartCount);
    };
  }, []);

  useEffect(() => {
    // Mengambil data produk berdasarkan id
    apiClient
      // .get(`${process.env.REACT_APP_API_BASE_URL}/produk/all/${id}`)
      .get(`/produk/all/${id}`)
      .then((response) => {
        setProduk(response.data);
        setSelectedImage(response.data.imageUrls[0]); // Menetapkan gambar pertama sebagai gambar utama
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });

    // Mengambil jumlah rating produk berdasarkan id
    apiClient
      // .get(`${process.env.REACT_APP_API_BASE_URL}/rating/produk/${id}/rating-count`)
      .get(`/rating/produk/${id}/rating-count`)
      .then((response) => {
        if (response.data.ratingCount !== undefined) {
          setRatingCount(response.data.ratingCount); // Menyimpan jumlah rating
        }
      })
      .catch((error) => {
        console.error("Error fetching rating count:", error);
      });

    // Mengambil rata-rata rating produk berdasarkan id
    apiClient
      // .get(`${process.env.REACT_APP_API_BASE_URL}/rating/produk/${id}/ratings`)
      .get(`/rating/produk/${id}/ratings`)
      .then((response) => {
        if (response.data.length > 0) {
          const averageRating =
            response.data.reduce((acc, curr) => acc + curr.rating, 0) /
            response.data.length;
          setRating(Math.round(averageRating));
          setRatings(response.data); // Save the ratings data to state
        }
      })
      .catch((error) => {
        console.error("Error fetching ratings:", error);
      });
  }, [id]);

  useEffect(() => {
    const initializeColors = async () => {
      await fetchColors();
    };
    initializeColors();
  }, []);

  // Fungsi untuk menghitung harga setelah diskon
  const calculateDiscountedPrice = (price, discountPercentage) => {
    return price - price * (discountPercentage / 100);
  };

  // Fungsi untuk format persentase diskon dan menghitung penghematan
  const calculateSave = (price, discountedPrice) => {
    const saveAmount = price - discountedPrice;
    const savePercentage = (saveAmount / price) * 100;
    return { saveAmount, savePercentage };
  };

  // const handleAddToCart = () => {
  //   const isLoggedIn = localStorage.getItem("token");
  //   if (!isLoggedIn) {
  //     navigate("/login");
  //     return;
  //   }

  //   const hargaSetelahDiskon = produk.diskon
  //     ? produk.harga - (produk.harga * produk.diskon) / 100
  //     : produk.harga;

  //   const produkData = {
  //     produkId: produk.id,
  //     jumlah: 1,
  //     subtotal: hargaSetelahDiskon,
  //   };

  //   // let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   const currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   const existingProductIndex = currentCartItems.findIndex(
  //     (item) => item.produkId === produk.id
  //   );

  //   if (existingProductIndex >= 0) {
  //     currentCartItems[existingProductIndex].jumlah += 1;
  //     currentCartItems[existingProductIndex].subtotal =
  //       currentCartItems[existingProductIndex].jumlah * hargaSetelahDiskon;
  //   } else {
  //     currentCartItems.push(produkData);
  //   }

  //   // const filteredCartItems = currentCartItems.map(({ produkId, jumlah, subtotal }) => ({
  //   //   produkId,
  //   //   jumlah,
  //   //   subtotal,
  //   // }));

  //   const filteredCartItems = currentCartItems.map((item) => ({
  //     produkId: item.produkId,
  //     jumlah: item.jumlah,
  //     subtotal: item.subtotal,
  //   }));

  //   console.log("Current Cart Items:", filteredCartItems);

  //   axios
  //     .post(
  //       "http://192.168.1.3:2000/cart",
  //       //currentCartItems, // Send the array of cart items directly
  //       filteredCartItems,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log("Response Data:", response.data);
  //       const updatedCartItems =
  //         response.data.updatedCartItems || filteredCartItems;
  //       localStorage.setItem("cart", JSON.stringify(updatedCartItems));

  //       const updatedCartCount = updatedCartItems.reduce(
  //         (total, item) => total + item.jumlah,
  //         0
  //       );
  //       setCartCount(updatedCartCount);
  //       window.location.reload();

  //       toast.success("Produk berhasil ditambahkan ke keranjang!", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error adding product to cart:", error);
  //       toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     });
  // };

  // const handleAddToCart = () => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/login");
  //     return;
  //   }

  //   const hargaSetelahDiskon = produk.diskon
  //     ? produk.harga[index] - (produk.harga[index] * produk.diskon) / 100
  //     : produk.harga[index];

  //   if (selectedIndex === null) {
  //     toast.error("Please select a color.", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //     });
  //     return;
  //   }

  //   const selectedColorHex = produk.color[selectedIndex];
  //   const selectedColorName = hexToName(selectedColorHex);

  //   let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   console.log("Initial cart:", JSON.stringify(currentCartItems, null, 2));

  //   const existingProductIndex = currentCartItems.findIndex(
  //     (item) =>
  //       item.produkId === produk.id && item.color_hex === selectedColorHex
  //   );

  //   let newCartItem;

  //   if (existingProductIndex >= 0) {
  //     // FIX: Do not modify jumlah here, let the backend handle it
  //     newCartItem = {
  //       ...currentCartItems[existingProductIndex],
  //       jumlah: currentCartItems[existingProductIndex].jumlah + 1,
  //     };
  //     currentCartItems[existingProductIndex] = newCartItem;
  //   } else {
  //     newCartItem = {
  //       produkId: produk.id,
  //       jumlah: 1, // Start with 1
  //       subtotal: hargaSetelahDiskon,
  //       colorName: selectedColorName,
  //       color_hex: selectedColorHex,
  //     };
  //     currentCartItems.push(newCartItem);
  //   }

  //   localStorage.setItem("cart", JSON.stringify(currentCartItems));

  //   console.log(
  //     "Final cart before sending:",
  //     JSON.stringify(currentCartItems, null, 2)
  //   );

  //   apiClient
  //     .post("/cart", [newCartItem], {
  //       // Send only the updated item
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Response Data:", response.data);

  //       const updatedCartItems =
  //         response.data.updatedCartItems || currentCartItems;

  //       localStorage.setItem("cart", JSON.stringify(updatedCartItems));

  //       const updatedCartCount = updatedCartItems.reduce(
  //         (total, item) => total + parseInt(item.jumlah, 10),
  //         0
  //       );

  //       setCartCount(updatedCartCount);
  //       window.location.reload();

  //       toast.success("Produk berhasil ditambahkan ke keranjang!", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error adding product to cart:", error);
  //       toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     });
  // };

  // const handleAddToCart = () => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/login");
  //     return;
  //   }

  //   if (selectedIndex === null) {
  //     toast.error("Please select a color.", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //     });
  //     return;
  //   }

  //   // Use selectedIndex instead of index
  //   const hargaSetelahDiskon = produk.diskon
  //     ? produk.harga[selectedIndex] - (produk.harga[selectedIndex] * produk.diskon) / 100
  //     : produk.harga[selectedIndex];

  //   const selectedColorHex = produk.color[selectedIndex];
  //   const selectedColorName = hexToName(selectedColorHex);

  //   let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   console.log("Initial cart:", JSON.stringify(currentCartItems, null, 2));

  //   const existingProductIndex = currentCartItems.findIndex(
  //     (item) =>
  //       item.produkId === produk.id && item.color_hex === selectedColorHex
  //   );

  //   let newCartItem;

  //   if (existingProductIndex >= 0) {
  //     newCartItem = {
  //       ...currentCartItems[existingProductIndex],
  //       jumlah: currentCartItems[existingProductIndex].jumlah + 1,
  //     };
  //     currentCartItems[existingProductIndex] = newCartItem;
  //   } else {
  //     newCartItem = {
  //       produkId: produk.id,
  //       jumlah: 1,
  //       subtotal: hargaSetelahDiskon,
  //       colorName: selectedColorName,
  //       color_hex: selectedColorHex,
  //     };
  //     currentCartItems.push(newCartItem);
  //   }

  //   localStorage.setItem("cart", JSON.stringify(currentCartItems));

  //   console.log("Final cart before sending:", JSON.stringify(currentCartItems, null, 2));

  //   apiClient
  //     .post("/cart", [newCartItem], {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Response Data:", response.data);

  //       const updatedCartItems = response.data.updatedCartItems || currentCartItems;

  //       localStorage.setItem("cart", JSON.stringify(updatedCartItems));

  //       const updatedCartCount = updatedCartItems.reduce(
  //         (total, item) => total + parseInt(item.jumlah, 10),
  //         0
  //       );

  //       setCartCount(updatedCartCount);
  //       window.location.reload();

  //       toast.success("Produk berhasil ditambahkan ke keranjang!", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error adding product to cart:", error);
  //       toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     });
  // };

  const handleAddToCart = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    if (selectedIndex === null || selectedIndex === undefined) {
      toast.error("Please select a color.", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return;
    }

    if (!produk.harga || !produk.harga[selectedIndex]) {
      toast.error("Invalid price data. Please try again.", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return;
    }

    // Ensure prices are numbers
    // const selectedPrice = parseFloat(produk.harga[selectedIndex]);
    const selectedPrice =
      Array.isArray(produk.harga) && produk.harga[selectedIndex] !== undefined
        ? parseFloat(produk.harga[selectedIndex])
        : 0;

    // Store selected price in localStorage
    localStorage.setItem("selectedPrice", JSON.stringify(selectedPrice));

    const hargaSetelahDiskon = produk.diskon
      ? selectedPrice - (selectedPrice * produk.diskon) / 100
      : selectedPrice;

    const selectedColorHex = produk.color[selectedIndex];
    const selectedColorName = hexToName(selectedColorHex);

    let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    console.log("Initial cart:", JSON.stringify(currentCartItems, null, 2));

    const existingProductIndex = currentCartItems.findIndex(
      (item) =>
        item.produkId === produk.id && item.color_hex === selectedColorHex
    );

    let newCartItem;

    if (existingProductIndex >= 0) {
      const existingItem = currentCartItems[existingProductIndex];

      newCartItem = {
        ...existingItem,
        jumlah: parseInt(existingItem.jumlah, 10) + 1, // Convert jumlah to number before addition
        subtotal: parseFloat(existingItem.subtotal) + hargaSetelahDiskon, // Convert subtotal to number before addition
      };

      currentCartItems[existingProductIndex] = newCartItem;
    } else {
      newCartItem = {
        produkId: produk.id,
        jumlah: 1,
        subtotal: hargaSetelahDiskon,
        colorName: selectedColorName,
        color_hex: selectedColorHex,
      };
      currentCartItems.push(newCartItem);
    }

    // ✅ Log values to ensure correct data types
    console.log("Subtotal for selected item (number):", newCartItem.subtotal);
    console.log(
      "Updated Cart Items:",
      JSON.stringify(currentCartItems, null, 2)
    );

    localStorage.setItem("cart", JSON.stringify(currentCartItems));

    apiClient
      .post("/cart", [newCartItem], {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Response Data:", response.data);

        const updatedCartItems =
          response.data.updatedCartItems || currentCartItems;

        localStorage.setItem("cart", JSON.stringify(updatedCartItems));

        const updatedCartCount = updatedCartItems.reduce(
          (total, item) => total + parseInt(item.jumlah, 10),
          0
        );

        setCartCount(updatedCartCount);
        window.location.reload();

        toast.success("Produk berhasil ditambahkan ke keranjang!", {
          position: "bottom-right",
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.error("Error adding product to cart:", error);
        toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
          position: "bottom-right",
          autoClose: 3000,
        });
      });
  };

  const handlePreorderAllClick = () => {
    navigate("/login");
  };

  const updateCartCount = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const cartCount = cart.reduce((total, item) => total + item.jumlah, 0);
    localStorage.setItem("cartCount", cartCount); // Save the cart count
  };
  const handleThumbnailClick = (image, index) => {
    setSelectedImage(image);
    setSelectedIndex(index);
  };

  // const handleColorClick = (index) => {
  //   if (produk.imageUrls && produk.imageUrls[index]) {
  //     setSelectedImage(produk.imageUrls[index]);
  //     setSelectedIndex(index);
  //   }
  //   const selectedColor = produk.color[index];
  //   const selectedPrice = produk.harga[index];
  //   console.log(`Selected color at index ${index}: ${selectedColor}`);
  //   const colorName = hexToName(selectedColor,selectedPrice);
  //   console.log(
  //     `Selected color at index ${index}: ${selectedColor} (${colorName})`
  //   );
  //   console.log(produk.harga[index]);

  // };

  const handleColorClick = (index) => {
    if (!produk || !produk.color || !produk.harga) return;

    setSelectedIndex(index);
    setSelectedImage(produk.imageUrls?.[index] || ""); // Ensure there's an image
    setSelectedPrice(produk.harga?.[index] || 0); // Ensure there's a price

    // Store selectedPrice in localStorage
    localStorage.setItem(
      "selectedPrice",
      JSON.stringify(produk.harga?.[index] || 0)
    );

    console.log(`Selected color: ${produk.color[index]}`);
    console.log(`Selected price: ${produk.harga[index]}`);
  };

  if (!produk) {
    return <p>Loading...</p>;
  }

  const discountedPrice = produk.diskon
    ? calculateDiscountedPrice(produk.harga, produk.diskon)
    : null;
  const { saveAmount, savePercentage } = discountedPrice
    ? calculateSave(produk.harga, discountedPrice)
    : { saveAmount: 0, savePercentage: 0 };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const toggleDropdowns = () => {
    setIsDropdownOpenSpesification((prev) => !prev);
  };
  const toggleDropdownss = () => {
    setIsDropdownOpenWhatsInTheBox((prev) => !prev);
  };
  const toggleDropdownsss = () => {
    setIsDropdownOpenReview((prev) => !prev);
  };

  fetchColors();
  const currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];
  const apaankeq = currentCartItems.map((item) => {
    console.log("Item jumlah:", item.jumlah); // Logging item.jumlah for each item
  });
  return (
    <>
      <div className="p-3" style={{ marginBottom: "100px" }}>
        <ToastContainer />

        <div className="bg-gray-100 mt-[100px]">
          <div className="flex flex-col lg:flex-row items-center px-4 p-8">
            {/* Thumbnails and Image Column */}
            <div className="flex flex-col lg:flex-row lg:w-3/5 w-full mb-8 lg:mb-0">
              {/* Thumbnails Column */}
              <div className="flex flex-col overflow-y-auto space-y-4 mr-4 lg:w-1/4 mb-4 lg:mb-0 pt-16">
                {produk.imageUrls &&
                  produk.imageUrls.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Thumbnail ${index + 1}`}
                      onClick={() => handleThumbnailClick(img, index)}
                      className={`w-16 h-16 object-cover rounded-lg cursor-pointer ${
                        selectedIndex === index ? "border-2 border-black" : ""
                      }`}
                    />
                  ))}
              </div>

              {/* Selected Image Column */}
              <div className="flex-1 bg-gray-100 flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-10 rounded-lg">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt={produk.nama_produk}
                    className="max-w-full max-h-[500px] object-contain rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
                  />
                ) : (
                  <p className="text-gray-500 text-lg font-semibold">
                    No image selected
                  </p>
                )}
              </div>
            </div>

            {/* Product Details Section */}
            <div className="flex-1 w-full px-4 lg:px-8">
              <div className="flex flex-col space-y-6">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-48 h-24 object-contain"
                    src={produk.Brand.imageUrl}
                    alt={produk.Brand.nama_brand}
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <h3 className="text-2xl font-semibold">
                    {produk.nama_produk}
                  </h3>

                  {/* Rating section */}
                  <div className="flex items-center justify-between">
                    <RatingStars rating={rating} />
                    <p className="text-xl font-medium">{rating}/5</p>
                  </div>

                  <h6 className="text-xl font-light">
                    Category:{" "}
                    <span className="text-gray-600">
                      {produk.Kategori.nama_kategori}
                    </span>
                  </h6>
                </div>
                {/* <div className="flex flex-wrap space-x-4 mb-4 gap-12">
                  {produk.color &&
                    produk.color.map((color, index) => (
                      <div
                        key={index}
                        onClick={() => handleColorClick(index)}
                        className={`flex flex-col items-center mb-4 cursor-pointer ${
                          selectedIndex === index ? "border-2 border-black" : ""
                        }`}
                      >
                        <div
                          className="w-8 h-8 rounded-full mb-2"
                          style={{ backgroundColor: color }}
                        ></div>
                        <p className="text-sm text-center">
                          {hexToName(color)}
                        </p>
                      </div>
                    ))}
                </div>
              
                <div className="flex flex-col items-end space-y-2">
                  {discountedPrice ? (
                    <>
                      <p className="text-2xl font-semibold text-gray-800">
                        {formatRupiah(discountedPrice)}
                      </p>
                      <p className="text-sm text-gray-400 line-through">
                        {formatRupiah(produk.harga[selectedIndex])}
                      </p>
                      {savePercentage && (
                        <p className="text-red-500 text-sm font-medium">
                          Save {savePercentage.toFixed(0)}%
                        </p>
                      )}
                    </>
                  ) : (
                    <p className="text-2xl font-semibold text-gray-800">
                      {formatRupiah(produk.harga[selectedIndex])}
                    </p>
                  )}

                  <button
                    onClick={handleAddToCart}
                    className="w-full py-3 text-lg font-semibold text-white bg-black rounded-md mt-4"
                  >
                    Add to Cart
                  </button>
                </div> */}
                <div className="flex flex-wrap space-x-4 mb-4 gap-12">
                  {produk.color?.map((color, index) => (
                    <div
                      key={index}
                      onClick={() => handleColorClick(index)}
                      className={`flex flex-col items-center mb-4 cursor-pointer ${
                        selectedIndex === index ? "border-2 border-black" : ""
                      }`}
                    >
                      <div
                        className="w-8 h-8 rounded-full mb-2"
                        style={{ backgroundColor: color }}
                      ></div>
                      <p className="text-sm text-center">{hexToName(color)}</p>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col items-end space-y-2">
                  {discountedPrice ? (
                    <>
                      <p className="text-2xl font-semibold text-gray-800">
                        {formatRupiah(discountedPrice)}
                      </p>
                      <p className="text-sm text-gray-400 line-through">
                        {formatRupiah(selectedPrice || produk.harga[0])}
                      </p>
                      {savePercentage && (
                        <p className="text-red-500 text-sm font-medium">
                          Save {savePercentage.toFixed(0)}%
                        </p>
                      )}
                    </>
                  ) : (
                    <p className="text-2xl font-semibold text-gray-800">
                      {formatRupiah(selectedPrice || produk.harga[0])}
                    </p>
                  )}

                  <button
                    onClick={handleAddToCart}
                    className="w-full py-3 text-lg font-semibold text-white bg-black rounded-md mt-4"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "134px",
            paddingLeft: "160px",
            paddingRight: "160px",
          }}
        >
          {/* Description Header */}
          <div
            onClick={toggleDropdown}
            style={{
              fontSize: "20px",
              fontWeight: "400",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
            }}
          >
            Description
            {isDropdownOpen ? (
              <FaMinus className="text-black" style={{ fontSize: "28px" }} />
            ) : (
              <FaChevronDown
                className="text-black"
                style={{ fontSize: "28px" }}
              />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpen && (
            <div
              style={{
                marginTop: "10px",
                fontSize: "16px",
                color: "#555",
                lineHeight: "1.5",
              }}
            >
              {produk.deskripsi || "No description available."}
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: "48px",
            paddingLeft: "160px",
            paddingRight: "160px",
          }}
        >
          {/* Description Header */}
          <div
            onClick={toggleDropdowns}
            style={{
              fontSize: "20px",
              fontWeight: "400",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
            }}
          >
            Spesification
            {isDropdownOpenSpesifictaion ? (
              <FaMinus className="text-black" style={{ fontSize: "28px" }} />
            ) : (
              <FaChevronDown
                className="text-black"
                style={{ fontSize: "28px" }}
              />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpenSpesifictaion && (
            <div
              style={{
                marginTop: "10px",
                fontSize: "16px",
                color: "#555",
                lineHeight: "1.5",
              }}
            >
              {produk.spesifikasi || "No spesification available."}
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: "48px",
            paddingLeft: "160px",
            paddingRight: "160px",
          }}
        >
          {/* Description Header */}
          <div
            onClick={toggleDropdownss}
            style={{
              fontSize: "20px",
              fontWeight: "400",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
            }}
          >
            What’s in the box
            {isDropdownOpenWhatsInTheBox ? (
              <FaMinus className="text-black" style={{ fontSize: "28px" }} />
            ) : (
              <FaChevronDown
                className="text-black"
                style={{ fontSize: "28px" }}
              />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpenWhatsInTheBox && (
            <div
              style={{
                marginTop: "10px",
                fontSize: "16px",
                color: "#555",
                lineHeight: "1.5",
              }}
            >
              {produk.keterangan || "No data available."}
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: "48px",
            paddingLeft: "160px",
            paddingRight: "160px",
          }}
        >
          {/* Description Header */}
          <div
            onClick={toggleDropdownsss}
            style={{
              fontSize: "20px",
              fontWeight: "400",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
            }}
          >
            Review
            {isDropdownOpenReview ? (
              <FaMinus className="text-black" style={{ fontSize: "28px" }} />
            ) : (
              <FaChevronDown
                className="text-black"
                style={{ fontSize: "28px" }}
              />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpenReview && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                marginTop: "48px",
                fontSize: "16px",
                color: "#555",
                lineHeight: "1.5",
              }}
            >
              {/* Left Section - Ratings */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {/* Menampilkan RatingStars */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    {/* Rating Stars on the Left */}
                    {Array.from({ length: 1 }, (_, index) => (
                      <span
                        key={index}
                        style={{
                          color: index < rating ? "#CCAB5C" : "#ddd",
                          fontSize: "43px",
                        }} // Adjusted font size for stars
                      >
                        ★
                      </span>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "128px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>{rating}</p>
                    <span style={{ fontSize: "28px" }}>/5.0</span>{" "}
                    {/* Adjust the font size of /5 */}
                  </div>
                </div>

                {/* Menampilkan jumlah review */}
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "#808080",
                    marginTop: "5px",
                  }}
                >
                  {ratingCount > 0 ? `${ratingCount} Ratings` : "0 Ratings"}
                </p>
              </div>

              <div>
                {/* Reviews Section - Positioned below Photos & Videos */}
                <div style={{ marginTop: "20px" }}>
                  {/* Adds spacing between the two sections */}
                  <span style={{ fontSize: "40px", fontWeight: "400px" }}>
                    Review
                  </span>

                  {ratings && ratings.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        marginTop: "62px",
                        flexDirection: "column",
                      }}
                    >
                      {ratings.map((ratingItem, index) => (
                        <div
                          key={ratingItem.id}
                          style={{
                            display: "flex",
                            flexDirection: "column", // Arrange elements vertically
                            alignItems: "flex-start",
                            marginBottom: "15px",
                          }}
                        >
                          {/* Rating Stars - Positioned above the Icon */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "56px", // Adjusted for star size (width 54.7px, height 56px)
                              fontWeight: "400",
                              color: "#555",
                              marginBottom: "38px", // Adjust space between stars and icon
                            }}
                          >
                            {/* Star Icons for each review */}
                            {Array.from({ length: 5 }, (_, index) => (
                              <span
                                key={index}
                                style={{
                                  color:
                                    index < ratingItem.rating
                                      ? "#CCAB5C"
                                      : "#ddd",
                                  width: "54.7px", // Adjust the width
                                  height: "56px", // Adjust the height
                                  display: "inline-block",
                                  fontSize: "inherit", // Inherit the fontSize
                                  lineHeight: "56px", // Align stars vertically
                                  textAlign: "center", // Align the stars horizontally
                                }}
                              >
                                ★
                              </span>
                            ))}
                          </div>

                          {/* User's Icon and Name */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center", // Align icon and name in a row
                              marginLeft: "10px", // Adds space between stars and icon+name
                            }}
                          >
                            {/* User's Icon */}
                            <div
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                                backgroundColor: "#ccc",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "10px", // Space between icon and name
                              }}
                            >
                              {/* FontAwesome User Icon */}
                              <i
                                className="fas fa-user" // FontAwesome user icon class
                                style={{
                                  fontSize: "24px", // Adjust the icon size
                                  color: "white", // Icon color
                                }}
                              />
                            </div>

                            {/* Name of the User (Masked) */}
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#333",
                                marginRight: "10px",
                                paddingLeft: "33px",
                              }}
                            >
                              {ratingItem.User?.nama
                                ? `${ratingItem.User.nama.charAt(
                                    0
                                  )}*${ratingItem.User.nama.slice(-1)}`
                                : "Anonymous"}
                            </p>
                          </div>

                          {/* Review */}
                          {ratingItem.review && (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "400px",
                                color: "#666",
                                marginTop: "38px",
                              }}
                            >
                              {ratingItem.review}
                            </p>
                          )}

                          {/* Media Section (Image and Video) */}
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              width: "100%",
                            }}
                          >
                            {/* Image on the Left */}
                            {ratingItem.imageUrl && (
                              <div
                                style={{ width: "50%", paddingRight: "10px" }}
                              >
                                <img
                                  src={ratingItem.imageUrl}
                                  alt="User Upload"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: "8px",
                                  }}
                                />
                              </div>
                            )}

                            {/* Video on the Right */}
                            {ratingItem.videoUrl && (
                              <div
                                style={{ width: "50%", paddingLeft: "10px" }}
                              >
                                <video
                                  width="100%"
                                  controls={false} // Disable immediate play
                                  style={{ borderRadius: "8px" }}
                                >
                                  <source
                                    src={ratingItem.videoUrl}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            )}
                          </div>

                          {/* Conditionally render <hr /> if there are more than 1 review */}
                          {index < ratings.length - 1 && (
                            <hr
                              style={{
                                width: "100%",
                                marginTop: "20px",
                                border: "1px solid #ccc",
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No reviews available.</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-full py-36 px-4 text-4xl font-medium">
          <div>
            <div className="text-center">You may also like</div>
            <Carousel responsive={responsive} className="mt-5">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="relative p-2 cursor-pointer"
                  onClick={() => handleCardClick(product.id)} // Replace with handleImageClick
                >
                  {product.diskon && (
                    <div className="absolute top-5 left-5 bg-red-500 text-white px-3 py-1 rounded font-semibold text-sm">
                      {product.diskon}% OFF
                    </div>
                  )}
                  <img
                    src={product.image[0]} // Access the first image
                    alt={product.nama_produk}
                    className="w-48 h-28 object-contain"
                  />
                  <h4 className="flex justify-start text-xl font-semibold mt-2 mb-4">
                    {product.nama_produk}
                  </h4>
                  <div className="flex justify-between items-center p-5">
                    <div className="flex justify-start">
                      {product.color &&
                        product.color.map((color, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center mr-2"
                          >
                            <div
                              className="w-8 h-8 rounded-full mb-2"
                              style={{ backgroundColor: color }}
                            ></div>
                          </div>
                        ))}
                    </div>
                    <div className="flex flex-col items-end">
                      <p className="text-lg font-semibold text-gray-900">
                        {formatRupiah(
                          product.harga[0] - (product.diskon || 0)
                        ).replace("Rp", "Rp ")}
                      </p>
                      {product.diskon && (
                        <p className="text-sm text-black line-through">
                          {formatRupiah(
                            product.harga[0] || product.harga
                          ).replace("Rp", "Rp ")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <Fitur />

        <div
          style={{
            marginTop: "28px",
            marginBottom: "58px",
            backgroundColor: "white",
          }}
        >
          {brands.length > 0 && (
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
            >
              {brands.map((brand, index) => (
                <img
                  key={index}
                  src={brand.image}
                  alt={`Brand ${index}`}
                  onClick={() => handleImageClick(brand.id)}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "170px",
                    objectFit: "contain",
                  }}
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default DetailProduk;

import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  // Menu,
  // X,
  MenuItem,
  Typography,
  TextField,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import "./Navbar.css";
import apiClient from "./apiClient";
import LogoBrand from "../assets/Logo Musique Hitam.png";
import SearchButton from "../assets/Search_Magnifying_Glass.svg";
import CartButton from "../assets/Shopping_Cart_02.svg";
import UserButton from "../assets/User_01.svg";
import FlagIndo from "../assets/Flag_indo.svg";
import { Menu, X } from "lucide-react";

const Navbar = () => {
  const userMenuButtonRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [megaMenuVisible, setMegaMenuVisible] = useState(false);
  // const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [showNavbar, setShowNavbar] = useState(true);
  const navigate = useNavigate();
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [userImage, setUserImage] = useState(null); // State untuk gambar pengguna
  const [userMegaMenuVisible, setUserMegaMenuVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [produk, setProduk] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedKategori, setSelectedKategori] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await apiClient.get("/kategori");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const { kategoriId: urlKategoriId } = useParams();
  const kategoriId = urlKategoriId || localStorage.getItem("kategoriId");

  const handleCategoryClick = async (kategoriId) => {
    setSelectedKategori(kategoriId);
    setLoading(true);
    setError("");
    localStorage.setItem("kategoriId", kategoriId);
    // navigate(`/produk-kategori/${kategoriId}`);

    try {
      const produkResponse = await apiClient.get(
        `/produk/kategori/${kategoriId}`
      );
      setProduk(produkResponse.data);
    } catch (err) {
      setError("Failed to get Product");
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClickMobile = async (kategoriId) => {
    setSelectedKategori(kategoriId);
    setLoading(true);
    setError("");
    localStorage.setItem("kategoriId", kategoriId);
    navigate(`/produk-kategori/${kategoriId}`);

    try {
      const produkResponse = await apiClient.get(
        `/produk/kategori/${kategoriId}`
      );
      setProduk(produkResponse.data);
    } catch (err) {
      setError("Failed to get Product");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!kategoriId) {
      setError("Kategori tidak ditemukan");
      setLoading(false);
      return;
    }

    const fetchProduk = async () => {
      try {
        setLoading(true);
        const produkResponse = await apiClient.get(
          `/produk/kategori/${kategoriId}`
        );
        setProduk(produkResponse.data);
      } catch (err) {
        setError("Product Not Found");
      } finally {
        setLoading(false);
      }
    };

    fetchProduk();
  }, [kategoriId]);

  const handleProductsClick = async () => {
    setIsOpen(!isOpen); // Keep the dropdown toggle

    if (!categories.length) {
      // Fetch categories only if not loaded
      try {
        const response = await apiClient.get("/kategori");
        setCategories(response.data);

        if (response.data.length > 0) {
          const firstCategory = response.data[0];
          setSelectedKategori(firstCategory.id);
          localStorage.setItem("kategoriId", firstCategory.id);
          // navigate(`/produk-kategori/${firstCategory.id}`);
        }
      } catch (error) {
        console.error("Error fetching first category:", error);
      }
    } else {
      // If categories are already loaded, ensure it selects the first category
      const firstCategory = categories[0];
      if (firstCategory) {
        setSelectedKategori(firstCategory.id);
        localStorage.setItem("kategoriId", firstCategory.id);
        // navigate(`/produk-kategori/${firstCategory.id}`);
      }
    }
  };
  // Handle scroll to show/hide navbar
  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false); // Scroll ke bawah, sembunyikan navbar
      } else {
        setShowNavbar(true); // Scroll ke atas, tampilkan navbar
      }
      lastScrollY = window.scrollY;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await apiClient.get("/user/profile", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUserName(response.data.nama || "Pengguna");
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();

    // Update cart count
    const updateCartCount = () => {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      setCartCount(cart.reduce((total, item) => total + item.jumlah, 0));
    };

    updateCartCount();
    window.addEventListener("cart-updated", updateCartCount);

    return () => window.removeEventListener("cart-updated", updateCartCount);
  }, []); // Add [] to only run once on mount

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    setIsLoggedIn(false);
    setIsOTPVerified(false); // Reset OTP verification state on logout
    navigate("/login");
  };

  const handleOTPVerification = () => {
    setIsOTPVerified(true); // Set to true after successful verification
  };

  // Handle cart click
  const handleCartClick = () => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      navigate("/cart");
    }
  };

  // Handle user menu click
  const handleUserMenuClick = () => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      // setUserMegaMenuVisible(!userMegaMenuVisible); 
      setUserMegaMenuVisible((prev) => !prev);
    }
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userMenuButtonRef.current &&
        !userMenuButtonRef.current.contains(event.target) &&
        !event.target.closest(".mega-menu-user")
      ) {
        setUserMegaMenuVisible(false); // Close the menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Close the mega menu when clicking a menu item
  const handleMenuItemClick = (path) => {
    setUserMegaMenuVisible(false); // Close the menu after item click
    if (path) {
      navigate(path); // Navigate to the specific path
    }
  };

  const closeUserMenu = () => {
    setDropdownOpen(false);
  };

  const handleSearchIconClick = () => setMegaMenuVisible(!megaMenuVisible);

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      try {
        const response = await apiClient.get(`/produk?query=${query}`);
        const filteredResults = response.data.filter(
          (item) => item.nama_produk.toLowerCase().includes(query.toLowerCase()) // Filter based on 'nama_produk'
        );
        setSearchResults(filteredResults);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]); // Clear search results if query length is less than 3
    }
  };

  const handleSearchItemClick = (item) => {
    navigate(`/produk/all/${item.id}`);
    setMegaMenuVisible(false);
  };

  // Handle language menu
  // const handleLanguageMenuOpen = (event) => {
  //   setAnchorElLanguage(event.currentTarget);
  // };

  // const handleLanguageMenuClose = (language) => {
  //   setAnchorElLanguage(null);
  //   if (language) {
  //     console.log(`Language changed to: ${language}`);
  //   }
  // };

  const toggleUserMegaMenu = () => {
    setUserMegaMenuVisible(!userMegaMenuVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userMenuButtonRef.current &&
        !userMenuButtonRef.current.contains(event.target) &&
        !event.target.closest(".mega-menu-user")
      ) {
        setUserMegaMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleCategoryLink = (categoryId) => {
    setSelectedKategori(categoryId); // Update selected category
    localStorage.setItem("kategoriId", categoryId); // Save category ID
    setIsOpen(false); // Close dropdown
    navigate(`/produk-kategori/${categoryId}`);
  };

  const isNewProduct = (createdAt) => {
    if (!createdAt) return false;
    const productDate = new Date(createdAt);
    const currentDate = new Date();

    return (
      productDate.getMonth() === currentDate.getMonth() &&
      productDate.getFullYear() === currentDate.getFullYear()
    );
  };

  const fetchNewProducts = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/produk/all");
      const allProducts = response.data || [];

      // Filter only new products
      const newProducts = allProducts.filter((item) =>
        isNewProduct(item.createdAt)
      );

      setProduk(newProducts); // Update state with only new products
    } catch (error) {
      setError("Failed to fetch new products.");
      console.error("Error fetching new products:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNewProducts();
  }, []);

  const [isNewProductsSelected, setIsNewProductsSelected] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleLanguageMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLanguageMenuClose = (lang) => {
    console.log("Selected Language:", lang);
    setMenuOpen(false);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [productDropdownOpen, setProductDropdownOpen] = useState(false);

  // return (
  //   <div
  //     className={`fixed top-0 left-0 w-full bg-white shadow-md transition-transform duration-300 z-[999]
  //       ${showNavbar ? "translate-y-0" : "-translate-y-full"}`}
  //     style={{ height: "108px" }}
  //   >
  //     <div className="max-w-6xl mx-auto flex items-center justify-between py-4">
  //       <Link to="/" className="flex items-center w-64">
  //         <img src={LogoBrand} alt="Logo" className="h-16" />
  //       </Link>

  //       <div className="flex items-center justify-center gap-10 text-lg relative w-full">
  //         <Link
  //           className="text-black font-semibold px-4 py-2 rounded-lg hover:bg-gray-200 transition  whitespace-nowrap"
  //           to="/"
  //         >
  //           Home
  //         </Link>

  //         <div className="relative">
  //           <button
  //             onClick={handleProductsClick}
  //             className="text-black font-semibold px-4 py-2 rounded-lg hover:bg-gray-200 transition"
  //           >
  //             Products
  //           </button>

  //           {isOpen && (
  //             <div className="fixed left-0 w-full bg-white border border-gray-300 shadow-lg z-50">
  //               <div className="px-10 py-4 grid grid-cols-3 gap-4">
  //                 {/* Left Column: Categories */}
  //                 <div className="col-span-1">
  //                   <ul className="flex flex-col gap-2">
  //                     <li
  //                       className={`text-black px-4 py-2 cursor-pointer transition ${
  //                         selectedTab === "New Products"
  //                           ? "bg-black text-white"
  //                           : "hover:bg-black hover:text-white"
  //                       }`}
  //                       onClick={() => {
  //                         setSelectedTab("New Products");
  //                         fetchNewProducts();
  //                       }}
  //                     >
  //                       New Products
  //                     </li>
  //                     <li
  //                       className="px-4 py-2 hover:bg-black hover:text-white cursor-pointer font-bold"
  //                       onClick={() => setIsOpen(false)}
  //                     >
  //                       <Link
  //                         to="/all-produk-new"
  //                         className="block w-full h-full"
  //                       >
  //                         View All
  //                       </Link>
  //                     </li>

  //                     {categories.map((category) => (
  //                       <li
  //                         key={category.id}
  //                         className={`text-black px-4 py-2 cursor-pointer ${
  //                           selectedKategori === category.id
  //                             ? "bg-black text-white"
  //                             : "hover:bg-black hover:text-white"
  //                         }`}
  //                         onClick={() => handleCategoryClick(category.id)}
  //                       >
  //                         {category.nama_kategori}
  //                       </li>
  //                     ))}
  //                   </ul>
  //                 </div>

  //                 <div className="col-span-2">
  //                   {kategoriId && (
  //                     <div className="flex justify-end">
  //                       <button
  //                         className="mb-4 px-4 py-2 bg-black text-white rounded hover:bg-blue-600"
  //                         onClick={() => {
  //                           setIsOpen(false);
  //                           navigate(`/produk-kategori/${kategoriId}`);
  //                         }}
  //                       >
  //                         View All
  //                       </button>
  //                     </div>
  //                   )}

  //                   {loading ? (
  //                     <p className="text-center">Loading...</p>
  //                   ) : error ? (
  //                     <p className="text-center text-red-500">{error}</p>
  //                   ) : produk.length > 0 ? (
  //                     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
  //                       {produk.slice(0, 6).map((item) => (
  //                         <div key={item.id} className="relative p-2">
  //                           {isNewProduct(item.createdAt) && (
  //                             <span className="absolute top-2 left-2 bg-red-500 text-white text-xs px-2 py-1 rounded-md font-semibold">
  //                               NEW
  //                             </span>
  //                           )}
  //                           <img
  //                             src={item.image[0]}
  //                             alt={item.nama_produk}
  //                             className="w-full h-24 sm:h-28 md:h-32 object-contain rounded-lg"
  //                           />
  //                           <p className="text-center mt-2 text-sm sm:text-base md:text-lg text-black font-medium">
  //                             {item.nama_produk}
  //                           </p>
  //                         </div>
  //                       ))}
  //                     </div>
  //                   ) : (
  //                     <p className="text-center text-gray-600">
  //                       No new products available.
  //                     </p>
  //                   )}
  //                 </div>
  //               </div>
  //             </div>
  //           )}
  //         </div>

  //         <Link
  //           className="text-black font-semibold px-4 py-2 rounded-lg hover:bg-gray-200 transition  whitespace-nowrap"
  //           to="/all-brand"
  //         >
  //           Brand
  //         </Link>
  //         <Link
  //           className="text-black font-semibold px-4 py-2 rounded-lg hover:bg-gray-200 transition  whitespace-nowrap"
  //           to="/our-outlet"
  //         >
  //           Store
  //         </Link>
  //         <Link
  //           className="text-black font-semibold px-4 py-2 rounded-lg hover:bg-gray-200 transition whitespace-nowrap"
  //           to="/e-catalog"
  //         >
  //           E-Catalog
  //         </Link>
  //       </div>

  //       <div className="flex items-center gap-2 relative">
  //         {/* Search Button */}
  //         <IconButton
  //           onClick={handleSearchIconClick}
  //           className="focus:outline-none"
  //         >
  //           <img src={SearchButton} alt="Search Icon" className="h-7" />
  //         </IconButton>

  //         <IconButton
  //           onClick={handleCartClick}
  //           className="relative focus:outline-none w-10 h-10 flex items-center justify-center"
  //         >
  //           {cartCount > 0 && (
  //             <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-0.5">
  //               {cartCount}
  //             </span>
  //           )}
  //           <img
  //             src={CartButton}
  //             alt="Cart Icon"
  //             className="block w-auto h-8"
  //           />
  //         </IconButton>

  //         <IconButton
  //           onClick={handleUserMenuClick}
  //           className="focus:outline-none"
  //         >
  //           {userImage ? (
  //             <img
  //               src={userImage}
  //               alt="User Image"
  //               className="h-8 w-8 rounded-full"
  //             />
  //           ) : (
  //             <img src={UserButton} alt="User Icon" className="h-8" />
  //           )}
  //         </IconButton>

  //         {isLoggedIn && userMegaMenuVisible && (
  //           <div className="fixed top-24 left-1/2 transform -translate-x-1/2 w-full max-w-3xl bg-white shadow-lg z-50 p-4 transition-all duration-300 ease-in-out scale-100 opacity-100">
  //             <div className="flex justify-between items-center p-4">
  //               {/* User Avatar */}
  //               <div className="flex flex-col items-center">
  //                 <img
  //                   src={userImage || ""}
  //                   alt="User Avatar"
  //                   className="h-40 w-40 rounded-full bg-gray-200"
  //                 />
  //               </div>

  //               <ul className="space-y-3 text-lg text-gray-700">
  //                 <li>
  //                   <button
  //                     onClick={() => navigate("/settings")}
  //                     className="hover:text-black transition"
  //                   >
  //                     Account
  //                   </button>
  //                 </li>
  //                 <li>
  //                   <button
  //                     onClick={() => navigate("/order-history")}
  //                     className="hover:text-black transition"
  //                   >
  //                     Order History
  //                   </button>
  //                 </li>
  //                 <li>
  //                   <button
  //                     onClick={handleLogout}
  //                     className="text-red-500 hover:text-red-700 transition"
  //                   >
  //                     Logout
  //                   </button>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         )}

  //         <IconButton
  //           onClick={handleLanguageMenuOpen}
  //           className="focus:outline-none"
  //         >
  //           <img
  //             src={FlagIndo}
  //             alt="Language Selector"
  //             className="h-7 w-10 object-cover"
  //           />
  //         </IconButton>
  //         <div className="relative inline-block">
  //           {menuOpen && (
  //             <div className="absolute top-full right-0 mt-2 bg-white shadow-lg rounded-md z-50">
  //               <button
  //                 onClick={() => handleLanguageMenuClose("en")}
  //                 className="text-black text-left block px-4 py-2 hover:bg-gray-200 w-full"
  //               >
  //                 English
  //               </button>
  //               <button
  //                 onClick={() => handleLanguageMenuClose("id")}
  //                 className="text-black text-left block px-4 py-2 hover:bg-gray-200 w-full"
  //               >
  //                 Bahasa Indonesia
  //               </button>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </div>

  //     {megaMenuVisible && (
  //       <div className="absolute top-[88px] left-0 right-0 z-10 p-4 bg-white shadow-lg rounded-md w-full mx-auto">
  //         <input
  //           type="text"
  //           placeholder="Search..."
  //           value={searchQuery}
  //           onChange={handleSearchChange}
  //           className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
  //         />

  //         <ul className="mt-2 bg-white rounded-md shadow-md max-h-60 overflow-y-auto">
  //           {searchResults.length > 0 ? (
  //             searchResults.map((item) => (
  //               <li
  //                 key={item.id}
  //                 className="p-2 hover:bg-gray-100 cursor-pointer transition duration-200 text-black"
  //                 onClick={() => handleSearchItemClick(item)}
  //               >
  //                 {item.nama_produk}
  //               </li>
  //             ))
  //           ) : (
  //             <li className="p-2 text-black text-center">No results found</li>
  //           )}
  //         </ul>
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    //   <div
    //     className={`fixed top-0 left-0 w-full bg-white shadow-md transition-transform duration-300 z-[999]
    //       ${showNavbar ? "translate-y-0" : "-translate-y-full"}`}
    //     style={{ height: "108px" }}
    //   >
    //     <div className="max-w-6xl mx-auto flex items-center justify-between py-4">
    //       <Link to="/" className="flex items-center w-64">
    //         <img src={LogoBrand} alt="Logo" className="h-16" />
    //       </Link>
    <nav className="fixed top-0 left-0 w-full bg-white shadow-md transition-transform duration-300 z-[999]">
      <div className="max-w-7xl mx-auto flex items-center justify-between p-4">
        {/* Logo */}
        <Link to="/" className="hidden md:flex flex items-center  w-64">
          <img src={LogoBrand} alt="Logo" className="h-16" />
        </Link>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center gap-10 text-lg">
          <Link to="/" className="hover:text-gray-500 text-black font-semibold">
            Home
          </Link>
          <div className="relative">
            <button
              onClick={handleProductsClick}
              className="text-black font-semibold px-4 py-2 rounded-lg hover:bg-gray-200 transition"
            >
              Products
            </button>

            {isOpen && (
              <div className="fixed left-0 w-full bg-white border border-gray-300 shadow-lg z-50">
                <div className="px-10 py-4 grid grid-cols-3 gap-4">
                  {/* Left Column: Categories */}
                  <div className="col-span-1">
                    <ul className="flex flex-col gap-2">
                      <li
                        className={`text-black px-4 py-2 cursor-pointer transition ${
                          selectedTab === "New Products"
                            ? "bg-black text-white"
                            : "hover:bg-black hover:text-white"
                        }`}
                        onClick={() => {
                          setSelectedTab("New Products");
                          fetchNewProducts();
                        }}
                      >
                        New Products
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-black hover:text-white cursor-pointer font-bold"
                        onClick={() => setIsOpen(false)}
                      >
                        <Link
                          to="/all-produk-new"
                          className="block w-full h-full"
                        >
                          View All
                        </Link>
                      </li>

                      {categories.map((category) => (
                        <li
                          key={category.id}
                          className={`text-black px-4 py-2 cursor-pointer ${
                            selectedKategori === category.id
                              ? "bg-black text-white"
                              : "hover:bg-black hover:text-white"
                          }`}
                          onClick={() => handleCategoryClick(category.id)}
                        >
                          {category.nama_kategori}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="col-span-2">
                    {kategoriId && (
                      <div className="flex justify-end">
                        <button
                          className="mb-4 px-4 py-2 bg-black text-white rounded hover:bg-blue-600"
                          onClick={() => {
                            setIsOpen(false);
                            navigate(`/produk-kategori/${kategoriId}`);
                          }}
                        >
                          View All
                        </button>
                      </div>
                    )}

                    {loading ? (
                      <p className="text-center">Loading...</p>
                    ) : error ? (
                      <p className="text-center text-red-500">{error}</p>
                    ) : produk.length > 0 ? (
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {produk.slice(0, 6).map((item) => (
                          <div key={item.id} className="relative p-2">
                            {isNewProduct(item.createdAt) && (
                              <span className="absolute top-2 left-2 bg-red-500 text-white text-xs px-2 py-1 rounded-md font-semibold">
                                NEW
                              </span>
                            )}
                            <img
                              src={item.image[0]}
                              alt={item.nama_produk}
                              className="w-full h-24 sm:h-28 md:h-32 object-contain rounded-lg"
                            />
                            <p className="text-center mt-2 text-sm sm:text-base md:text-lg text-black font-medium">
                              {item.nama_produk}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-center text-gray-600">
                        No new products available.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Link
            to="/all-brand"
            className="hover:text-gray-500 text-black font-semibold"
          >
            Brand
          </Link>
          <Link
            to="/our-outlet"
            className="hover:text-gray-500 text-black font-semibold"
          >
            Store
          </Link>
          <Link
            to="/e-catalog"
            className="hover:text-gray-500 text-black font-semibold"
          >
            E-Catalog
          </Link>
        </div>

        {/* Icons & Mobile Menu Button */}
        <Link to="/" className="flex md:hidden items-center  w-32">
          <img src={LogoBrand} alt="Logo" className="h-10" />
        </Link>
        <div className="flex items-center gap-2">
          <IconButton
            onClick={handleSearchIconClick}
            className="focus:outline-none"
          >
            <img src={SearchButton} alt="Search Icon" className="h-7" />
          </IconButton>
          <div className="hidden md:flex">
            <IconButton
              onClick={handleCartClick}
              className="relative focus:outline-none w-10 h-10 flex items-center justify-center"
            >
              {cartCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-0.5">
                  {cartCount}
                </span>
              )}
              <img
                src={CartButton}
                alt="Cart Icon"
                className="block w-auto h-8"
              />
            </IconButton>
          </div>
          <IconButton
            onClick={handleUserMenuClick}
            className="focus:outline-none relative"
          >
            {userImage ? (
              <img
                src={userImage}
                alt="User Image"
                className="block w-auto h-8 rounded-full"
              />
            ) : (
              <img src={UserButton} alt="User Icon" className="h-8" />
            )}
          </IconButton>

          {isLoggedIn && userMegaMenuVisible && (
            <div className="absolute top-16 right-0 w-64 bg-white shadow-lg z-50 p-2 rounded-lg transition-all duration-300 ease-in-out opacity-100 border border-gray-200">
              <div className="flex flex-col items-center p-2">
                {/* User Avatar */}
                <img
                  src={userImage || ""}
                  alt="User Avatar"
                  className="h-20 w-20 rounded-full bg-gray-200"
                />

                <ul className="space-y-1 text-md text-gray-700 w-full text-center">
                  <li>
                    <button
                      onClick={() => navigate("/settings")}
                      className="block text-black w-full py-1 hover:text-black hover:bg-gray-100 rounded transition"
                    >
                      Account
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/order-history")}
                      className="block text-black w-full py-1 hover:text-black hover:bg-gray-100 rounded transition"
                    >
                      Order History
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="block w-full py-1 text-red-500 hover:text-red-700 hover:bg-gray-100 rounded transition"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className="hidden md:flex">
            <IconButton
              onClick={handleLanguageMenuOpen}
              className="focus:outline-none"
            >
              <img
                src={FlagIndo}
                alt="Language Selector"
                className="h-7 w-10 object-cover"
              />
            </IconButton>
          </div>
          <div className="relative inline-block">
            {/* Dropdown Menu */}
            {menuOpen && (
              <div className="absolute top-full right-0 mt-2 bg-white shadow-lg rounded-md z-50">
                <button
                  onClick={() => handleLanguageMenuClose("en")}
                  className="text-black text-left block px-4 py-2 hover:bg-gray-200 w-full"
                >
                  English
                </button>
                <button
                  onClick={() => handleLanguageMenuClose("id")}
                  className="text-black text-left block px-4 py-2 hover:bg-gray-200 w-full"
                >
                  Bahasa Indonesia
                </button>
              </div>
            )}
          </div>
          <button
            className="md:hidden text-black"
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen);
              setProductDropdownOpen(false);
            }}
          >
            {mobileMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="md:hidden bg-white shadow-md flex flex-col gap-4 p-4">
          <Link
            to="/"
            className="hover:text-gray-500"
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen);
              setProductDropdownOpen(false);
            }}
          >
            Home
          </Link>
          <IconButton
            onClick={handleCartClick}
            className="relative focus:outline-none w-10 h-10 flex items-center justify-center"
          >
            {cartCount > 0 && (
              <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-0.5">
                {cartCount}
              </span>
            )}
            <span className="ml-5 text-left whitespace-nowrap text-base text-black hover:text-gray-500">
              My Cart
            </span>
          </IconButton>
          <button
            onClick={() => setProductDropdownOpen(!productDropdownOpen)}
            className="text-black hover:text-gray-500 text-left"
          >
            Products
          </button>
          {productDropdownOpen && (
            <div className="fixed left-0 w-full bg-white border border-gray-300 shadow-lg z-50">
              <div className="px-10 py-4 gap-4">
                {/* Left Column: Categories */}
                <div className="col-span-1">
                  <ul className="flex flex-col gap-2">
                    <li
                      className={`text-black px-4 py-2 cursor-pointer transition ${
                        selectedTab === "New Products"
                          ? "bg-black text-white"
                          : "hover:bg-black hover:text-white"
                      }`}
                      onClick={() => {
                        setSelectedTab("New Products");
                        fetchNewProducts();
                      }}
                    >
                      New Products
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-black hover:text-white cursor-pointer font-bold"
                      onClick={() => setIsOpen(false)}
                    >
                      <Link
                        to="/all-produk-new"
                        className="block w-full h-full"
                      >
                        View All
                      </Link>
                    </li>

                    {categories.map((category) => (
                      <li
                        key={category.id}
                        className={`text-black px-4 py-2 cursor-pointer ${
                          selectedKategori === category.id
                            ? "bg-black text-white"
                            : "hover:bg-black hover:text-white"
                        }`}
                        onClick={() => handleCategoryClickMobile(category.id)}
                      >
                        {category.nama_kategori}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          <Link to="/all-brand" className="hover:text-gray-500">
            Brand
          </Link>
          <Link to="/our-outlet" className="hover:text-gray-500">
            Store
          </Link>
          <Link to="/e-catalog" className="hover:text-gray-500">
            E-Catalog
          </Link>
          <IconButton
            onClick={handleLanguageMenuOpen}
            className="relative focus:outline-none w-10 h-10 flex items-center justify-center"
          >
            <span className="ml-9 text-left whitespace-nowrap text-base text-black hover:text-gray-500">
              Language
            </span>
          </IconButton>
        </div>
      )}
      {megaMenuVisible && (
        <div className="absolute top-[88px] left-0 right-0 z-10 p-4 bg-white shadow-lg rounded-md w-full mx-auto">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 text-black"
          />

          <ul className="mt-2 bg-white rounded-md shadow-md max-h-60 overflow-y-auto">
            {searchResults.length > 0 ? (
              searchResults.map((item) => (
                <li
                  key={item.id}
                  className="p-2 hover:bg-gray-100 cursor-pointer transition duration-200 text-black"
                  onClick={() => handleSearchItemClick(item)}
                >
                  {item.nama_produk}
                </li>
              ))
            ) : (
              <li className="p-2 text-black text-center">No results found</li>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

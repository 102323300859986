import React, { useEffect, useState } from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import Footer from "./Footer";
import Banner from "./Banner";
import BrandsSwiper from "./BrandsSwiper";
import Navbar from "./Navbar";
import Fitur from "./Fitur";
import Kategori from "./Kategori";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProdukNewArrivalLimit from "./ProdukSwipeCard"; // Import Komponen ProdukSwipeCard
import { useSearchParams } from "react-router-dom";
import apiClient from "../components/apiClient";
import { X } from "lucide-react";
import Popup from "../assets/popup.png";

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [promo, setPromo] = useState(null);

  useEffect(() => {
    const fetchPromo = async () => {
      try {
        const response = await apiClient("/promo/show");
        if (response.data.length > 0) {
          setPromo(response.data[0]); // Get first promo
        }
      } catch (error) {
        console.error("Error fetching promo:", error);
      }
    };

    fetchPromo();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  if (!promo) return null; // Hide popup only if promo is not available

  return (
    <div>
      <Navbar />
      <Banner />
      <Box sx={{ padding: "40px", marginBottom: "20px" }}>
        <Kategori />
      </Box>
      <Box sx={{ padding: "100px", marginTop: "100px", marginBottom: "10px" }}>
        <ProdukNewArrivalLimit />
      </Box>
      <Box sx={{ marginBottom: "100px" }}>
        <BrandsSwiper />
      </Box>
      <Box
        sx={{
          padding: "60px",
          marginTop: "0px",
          marginBottom: "0px",
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Fitur />
      </Box>

      {/* Toast Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        style={{ zIndex: 1500 }}
      />

      <Footer />

      {/* Popup Modal (Only shown when showPopup is true) */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[827px] h-[416px] rounded-lg shadow-2xl relative flex">
            {/* Left side with promo image */}
            <div className="w-1/3.5">
              <img
                src={promo.image}
                className="w-full h-full object-cover rounded-l-lg"
                alt={promo.nama_promo}
              />
            </div>

            {/* Right side with promo text */}
            <div className="w-2/3 p-6 flex flex-col justify-center items-center relative">
              {/* Close Button */}
              <button
                onClick={() => setShowPopup(false)}
                className="absolute top-4 right-4 text-black transform"
              >
                <X size={24} />
              </button>

              {/* Promo Title */}
              <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                {promo.nama_promo}
              </h2>

              {/* Promo Details */}
              <p className="text-gray-600 text-center max-w-md">{promo.detail}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default Home;

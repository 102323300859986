import React, { useState } from "react";
import Footer from "../components/Footer";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      jsx
      <section className="py-24">
        <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-5 text-center">
            RETURN & EXCHANGE POLICY
          </h2>
          <div className="mt-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              What steps must be taken to return/exchange my order?
            </h3>
            <p className="text-base text-gray-600">
              Contact our Customer Care via Whatsapp (+62) 821-2121-1509.
            </p>

            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
              What terms and conditions must be met when returning/exchanging my
              order?
            </h3>
            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            You can return/exchange your order under the following conditions:
            </h3>
            <ul className="list-disc list-inside text-base text-gray-600 space-y-2">
              <li>
                Buyers must include an unboxing video when submitting a
                return/exchange.
              </li>
              <li>
                Products that have been purchased cannot be returned for a
                refund.
              </li>
              <li>
                Requests for product returns/exchanges are made within a maximum
                period of 7 (seven) days after the date the order is received by
                the customer. Returned products must be received at the
                specified location no later than 7 (seven) days after the
                return/exchange date is approved by Musique.
              </li>
              <li>
                The returned product has never been used and is still in its
                original condition complete with the label on the cardboard.
                Without any damage, with other conditions that make the product
                considered defective or damaged.
              </li>
              <li>
                The box/packaging of the product brand must be in its original
                condition. Not damaged/dented, not attached with any adhesive.
              </li>
              <li>The returned product has a purchase invoice mark.</li>
            </ul>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Accordion;

import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Importing Eye icons
import 'react-toastify/dist/ReactToastify.css';
import '../pages/Withdraw.css';

function Wallet() {
  const [activeStep, setActiveStep] = useState(0);
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productNames, setProductNames] = useState({});
  const [isWithdrawVisible, setIsWithdrawVisible] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawError, setWithdrawError] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [withdrawalInProgress, setWithdrawalInProgress] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const [withdrawals, setWithdrawals] = useState([]);
  const filteredWithdrawals = walletData.filter((item) => item.status === 'Complete' || item.status === 'On Process');

  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

   // Fetch wallet data on component mount
   useEffect(() => {
    if (userId && token) {
      fetch(`http://192.168.203.1:2000/wallet`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 404) {
            return [];
          }
          if (!response.ok) {
            throw new Error('Failed to fetch wallet data');
          }
          return response.json();
        })
        .then((data) => {
          const productNamesMap = {};
          data.forEach(item => {
            if (item.Produk) {
              productNamesMap[item.produkId] = item.Produk.nama_produk;
            }
          });
          setProductNames(productNamesMap);
          setWalletData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Fetch error:', error);
          setError(error.message);
          setLoading(false);
        });
    } else {
      setError('User is not authenticated');
      setLoading(false);
    }
  }, [userId, token]);

  useEffect(() => {
    const fetchWalletData = async () => {
      setLoading(true);
      try {
        const response = await fetch('http://192.168.203.1:2000/wallet', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setWalletData(data);
        setLoading(false);
        setError(null);

        // Extract walletId for the next fetch
        const walletId = data.id;  // Assuming 'id' is the wallet ID in the response
        fetchWithdrawals(walletId); // Move this out if necessary
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchWalletData();
}, [token]); // Simplified useEffect dependency


const fetchWithdrawals = async (walletId) => {
  try {
      const response = await fetch(`http://192.168.203.1:2000/withdraw/?walletId=${walletId}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
      });

      const withdrawalData = await response.json();
      console.log('Withdrawal Data:', withdrawalData); // Log the data to inspect it

      if (Array.isArray(withdrawalData)) {
          const filteredWithdrawals = withdrawalData.filter(
              (item) => item.status === 'On Proccess' || item.status === 'Completed'
          );
          setWithdrawals(filteredWithdrawals);
      } else {
          console.error('Expected an array, but received:', withdrawalData);
          setWithdrawals([]); // Clear withdrawals if the format is unexpected
      }

  } catch (error) {
      console.error('Error fetching withdrawals:', error);
      setError(error.message);
  }
};



  const handleButtonClick = (index) => setActiveStep(index);

  const buttonStyle = (index) => ({
    backgroundColor: activeStep === index ? '#1D1615' : 'white',
    color: activeStep === index ? '#F8F9FA' : 'black',
    padding: '10px 20px',
    border: '0px',
    cursor: 'pointer',
    width: '317px',
    height: '53px',
    marginRight: index < 2 ? '28px' : '0px',
  });

  const formatCurrency = (amount) => 
    new Intl.NumberFormat('id-ID', { style: 'decimal', currency: 'IDR' }).format(amount);

  const totalBalance = walletData.reduce((total, item) => total + item.jumlah, 0);

  useEffect(() => {
    if (walletData.length > 0) {
      const walletId = walletData[0]?.id; // Assuming you want to use the first wallet's ID
      fetch(`http://192.168.203.1:2000/withdraw/?walletId=${walletId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log("Withdrawals data", data);
        // Do something with the withdrawals data
      })
      .catch((error) => {
        console.error('Fetch withdrawals error:', error);
      });
    }
  }, [walletData, token]);

  const handleWithdrawClick = () => {
    setIsPasswordModalVisible(true);  // Show password modal
  };
  const handlePasswordSubmit = () => {
    // Validate the password by sending it to the backend API
    fetch('http://192.168.203.1:2000/user/validate-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,  // Use token for the logged-in user
      },
      body: JSON.stringify({ password: password }),  // Send the password entered by the user
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.isValid) {
        // Password is correct, proceed with withdrawal
        handleWithdrawal();
      } else {
        setPasswordError('Incorrect password');
      }
    })
    .catch((error) => {
      setPasswordError('Error validating password, please try again');
    });
  };
  
  // Update handleWithdrawal method:
  const handleWithdrawal = () => {
    const withdrawAmountInt = parseInt(withdrawAmount.replace(/\D/g, ''));
    
    // Check for minimum withdrawal amount
    if (withdrawAmountInt < 1000000) {
      setWithdrawError('Minimum withdrawal is Rp 1.000.000');
    }
    // Check if withdrawal amount exceeds total balance
    else if (withdrawAmountInt > totalBalance) {
      setWithdrawError('Insufficient balance');
    } else {
      setWithdrawError('');
  
      // Find a wallet item with sufficient balance
      const selectedWallet = walletData.find(item => item.jumlah >= withdrawAmountInt);
  
      if (!selectedWallet) {
        setWithdrawError('No wallet found with sufficient balance.');
        return;
      }
  
      const walletId = selectedWallet.id;  // walletId is extracted from the selected wallet
  
      // Get the current date in 'YYYY-MM-DD HH:MM:SS' format
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' '); // Format as 'YYYY-MM-DD HH:MM:SS'
  
      const withdrawalData = {
        walletId: walletId,  // Use the walletId from the selected wallet
        jumlah: withdrawAmountInt,
        tanggal_wd: formattedDate,
      };
  
      fetch('http://192.168.203.1:2000/withdraw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(withdrawalData),
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'Failed to process the withdrawal');
          });
        }
        return response.json();
      })
      .then((data) => {
        // Toast notification for success
        toast.success('Withdraw Success!', { autoClose: 2000 });
  
        // Show progress bar animation
        setWithdrawalInProgress(true);
  
        setTimeout(() => {
          setWithdrawalInProgress(false);
          // Reload the page after successful withdrawal
          window.location.reload();
        }, 2000);
  
        // You could also close the withdrawal modal here after success
        setIsWithdrawVisible(false); // Close the withdrawal modal
      })
      .catch((error) => {
        console.error('Withdrawal error:', error);
        setWithdrawError('Failed to process the withdrawal. Please try again.');
      });
    }
  };
  

  const handleWithdrawAmountChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, ''); 
    setWithdrawAmount(rawValue);
  };

  const formatWithdrawAmount = (amount) => {
    return new Intl.NumberFormat('id-ID').format(amount);
  };

  const handleAmountClick = (amount) => {
    setWithdrawAmount(amount);
    setSelectedAmount(amount);
  };

  if (loading) return <p>Loading wallet data...</p>;
  if (error) return <p style={{ color: 'red' }}>Error: {error}</p>;

  const receivedTransactions = walletData.filter((item) => item.status === 'received');



  return (
    <div>
      <div
        style={{
          marginBottom: '129px',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '0px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  
          maxWidth: '100%',
          margin: '20px auto',
        }}
      >
        <span style={{ fontWeight: '300', fontSize: '16px', color: '#555' }}>Total Refund Balance</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'left',
            marginTop: '15px',
          }}
        >
          <p style={{ fontSize: '36px', fontWeight: '700', color: '#333' }}>
            <strong>Rp {formatCurrency(totalBalance)}</strong>
          </p>
          {!isWithdrawVisible && (
            <button
              style={{
                backgroundColor: '#1D1615',
                color: '#F8F9FA',
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => setIsWithdrawVisible(true)} 
            >
              Withdraw
            </button>
          )}
        </div>

        {!isWithdrawVisible && (
          <p
            style={{
              marginTop: '10px',
              fontSize: '14px',
              color: '#777',
              fontWeight: '300',
            }}
          >
            Terms and conditions for withdrawing your balance.
          </p>
        )}
      </div>

      {isWithdrawVisible && (
        <div
          style={{
            backgroundColor: '#f8f9fa',
            padding: '20px',
            marginTop: '20px',
            minHeight: '600px',
          }}
        >
          <h3 style={{ fontSize: '40px', fontWeight: 700, lineHeight: '48.41px' }}>Withdraw</h3>

          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '21px'}}>
            <p style={{fontSize: '24px', fontWeight: 400, marginTop: '57px', lineHeight: '29.05px'}}>Withdrawal amount</p>
            <p
              style={{fontSize: '24px', fontWeight: 700, marginTop: '57px', lineHeight: '29.05px', cursor: 'pointer'}}
              onClick={() => setWithdrawAmount(totalBalance)}  
            >
              Withdraw All
            </p>
          </div>

          <input
            type="text"
            value={`Rp ${formatWithdrawAmount(withdrawAmount)}`}
            onChange={handleWithdrawAmountChange}
            placeholder="Enter withdrawal amount"
            style={{
              fontSize: '36px',
              fontWeight: 700,
              padding: '5px 0',
              width: '100%',
              marginBottom: '10px',
              border: 'none',
              borderBottom: '2px solid #000',
              outline: 'none',
              background: 'transparent',
            }}
          />

          <p style={{ color: '#808080', fontSize: '24px', fontWeight: 400, marginTop: '11px', }}>
            Minimum withdrawal amount is Rp 1.000.000
          </p>
          {withdrawError && <p style={{ color: 'red' }}>{withdrawError}</p>}

          <p style={{textAlign: 'center', marginTop: '31px', fontSize: '24px', fontWeight: 400}}>Choose amount</p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '24px',
              padding: '40px',
            }}
          >
            {['1000000', '10000000', '50000000'].map((amount, index) => (
              <div
                key={index}
                onClick={() => handleAmountClick(amount)}
                style={{
                  padding: '10px',
                  border: selectedAmount === amount ? '2px solid green' : '1px solid #000',
                  borderRadius: '4px',
                  fontSize: '24px',
                  fontWeight: 700,
                  cursor: 'pointer',
                  marginRight: index < 2 ? '20px' : '0',
                }}
              >
                Rp {formatCurrency(amount)}
              </div>
            ))}
          </div>

          <p style={{textAlign: 'center', marginTop: '67px', marginBottom: '24px'}}>
            By clicking the button below, you agree to the <span style={{textDecoration: 'underline'}}>terms and conditions for withdrawing your balance.</span>
          </p>

          <div style={{ width: '100%', position: 'relative' }}>
            <button
              style={{
                backgroundColor: '#1D1615',
                color: '#F8F9FA',
                fontWeight: 700,
                padding: '10px 0',
                width: '100%',
                border: 'none',
                outline: 'none',
                fontSize: '24px',
                borderRadius: 0,
              }}
              onClick={handleWithdrawClick}
            >
              WITHDRAW
            </button>
          </div>

          <div style={{ width: '100%', position: 'relative', marginTop: '40px' }}>
            <button
              style={{
                backgroundColor: '#1D1615',
                color: '#F8F9FA',
                fontWeight: 700,
                padding: '10px 0',
                width: '100%',
                border: 'none',
                outline: 'none',
                fontSize: '24px',
                borderRadius: 0,
              }}
              onClick={() => setIsWithdrawVisible(false)}
            >
              BATAL
            </button>
          </div>
        </div>
      )}

      {!isWithdrawVisible && (
        <div style={{ marginBottom: '20px', marginTop: '58px', display: 'flex', backgroundColor: '#F8F9FA', padding: '50px' }}>
          <button style={buttonStyle(0)} onClick={() => handleButtonClick(0)}>
            All Transaction
          </button>
          <button style={buttonStyle(1)} onClick={() => handleButtonClick(1)}>
            Received
          </button>
          <button style={buttonStyle(2)} onClick={() => handleButtonClick(2)}>
            Withdrawal
          </button>
        </div>
      )}

      {!isWithdrawVisible && (
        <div>
          {activeStep === 0 && (
            <div>
              {walletData.length === 0 ? (
                <p>No transactions found.</p>
              ) : (
                <div>
                  {walletData.map((item) => (
                    <div
                      key={item.id}
                      style={{
                        border: '1px solid #ddd',
                        padding: '15px',
                        marginBottom: '10px',
                        borderRadius: '8px',
                        background: '#fff',
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4 style={{ margin: 0, fontSize: '24px', fontWeight: 700 }}>
                          ORDER ID #{item.id.toString().slice(-7)}
                        </h4>
                        <p style={{ margin: 0, fontSize: '24px', fontWeight: 700 }}>
                          <strong>+Rp{formatCurrency(item.jumlah)}</strong>
                        </p>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: '16px', fontWeight: 500 }}>{item.status}</p>
                        <p
                          style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            color: '#333',
                          }}
                        >
                          {productNames[item.produkId]}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
{activeStep === 2 && ( // When "Withdrawal" button is clicked
  <div>
    {filteredWithdrawals.length === 0 ? (
      <p>No withdrawal transactions found.</p>
    ) : (
      <div>
        {filteredWithdrawals.map((item) => (
          <div
            key={item.id}
            style={{
              border: '1px solid #ddd',
              padding: '15px',
              marginBottom: '10px',
              borderRadius: '8px',
              background: '#fff',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4 style={{ margin: 0, fontSize: '24px', fontWeight: 700 }}>
                ORDER ID #{item.id.toString().slice(-7)}
              </h4>
              <p style={{ margin: 0, fontSize: '24px', fontWeight: 700 }}>
                <strong>+Rp {formatCurrency(item.jumlah)}</strong>
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontSize: '16px', fontWeight: 500 }}>{item.status}</p>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#333',
                }}
              >
                {productNames[item.produkId]}
              </p>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
)}


       {/* Password Modal */}
       {isPasswordModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div style={{ padding: '176px' }}>
              <p style={{ fontSize: '30px', fontWeight: 900, fontFamily: 'Inter', marginBottom: '64px', textAlign: 'center' }}>
                Confirm Withdrawal with your Password
              </p>
              <label style={{ color: '#9E9E9E', display: 'block', marginBottom: '8px' }}>
                Password
              </label>
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <input
                  type={passwordVisible ? 'text' : 'password'} // Toggle input type based on state
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  className="password-input"
                  style={{
                    fontSize: '16px',
                    padding: '10px',
                    border: passwordError ? '2px solid red' : '1px solid #ccc',
                    borderRadius: '4px',
                    width: '100%',
                    outline: 'none',
                    marginRight: '10px',
                  }}
                />
                {/* Toggle Eye Icon */}
                <div 
                  style={{
                    color: 'gray',
                    position: 'absolute',
                    top: '40%',
                    right: '40px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer'
                  }}
                  onClick={() => setPasswordVisible(prevState => !prevState)} // Toggle visibility
                >
                  {passwordVisible ? <AiFillEyeInvisible size={24} /> : <AiFillEye size={24} />}
                </div>
              </div>

              {passwordError && <p style={{ color: 'red', fontSize: '14px', marginTop: '2px', marginBottom: '20px' }}>{passwordError}</p>}

              <button onClick={handlePasswordSubmit} className="submit-btn">WITHDRAW</button>
              <button onClick={() => setIsPasswordModalVisible(false)} className="cancel-btn">CANCEL</button>
            </div>
          </div>
        </div>
      )}


      {/* Toast container for notifications */}
      <ToastContainer />
    </div>
  );
}

export default Wallet;

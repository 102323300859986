import React, { useState } from "react";
import Footer from "../components/Footer";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section className="py-24">
        <div className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {/* Purchasing Online Heading */}
          <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-5 text-center">
            Privacy Policy
          </h2>

          {/* Privacy Policy Content */}
          <div className="space-y-6 text-gray-700 text-lg leading-7">
            {/* Section 1 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                1. Your Personal Information
              </h3>
              <p>
                We need and collect your personal information such as name,
                address, billing and delivery/shipping address, telephone
                number, email address, and password. We use this information to
                process your purchase and deliver the items to you. On special
                occasions, we may also use your contact information to
                communicate with you about our products, services, and
                promotions.
              </p>
            </div>

            {/* Section 2 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                2. Consent
              </h3>
              <p className="mb-2">
                <strong>How do you get my consent?</strong>
                <br />
                When you fill in your personal information and verify your
                credit card, we imply that you consent to our collecting it and
                using it for that specific reason only.
              </p>
              <p>
                <strong>How do I withdraw my consent?</strong>
                <br />
                If you change your mind, you can withdraw your consent for the
                disclosure of your information at any time by contacting us at
                WhatsApp (+62) 821-2121-1509.
              </p>
            </div>

            {/* Section 3 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                3. Disclosure
              </h3>
              <p>
                We may disclose your personal information if we are required by
                law to do so or if you violate our Terms of Service.
              </p>
            </div>

            {/* Section 4 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                4. Payment
              </h3>
              <p>
                All direct payment gateways adhere to the standards set by
                Midtrans.
              </p>
            </div>

            {/* Section 5 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                5. Third-Party Services
              </h3>
              <p className="mb-2">
                In general, the third-party providers used by us will only
                collect, use, and disclose your information to the extent
                necessary to allow them to perform the services they provide to
                us. We recommend that you read their privacy policies so you can
                understand the manner in which your personal information will be
                handled by these providers.
              </p>
              <p className="mb-2">
                <strong>Links:</strong> When you click on links on our store,
                they may direct you away from our site. We are not responsible
                for the privacy practices of other sites and encourage you to
                read their privacy statements.
              </p>
              <p>
                <strong>Google Analytics:</strong> Our store uses Google
                Analytics to help us learn about who visits our site and what
                pages are being looked at.
              </p>
            </div>

            {/* Section 6 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                6. Security
              </h3>
              <p>
                To protect your personal information, we take reasonable
                precautions and follow industry best practices to make sure it
                is not inappropriately lost, misused, accessed, disclosed,
                altered, or destroyed. If you provide us with your credit card
                information, the information is encrypted using secure socket
                layer technology (SSL).
              </p>
            </div>

            {/* Section 7 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                7. Age of Consent
              </h3>
              <p>
                By using this site, you represent that you are at least the age
                of majority in your state or province of residence, or that you
                are the age of majority in your state or province of residence
                and you have given us your consent to allow any of your minor
                dependents to use this site.
              </p>
            </div>

            {/* Section 8 */}
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                8. Changes to This Privacy Policy
              </h3>
              <p>
                We reserve the right to modify this privacy policy at any time,
                so please review it frequently.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Accordion;

import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function ReviewPage() {
  return (
    <div>
        <Navbar />

            <div style={{marginTop: '120px'}}></div>

        <Footer/>
    </div>
  )
}

export default ReviewPage
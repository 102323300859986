import React from "react";
import ClickPickup from "../assets/Click & Pickup crop.png";
import FreeShipping from "../assets/Free Shipping crop.png";
import SameDayDelivery from "../assets/Some Day Delivery crop.png";

function Fitur() {
  return (
    <>
      {/* {" "}
      <section className="p-6 w-full text-center">
        <h1 className="text-4xl font-bold text-black">Expedition</h1>
        <hr className="w-16 border-t-2 border-black mx-auto mt-2" />{" "}
      </section> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 transform scale-70 hover:scale-90 transition-transform duration-200">
        <div className="p-10 text-center rounded-lg">
          <div className="flex items-center justify-center gap-4">
            <img
              src={ClickPickup}
              alt="Click & Pickup Icon"
              className="w-14 h-14"
            />
            <div className="text-left">
              <h6 className="text-xl font-semibold">Click & Pickup</h6>
              <p className="text-sm w-56">Shop online and pick up in store</p>
            </div>
          </div>
        </div>
        <div className="p-10 text-center rounded-lg">
          <div className="flex items-center justify-center gap-4">
            <img src={FreeShipping} alt="Free Shipping Icon" className="h-14" />
            <div className="text-left">
              <h6 className="text-xl font-semibold">Free Shipping</h6>
              <p className="text-sm w-56">
                Enjoy free delivery nationwide with no minimum spend
              </p>
            </div>
          </div>
        </div>
        <div className="p-10 text-center rounded-lg">
          <div className="flex items-center justify-center gap-4">
            <img
              src={SameDayDelivery}
              alt="Same Day Delivery Icon"
              className="h-14"
            />
            <div className="text-left">
              <h6 className="text-xl font-semibold">Same Day Delivery</h6>
              <p className="text-sm w-56">
                Order online and have it delivered to your door that same day
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fitur;

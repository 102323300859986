import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import apiClient from "../components/apiClient";

const OtpVerification = () => {
  const { state } = useLocation();
  const [otp, setOtp] = useState(['', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [timer, setTimer] = useState(30); // Timer state for countdown
  const navigate = useNavigate();

  // Start countdown timer when OTP page is loaded
  useEffect(() => {
    if (timer === 0) return;
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  console.log(localStorage.getItem("userId"));

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically move to next input
      if (index < 3 && value) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }

      // If all fields are filled, automatically call handleVerifyOtp
      if (newOtp.every((digit) => digit !== '')) {
        handleVerifyOtp(newOtp.join('')); // Pass the OTP as a string
      }
    } else if (e.key === 'Backspace' && otp[index] !== '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handleVerifyOtp = async (otpString) => {
    try {
      const response = await apiClient.post("/user/verify-otp", {
        email: state.email,
        otp: otpString,
      });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        navigate('/'); // Redirect to home after successful OTP verification
      } else {
        setErrorMessage('OTP tidak valid atau telah kedaluwarsa.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setErrorMessage('Terjadi kesalahan saat memverifikasi OTP.');
    }
  };

  const handleResendOtp = async () => {
    try {
      await apiClient.post("/user/resend-otp", {
        email: state.email,
      });
      setTimer(30); // Reset the timer after sending OTP
    } catch (error) {
      console.error('Error resending OTP:', error);
      setErrorMessage('Terjadi kesalahan saat mengirim ulang OTP.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h5" sx={{ fontFamily: 'Inter', fontWeight: 900, fontSize: '36px', textAlign: 'center', marginBottom: 2 }}>
          Verification Code
        </Typography>

        <Typography sx={{ fontFamily: 'Inter', width: '190%', fontWeight: 400, fontSize: '24px', textAlign: 'center', marginBottom: 2 }}>
          Verification code has been sent to {state.email}
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, marginBottom: 4 }}>
          {otp.map((digit, index) => (
            <TextField
              key={index}
              id={`otp-input-${index}`}
              type="text"
              inputProps={{ maxLength: 1 }}
              value={digit}
              onChange={(e) => handleOtpChange(e, index)}
              onKeyDown={(e) => handleOtpChange(e, index)} // Handle backspace for clearing
              variant="standard" // Use the 'standard' variant for the underline style
              sx={{
                '& .MuiInputBase-root': {
                  padding: 0, // Remove extra padding
                },
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                  fontSize: '64px',
                  fontWeight: 700,
                  fontFamily: 'Inter',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '2px solid #BDBDBD', // Default underline color
                },
                '& .MuiInput-underline:hover:before': {
                  borderBottom: '2px solid #1F1F1F', // Hover effect for underline
                },
              }}
              required
            />
          ))}
        </Box>

        <Typography sx={{ fontFamily: 'Inter', width: '180%', fontWeight: 400, fontSize: '24px', textAlign: 'center', marginBottom: 2 }}>
          Please wait <span style={{ fontWeight: 700 }}>{timer} second</span> to resend OTP.
        </Typography>

        {timer === 0 && (
          <Button
            onClick={handleResendOtp}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              height: '56px',
              backgroundColor: '#1F1F1F',
              color: 'white',
              mb: 6,
              fontFamily: 'Inter',
              fontWeight: 700,
              fontSize: '24px',
            }}
          >
            RESEND OTP
          </Button>
        )}

        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Box>
    </Container>
  );
};

export default OtpVerification;

// src/api/apiClient.js
import axios from 'axios';

// Create a centralized Axios instance with a base URL
const apiClient = axios.create({
    // baseURL: process.env.REACT_APP_API_BASE_URL || 'http://192.168.1.3:2000',
    // baseURL: process.env.REACT_APP_API_BASE_URL || 'http://192.168.1.64:2000',
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://musique.co.id/musiquebackend',
    timeout: 5000, // Optional: Set a timeout for API requests
});

// Optional: Add request and response interceptors
apiClient.interceptors.request.use(
    (config) => {
        console.log(`[Request] ${config.method.toUpperCase()} - ${config.url}`);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        console.log(`[Response]`, response);
        return response;
    },
    (error) => {
        console.error(`[Error Response]`, error);
        return Promise.reject(error);
    }
);

export default apiClient;

import React, { useState } from "react";
import Footer from "../components/Footer";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section className="py-24">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-5 text-center">
            Payment Option
          </h2>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Accordion;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../components/DetailProdukBrand.css"; // Import the CSS file for styling
import Footer from "../components/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import apiClient from "../components/apiClient";
import Default from "../assets/default-image.png";

const formatRupiah = (angka) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka);
};

const DetailProdukBrand = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const navigate = useNavigate();
  const [imageHeader, setImageHeader] = useState(null); // ✅ Define imageHeader state

  // Get the brandId from localStorage
  const brandId = localStorage.getItem("brandId");

  // Fetch data from the API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`)
      .then((response) => response.json())
      .then((data) => {
        setBrands(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const fetchProductsByBrand = (brandId) => {
    setLoading(true);
    setMessage("");
    setError(null);

    const foundBrand = brands.find((brand) => brand.id === brandId);
    setSelectedBrand(foundBrand); // Set the selected brand

    // Reset videoUrl before fetching new data
    setVideoUrl("");

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/produk/brand/${brandId}`)
      .then((response) => {
        if (response.data.length === 0) {
          setMessage("Product not found");
          setProducts([]);
        } else {
          setMessage("");
          setProducts(response.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setMessage("Product not found");
          setProducts([]);
        } else {
          setError("Failed to fetch products. Please try again later.");
        }
        setLoading(false);
      });

    // Fetch brand details including about_brand
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/brand/${brandId}`)
      .then((response) => {
        if (response.data) {
          setSelectedBrand(response.data); // Update selectedBrand with full brand details
        }
      })
      .catch((err) => {
        console.error("Failed to fetch brand details:", err);
      });

    // Fetch video URL for the given brandId
    // axios
    //   .get(`${process.env.REACT_APP_API_BASE_URL}/videobrand/brand/${brandId}`)
    // .then((response) => {
    //   if (response.data.length > 0) {
    //     setVideoUrl(response.data.[0].videoUrl);
    //   } else {
    //     setVideoUrl("");
    //   }
    // })
    // .catch((err) => {
    //   console.error("Failed to fetch video:", err);
    // });

    apiClient
      .get(`/brand/${brandId}`)
      .then((response) => {
        if (response.data) {
          if (response.data.movie) {
            setVideoUrl(response.data.movie); // Show video if available
            setImageHeader(null); // Reset image when video exists
          } else if (response.data.imageheader) {
            setVideoUrl(null); // Reset video
            setImageHeader(response.data.imageheader); // Show imageheader if no video
          } else {
            setVideoUrl(null);
            setImageHeader(null); // No video or imageheader
          }
        }
      })
      .catch((err) => {
        console.error("Failed to fetch video:", err);
      });
  };

  useEffect(() => {
    if (brandId) {
      // Fetch products and video by brandId
      fetchProductsByBrand(brandId);
    } else {
      setError("Brand ID is missing.");
      setLoading(false);
      navigate("/");
    }
  }, [brandId, navigate]);

  const isNewProduct = (createdAt) => {
    const productDate = new Date(createdAt);
    const currentDate = new Date();
    return (
      productDate.getMonth() === currentDate.getMonth() &&
      productDate.getFullYear() === currentDate.getFullYear()
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Fungsi untuk memetakan hex ke nama warna (simple approach)
  const hexToName = (hex) => {
    const colors = {
      "#ffffff": "White",
      "#0c0c0c": "Black Dove",
      "#e0bfb8": "Rose Gold",
      "#070707": "Matte Black",
      "#fefefe": "Light Chrome",
      "#f0f0f0": "Light Gray",
      "#808080": "Gray",
      "#000000": "Black",
      "#ff0000": "Red",
      "#00ff00": "Green",
      "#0000ff": "Blue",
      "#800080": "Purple",
      "#ffff00": "Yellow",
      "#ffa500": "Orange",
      "#800000": "Maroon",
      "#808000": "Olive",
      "#008000": "Dark Green",
      "#000080": "Navy Blue",
      "#ff00ff": "Magenta",
      "#c0c0c0": "Silver",
      "#a52a2a": "Brown",
      "#d3d3d3": "Light Gray",
      "#f5f5f5": "White Smoke",
      "#c0d6e4": "Light Steel Blue",
      "#ff6347": "Tomato",
      "#2e8b57": "Sea Green",
      "#3cb371": "Medium Sea Green",
      "#ff1493": "Deep Pink",
      "#00bfff": "Deep Sky Blue",
      "#00ced1": "Dark Turquoise",
      "#8a2be2": "Blue Violet",
      "#7fff00": "Chartreuse",
      "#adff2f": "Green Yellow",
      "#ffdead": "Navajo White",
      "#dda0dd": "Plum",
      "#ff4500": "Orange Red",
      "#2f4f4f": "Dark Slate Gray",
      "#bdb76b": "Dark Khaki",
      "#9acd32": "Yellow Green",
      "#8b4513": "Saddle Brown",
      "#ffb6c1": "Light Pink",
      "#cd5c5c": "Indian Red",
      "#b0e0e6": "Powder Blue",
      "#f0e68c": "Khaki",
      "#e0ffff": "Light Cyan",
    };

    return colors[hex.toLowerCase()] || hex;
  };

  return (
    <div style={{ marginTop: "0px" }}>
      <div className="p-4 mt-[100px]">
        {selectedBrand ? (
          <>
            <h2 className="text-center text-xl font-bold mb-6">
              {selectedBrand.nama_brand}{" "}
            </h2>
            <span className="block mb-2 text-lg text-gray-700">
              {selectedBrand.about_brand || "No description available."}
            </span>
          </>
        ) : (
          <span className="text-lg text-gray-500">
            No brand information available.
          </span>
        )}
      </div>

      {/* Display video if available */}
      {/* {videoUrl && (
        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <video width="100%" height="auto" autoPlay muted loop>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )} */}

      {videoUrl ? (
        <div className="mb-5 text-center">
          <video
            className="w-full h-auto rounded-lg shadow-lg"
            autoPlay
            muted
            loop
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : imageHeader ? (
        <div className="mb-5 text-center">
          <img
            src={imageHeader}
            alt="Brand Header"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      ) : null}

      <div className="product-grid mb-10 flex flex-wrap justify-center">
        {message ? (
          <div className="no-products text-center mt-52 text-black">
            <img
              src="/product-not-found.avif"
              alt="No products"
              className="w-72 h-72 mx-auto"
            />
            <p className="text-2xl font-bold pt-8">
              {message || "Product By Brand Not Found"}
            </p>
          </div>
        ) : (
          products.map((item) => (
            <div
              key={item.id}
              // className="produk-item w-full sm:w-1/2 lg:w-1/3 xl:w-1/5 p-4 flex flex-col justify-between items-center bg-white border border-gray-200 rounded-lg shadow-lg relative mb-4 cursor-pointer hover:shadow-xl transition"
              className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/5 p-4 flex flex-col justify-between items-center bg-white border border-gray-200 rounded-lg shadow-lg relative cursor-pointer hover:shadow-xl transition" // Reduced height
              onClick={() => navigate(`/produk/all/${item.id}`)}
            >
              {/* NEW! Badge for newly added products */}
              {isNewProduct(item.createdAt) && (
                <div className="absolute top-2 right-2 bg-gray-100 text-black px-3 py-1 rounded font-medium text-sm">
                  NEW!
                </div>
              )}

              {/* Discount Badge */}
              {item.diskon && (
                <div className="absolute top-2 left-2 bg-red-500 text-white px-3 py-1 rounded font-bold text-sm">
                  {item.diskon}% OFF
                </div>
              )}

              {/* Product Image */}
              {/* <img
                src={
                  item.image && item.image.length > 0
                    ? item.image[0]
                    : `${process.env.REACT_APP_API_BASE_URL}/uploads-image/default-image.jpg`
                }
                alt={item.nama_produk}
                // className="w-full h-auto max-h-80 object-contain rounded-t-lg m-0 p-0"
                className="w-full h-[100px] object-contain rounded-t-lg"
              /> */}

              <div className="flex justify-center items-center">
                <div className="w-full max-w-sm">
                  <div className="w-full h-64 overflow-hidden rounded-lg">
                    <img
                      src={
                        item.image && item.image.length > 0
                          ? item.image[0]
                          : Default
                      }
                      alt={item.nama_produk}
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              </div>

              {/* Brand Image */}
              {/* {item.brand && item.brand.image && (
                <img
                  src={item.brand.image}
                  alt={item.brand.nama_brand}
                  className="w-24 h-24 object-contain"
                />
              )} */}

              {/* Product Details */}
              <div className="p-2 w-full">
                <h3 className="text-md font-semibold">{item.nama_produk}</h3>

                {/* Color Circles */}
                <div className="flex space-x-2 mt-4">
                  {item.color &&
                    item.color.map((color, index) => (
                      <div key={index} className="flex flex-col items-center">
                        <div
                          className="w-6 h-6 rounded-full mb-2 border border-gray-500"
                          style={{ backgroundColor: color }}
                        ></div>
                      </div>
                    ))}
                </div>

                {/* Category */}
                <div className="mt-4 text-sm text-gray-600">
                  {item.kategori.nama_kategori}
                </div>

                {/* Price Section */}
                <div className="mt-4 flex justify-between items-center">
                  <div className="text-lg font-semibold text-gray-800">
                    {item.harga && item.harga.length > 0 ? (
                      item.diskon ? (
                        <div className="flex flex-col items-start">
                          <span className="text-red-500 font-bold">
                            {formatRupiah(
                              (item.harga[0] || item.harga) -
                                ((item.harga[0] || item.harga) * item.diskon) /
                                  100
                            )}
                          </span>
                          <span className="line-through text-sm text-gray-400">
                            {formatRupiah(item.harga[0] || item.harga).replace(
                              "Rp",
                              "Rp "
                            )}
                          </span>
                        </div>
                      ) : (
                        formatRupiah(item.harga[0] || item.harga).replace(
                          "Rp",
                          "Rp "
                        )
                      )
                    ) : (
                      <span className="text-gray-500 text-sm">
                        Price not available
                      </span> // Show fallback text if no price is available
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Carousel for Brands */}
      <div className="mt-14 mb-14 bg-white">
        {brands.length > 0 && (
          <Carousel
            responsive={{
              desktop: { breakpoint: { max: 3000, min: 1024 }, items: 7 },
              tablet: { breakpoint: { max: 1024, min: 640 }, items: 3 },
              mobile: { breakpoint: { max: 640, min: 0 }, items: 2 },
            }}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            className="w-full px-4"
          >
            {brands.map((brand, index) => (
              <div key={index} className="flex justify-center">
                <img
                  src={brand.image}
                  alt={`Brand ${index}`}
                  onClick={() => fetchProductsByBrand(brand.id)}
                  className="cursor-pointer mx-2 w-full max-w-[200px] h-[170px] object-contain transition-transform duration-300 hover:scale-105"
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default DetailProdukBrand;

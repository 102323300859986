import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, FormControl, InputLabel, Checkbox, FormControlLabel, FormGroup, Select, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment'; // Add this import
import apiClient from "../components/apiClient";

const SearchProdukPage = () => {
  // State untuk produk, brand, dan filter
  const [produkList, setProdukList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [filters, setFilters] = useState({
    sortBy: [],
    price: '',
    brand: '',
  });
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch data produk dan brand
  useEffect(() => {
    // Fetch produk dengan query jika ada
    apiClient.get(`/produk?query=${searchQuery}`)
      .then(response => {
        setProdukList(response.data);
      })
      .catch(error => console.error('Error fetching products:', error));

    // Fetch brand
    apiClient.get('/brand')
      .then(response => {
        setBrandList(response.data);
      })
      .catch(error => console.error('Error fetching brands:', error));
  }, [searchQuery]); // Re-fetch produk when searchQuery changes

  // Fungsi untuk menangani perubahan filter
  const handleFilterChange = (e) => {
    const { name, checked } = e.target;
    setFilters(prev => {
      const updatedSortBy = checked
        ? [...prev.sortBy, name]
        : prev.sortBy.filter(item => item !== name);
      return { ...prev, sortBy: updatedSortBy };
    });
  };

  // Fungsi untuk melakukan sort dan filter pada produk
  const filteredAndSortedProducts = produkList
    .filter(product => (filters.brand ? product.brand === filters.brand : true))
    .sort((a, b) => {
      if (filters.sortBy.includes('Most Relevant')) {
        return 0;
      }
      if (filters.sortBy.includes('Latest')) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      if (filters.sortBy.includes('Most Popular')) {
        return b.popularity - a.popularity;
      }
      if (filters.sortBy.includes('Price Ascending')) {
        return a.harga - b.harga;
      }
      if (filters.sortBy.includes('Price Descending')) {
        return b.harga - a.harga;
      }
      return 0;
    });

  return (
    <Box sx={{ display: 'flex', padding: '20px', marginTop: '100px', backgroundColor: '#F8F9FA' }}>
      {/* Sidebar untuk filter */}
      <Box sx={{ width: '210px', marginRight: '20px',  }}>
        
        <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '20px' }}>Filter</Typography>
        
      

        <FormControl fullWidth sx={{ marginBottom: '16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <img
              src="/icon/Arrow_Down_Up.svg"
              alt="Arrow Down Up"
              style={{ width: '20px', height: '20px', marginRight: '8px' }}
            />
            <Typography variant="subtitle1">Sort By</Typography>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.sortBy.includes('Most Relevant')}
                  onChange={handleFilterChange}
                  name="Most Relevant"
                />
              }
              label="Most Relevant"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.sortBy.includes('Latest')}
                  onChange={handleFilterChange}
                  name="Latest"
                />
              }
              label="Latest"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.sortBy.includes('Most Popular')}
                  onChange={handleFilterChange}
                  name="Most Popular"
                />
              }
              label="Most Popular"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.sortBy.includes('Price Ascending')}
                  onChange={handleFilterChange}
                  name="Price Ascending"
                />
              }
              label="Price Ascending"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.sortBy.includes('Price Descending')}
                  onChange={handleFilterChange}
                  name="Price Descending"
                />
              }
              label="Price Descending"
            />
          </FormGroup>
        </FormControl>

        {/* Brand Filter */}
        <FormControl fullWidth sx={{ marginBottom: '16px' }}>
          <InputLabel>Brand</InputLabel>
          <Select
            value={filters.brand}
            name="brand"
            onChange={(e) => setFilters({ ...filters, brand: e.target.value })}
            label="Brand"
          >
            <MenuItem value="">All Brands</MenuItem>
            {brandList.map(brand => (
              <MenuItem key={brand.id} value={brand.nama_brand}>
                {brand.nama_brand}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </Box>
      

      {/* Produk list */}
 <Box sx={{ flexGrow: 1 }}>
  {/* Search bar */}
  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '50px' }}>
    <TextField
      placeholder='Search product by Name'
      variant="outlined"
      sx={{ width: '650px' }}  // Set width of the search bar
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  
</Box>
        <Grid container spacing={3}>
          {filteredAndSortedProducts.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <Card sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', boxShadow: 'none' }}>
                {/* Gambar Produk (Gambar pertama) */}
                <img
                  src={product.image[0]} // Menampilkan gambar pertama
                  alt={product.nama_produk}
                  style={{ height: '200px', objectFit: 'cover' }}
                />
                <CardContent>
                  {/* Nama Produk dan Color di pojok kanan */}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '24px',
                        lineHeight: '29.05px',
                      }}
                    >
                      {product.nama_produk}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {product.color && product.color[0].split(',').map((color, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            backgroundColor: color.trim(),
                            border: '1px solid #ccc',
                          }}
                        />
                      ))}
                    </Box>
                  </Box>

                  {/* Kategori Produk dan Harga di pojok kanan */}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '19.36px',
                      }}
                    >
                      {product.kategori.nama_kategori}
                    </Typography>

                    {/* Jika ada diskon, tampilkan harga coret dan harga baru */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      {product.diskon ? (
                        <>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              color: '#FF3232',
                            }}
                          >
                            {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
                              product.harga - (product.harga * product.diskon) / 100
                            )}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              textDecoration: 'line-through',
                              color: '#C6C6C6',
                              fontSize: '14px',
                            }}
                          >
                            {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(product.harga)}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(product.harga)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchProdukPage;

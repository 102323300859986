import React, { useState, useEffect } from "react";
import {
  Snackbar,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function AlamatPage() {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [editingAddress, setEditingAddress] = useState(null);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [formData, setFormData] = useState({
    namapenerima: "",
    alamat: "",
    kotaataukelurahan: "",
    provinsi: "",
    kodePos: "",
    main: "Rumah",
    nomeralamat: "",
    nomerteleponpenerima: "",
    status: "Utama",
    provinsiId: "",
    kotaId: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleAddressSubmit = async (event) => {
    event.preventDefault();

    // Check if required fields are filled
    if (
      !formData.namapenerima ||
      !formData.alamat ||
      !formData.kotaataukelurahan ||
      !formData.kodePos
    ) {
      setSnackbarMessage("Please fill out all required fields.");
      setOpenSnackbar(true);
      return;
    }

    // If status is 'Utama', check if another address already has 'Utama' status
    if (formData.status === "Utama") {
      const existingMainAddress = addresses.find(
        (address) => address.status === "Utama"
      );
      if (existingMainAddress && existingMainAddress.id !== formData.id) {
        setSnackbarMessage("You can only have one main address.");
        setOpenSnackbar(true);
        return;
      }
    }

    try {
      let response;
      if (editingAddress) {
        response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/${editingAddress.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(formData),
          }
        );
      } else {
        response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/alamat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(formData),
        });
      }

      if (response.ok) {
        const data = await response.json();
        if (editingAddress) {
          setAddresses((prev) =>
            prev.map((address) => (address.id === data.id ? data : address))
          );
          setSnackbarMessage("Address updated successfully!");
        } else {
          setAddresses((prev) => [...prev, data]);
          setSnackbarMessage("Address added successfully!");
        }
        setOpenAddDialog(false);
        setOpenEditDialog(false);
        setOpenSnackbar(true);
        window.location.reload();
      } else {
        const error = await response.json();
        setSnackbarMessage(
          `Failed to submit address: ${error.message || "Unknown error"}`
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error submitting address:", error);
      setSnackbarMessage("Failed to submit address. Please try again later.");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/alamat`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setAddresses(data);
        } else {
          console.error("Failed to fetch addresses");
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, []);

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/provinces`
        );
        if (response.ok) {
          const data = await response.json();
          setProvinces(data.rajaongkir.results);
        } else {
          console.error("Failed to fetch provinces");
        }
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };

    fetchProvinces();
  }, []);

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setAddresses((prevAddresses) =>
          prevAddresses.filter((address) => address.id !== id)
        );
        setSnackbarMessage("Address deleted successfully!");
        setOpenSnackbar(true);
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.message || "Failed to delete address";
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error deleting address:", error);
      setSnackbarMessage(
        "An error occurred while deleting the address. Please try again."
      );
      setOpenSnackbar(true);
    }
  };

  const fetchCities = async (provinceId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/rajaongkir/cities?province=${provinceId}`
      );
      if (response.ok) {
        const data = await response.json();
        setCities(data.rajaongkir.results);
      } else {
        console.error("Failed to fetch cities");
        setCities([]);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCities([]);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "provinsi") {
      const selectedProvince = provinces.find(
        (province) => province.province === value
      );
      if (selectedProvince) {
        setFormData((prevData) => ({
          ...prevData,
          provinsiId: selectedProvince.province_id,
        }));
        fetchCities(selectedProvince.province_id);
      }
      setFormData((prevData) => ({
        ...prevData,
        kotaataukelurahan: "",
        kotaId: "",
      }));
    }

    if (name === "kotaataukelurahan") {
      const selectedCity = cities.find((city) => city.city_name === value);
      if (selectedCity) {
        setFormData((prevData) => ({
          ...prevData,
          kotaId: selectedCity.city_id,
        }));
      }
    }
  };

  return (
    <div style={{ padding: "32px", backgroundColor: "white" }}>
      <div className="text-center mt-6">
        {!addresses.length && (
          <div className="text-2xl font-bold text-gray-700 mb-4">
            You do not have any addresses added to your account.
          </div>
        )}

        <button
          onClick={() => setOpenAddDialog(true)}
          className="bg-black text-white px-8 py-3 text-lg font-medium rounded-md shadow-md transition-all hover:bg-gray-800 active:scale-95"
        >
          ADD NEW ADDRESS
        </button>
      </div>

      {/* Address List */}
      <div className="mt-8 space-y-4">
        {addresses.map((address) => (
          <div
            key={address.id}
            className="relative bg-white p-6 rounded-lg shadow-md border border-gray-200"
          >
            {/* Address Info */}
            <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center">
              <div className="mb-3 sm:mb-0">
                <h3 className="text-lg font-semibold">
                  {address.main}{" "}
                  <span className="text-gray-500">({address.status})</span>
                </h3>
                <p className="text-gray-700 font-medium">
                  {address.namapenerima}
                </p>
                <p className="text-gray-600">{address.alamat}</p>
                <p className="text-gray-600">
                  {address.kotaataukelurahan}, {address.provinsi} -{" "}
                  {address.kodePos}
                </p>
                <p className="text-gray-600">{address.nomerteleponpenerima}</p>
                <p className="text-sm text-gray-500">Type: {address.main}</p>
                <p className="text-sm text-gray-500">
                  Status: {address.status}
                </p>
              </div>

              {/* Buttons */}
              <div className="flex flex-col sm:flex-row gap-2">
                {address.status !== "Utama" && (
                  <button
                    onClick={() => {
                      setEditingAddress(address);
                      setFormData({
                        namapenerima: address.namapenerima,
                        alamat: address.alamat,
                        kotaataukelurahan: address.kotaataukelurahan,
                        provinsi: address.provinsi,
                        kodePos: address.kodePos,
                        main: address.main,
                        nomeralamat: address.nomeralamat,
                        nomerteleponpenerima: address.nomerteleponpenerima,
                        status: address.status,
                        provinsiId: address.provinsiId,
                        kotaId: address.kotaId,
                      });
                      setOpenEditDialog(true);
                    }}
                    className="bg-black text-white px-4 py-2 rounded-md shadow-md transition-all hover:bg-gray-800 active:scale-95"
                  >
                    Edit Address
                  </button>
                )}

                <button
                  onClick={() => handleDelete(address.id)}
                  className="bg-red-600 text-white px-4 py-2 rounded-md shadow-md transition-all hover:bg-red-700 active:scale-95"
                >
                  Delete Address
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Add Address Dialog */}
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add New Address</DialogTitle>
        <DialogContent>
          <form onSubmit={handleAddressSubmit}>
            <TextField
              label="Recipient Name"
              name="namapenerima"
              value={formData.namapenerima}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Recipient's Phone Number"
              name="nomerteleponpenerima"
              value={formData.nomerteleponpenerima}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Address"
              name="alamat"
              value={formData.alamat}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
            <FormControl fullWidth required margin="normal">
              <InputLabel>Province</InputLabel>
              <Select
                name="provinsi"
                value={formData.provinsi}
                onChange={handleInputChange}
                label="Province"
              >
                {provinces.map((province) => (
                  <MenuItem
                    key={province.province_id}
                    value={province.province}
                  >
                    {province.province}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel>City</InputLabel>
              <Select
                name="kotaataukelurahan"
                value={formData.kotaataukelurahan}
                onChange={handleInputChange}
                label="City"
              >
                {cities.map((city) => (
                  <MenuItem key={city.city_id} value={city.city_name}>
                    {city.city_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Postal Code"
              name="kodePos"
              value={formData.kodePos}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
              type="number" // Restrict input to only numbers
              inputProps={{
                min: 0, // Optionally, you can set a minimum value, if needed
              }}
            />
            <TextField
              label="Address Number"
              name="nomeralamat"
              value={formData.nomeralamat}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              type="number" // Restrict input to only numbers
              inputProps={{
                min: 0, // Optionally, you can set a minimum value, if needed
              }}
            />
            <FormControl fullWidth required margin="normal">
              <InputLabel>Main Address</InputLabel>
              <Select
                name="main"
                value={formData.main}
                onChange={handleInputChange}
                label="Main Address"
              >
                <MenuItem value="Rumah">Rumah</MenuItem>
                <MenuItem value="Kantor">Kantor</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                label="Status"
              >
                <MenuItem value="Utama">Utama</MenuItem>
                <MenuItem value="Alamat lain">Alamat Lain</MenuItem>
              </Select>
            </FormControl>
            <DialogActions>
              <Button onClick={() => setOpenAddDialog(false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add Address
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Edit Address Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Address</DialogTitle>
        <DialogContent>
          <form onSubmit={handleAddressSubmit}>
            <TextField
              label="Recipient Name"
              name="namapenerima"
              value={formData.namapenerima}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Recipient's Phone Number"
              name="nomerteleponpenerima"
              value={formData.nomerteleponpenerima}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Address"
              name="alamat"
              value={formData.alamat}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Province</InputLabel>
              <Select
                name="provinsi"
                value={formData.provinsi}
                onChange={handleInputChange}
                label="Province"
              >
                {provinces.map((province) => (
                  <MenuItem
                    key={province.province_id}
                    value={province.province}
                  >
                    {province.province}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>City</InputLabel>
              <Select
                name="kotaataukelurahan"
                value={formData.kotaataukelurahan}
                onChange={handleInputChange}
                label="City"
              >
                {cities.map((city) => (
                  <MenuItem key={city.city_id} value={city.city_name}>
                    {city.city_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Postal Code"
              name="kodePos"
              value={formData.kodePos}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              type="number"
              inputProps={{
                min: 0,
              }}
            />
            <TextField
              label="Address Number"
              name="nomeralamat"
              value={formData.nomeralamat}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              type="number"
              inputProps={{
                min: 0,
              }}
            />
            <FormControl fullWidth required margin="normal">
              <InputLabel>Main Address</InputLabel>
              <Select
                name="main"
                value={formData.main}
                onChange={handleInputChange}
                label="Main Address"
              >
                <MenuItem value="Rumah">Rumah</MenuItem>
                <MenuItem value="Kantor">Kantor</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                label="Status"
              >
                <MenuItem value="Utama">Utama</MenuItem>
                <MenuItem value="Alamat lain">Alamat Lain</MenuItem>
              </Select>
            </FormControl>
            <DialogActions>
              <Button onClick={() => setOpenEditDialog(false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update Address
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  );
}

export default AlamatPage;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "flowbite-react";
import slide2Image from "../assets/2.png";
import "./Banner.css";
import apiClient from "../components/apiClient";
import { ChevronLeft, ChevronRight } from "lucide-react"; 

const Banner = () => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await apiClient.get("/banner");
        console.log("API Response:", response.data); // Debugging purpose

        if (response.data?.banners?.length > 0) {
          // Flatten all media (images & videos) from different banners into one array
          const allMedia = response.data.banners.flatMap(
            (banner) => banner.image || []
          );
          setMediaFiles(allMedia);
        } else {
          setError("No banners found.");
        }
      } catch (err) {
        console.error(err);
        setError("Failed to fetch banners.");
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  useEffect(() => {
    if (mediaFiles.length === 0) return; // Prevents useEffect from running when there's no media

    const isVideo = mediaFiles[currentIndex]?.endsWith(".mp4");
    const intervalTime = isVideo ? 10000 : 5000; // 10s for video, 5s for image

    const interval = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaFiles.length);
    }, intervalTime);

    return () => clearTimeout(interval);
  }, [currentIndex, mediaFiles]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const prevSlide = () => setCurrentIndex((prev) => (prev === 0 ? mediaFiles.length - 1 : prev - 1));
  const nextSlide = () => setCurrentIndex((prev) => (prev + 1) % mediaFiles.length);


  // return (
  //   <div className="relative w-full h-0 pb-[40%]">
  //     <Carousel slideInterval={5000} className="absolute top-0 left-0 w-full h-full">
  //       {mediaFiles.length > 0 ? (
  //         mediaFiles.map((file, index) => {
  //           const isVideo = file.endsWith(".mp4");

  //           return (
  //             <div key={index} className="w-full h-full flex items-center justify-center">
  //               {isVideo ? (
  //                 <video
  //                   src={file}
  //                   className="w-full h-full object-cover"
  //                   autoPlay
  //                   muted
  //                   loop
  //                   playsInline
  //                   controls
  //                 />
  //               ) : (
  //                 <img
  //                   src={file}
  //                   alt={`Slide ${index + 1}`}
  //                   className="w-full h-full object-cover"
  //                 />
  //               )}
  //             </div>
  //           );
  //         })
  //       ) : (
  //         <p>No media available</p>
  //       )}
  //     </Carousel>
  //   </div>
  // );

  return (
    <div className="relative w-full h-[500px] md:h-[700px] lg:h-[800px] overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        {mediaFiles.length > 0 ? (
          mediaFiles.map((file, index) => (
            <div
              key={index}
              className={`absolute top-0 left-0 w-full h-full flex items-center justify-center transition-opacity duration-700 ${
                index === currentIndex ? "opacity-100 z-10" : "opacity-0 z-0"
              }`}
            >
              {file.endsWith(".mp4") ? (
                <video
                  src={file}
                  className="w-full h-full object-cover pointer-events-none"
                  autoPlay
                  muted
                  loop
                  playsInline
                  controls
                />
              ) : (
                <img src={file} alt={`Slide ${index + 1}`} className="w-full h-full object-cover" />
              )}
            </div>
          ))
        ) : (
          <p>No media available</p>
        )}
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full z-20 hover:bg-black/70"
      >
        <ChevronLeft size={32} />
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full z-20 hover:bg-black/70"
      >
        <ChevronRight size={32} />
      </button>

      {/* Slide Indicators (Dots) */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-20">
        {mediaFiles.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${
              index === currentIndex ? "bg-white" : "bg-gray-400"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Banner;
